import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { EDIT_CUP, SEARCH_PLACE, GET_CUP } from "./CupEditQueries";
import CupEditPresenter from "./CupEditPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";
import moment from "moment";
import "moment/locale/ko";
import { SEE_CUPS } from "../CupList/CupListQueries";

const CupEditContainer = ({ match: { params }, history }) => {
  const cupId = params.cup;

  const [host, setHost] = useState([]);
  const [organizer, setOrganizer] = useState([]);
  const [support, setSupport] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [group, setGroup] = useState([]);

  const [oldHost, setOldHost] = useState([]);
  const [oldOrganizer, setOldOrganizer] = useState([]);
  const [oldSupport, setOldSupport] = useState([]);
  const [oldSponsor, setOldSponsor] = useState([]);

  const cupName = useInput("");
  const url = useInput("");
  const youtubeUrl = useInput("");
  const target = useInput("");
  const firstPrize = useInput("");
  const secondPrize = useInput("");
  const thirdPrize = useInput("");
  const mvpPrize = useInput("");
  const mannerPrize = useInput("");
  const joinPriceUnit = useInput("인당 ");
  const joinPrice = useInput("", newValue => !isNaN(newValue), true);
  const contact = useInput("");
  const cupProcess = useInput("");
  const DepositAccount = useInput("");
  const groupInput = useInput("");

  const [place, setPlace] = useState();

  const playerCnt = useInput(3);

  const cupStartDate = useInput(moment().format("YYYY-MM-DDThh:mm"));
  const cupEndDate = useInput(moment().format("YYYY-MM-DDThh:mm"));
  const cupDateInfo = useInput("");

  const [editLogo, setEditLogo] = useState(false);
  const [uploading, setUploading] = useState(false);

  const editCupMutation = useMutation(EDIT_CUP, { 
    refetchQueries:[{query:SEE_CUPS}, {query:GET_CUP, variables:{ id: cupId }}]
  });

  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();
  const placeNameInput = useInput("");

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setPlace(null);
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    placeNameInput.setValue(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };

  const { loading, data, refetch } = useQuery(GET_CUP, {
    variables: {
      id: cupId
    }
  });

  // 경기장 검색 관련
  const searchPlace = useInput("");
  const [searchingPlace, setSearchingPlace] = useState(false);
  const [openPlaceDialog, setOpenPlaceDialog] = useState(false);

  const { loading: loadingPlace, data: searchPlaceData } = useQuery(
    SEARCH_PLACE,
    {
      skip: searchPlace.value === "" && searchPlace.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchPlace.value
      }
    }
  );


  // 경기장 변경 시 변경값 설정
  const onChangePlace = place => () => {
    setPlace(place.id);
    setAddress(place.address);
    placeNameInput.setValue(place.placeName);
    handlePlaceDialogClose();
  };

  // 경기장 검색창 오픈 이벤트
  const handlePlaceDialogOpen = () => {
    setOpenPlaceDialog(true);
  };

  // 경기장 검색창 닫기 이벤트
  const handlePlaceDialogClose = () => {
    setOpenPlaceDialog(false);
  };

  // 경기장 검색 값 변할 경우 리렌더링
  useEffect(() => {
    if (searchPlace.value.length > 1) {
      setSearchingPlace(true);
    } else {
      setSearchingPlace(false);
    }

    if (!loadingPlace && searchPlace) {
      setSearchingPlace(false);
    }
  }, [searchPlace.value, loadingPlace]);

  const handleEditPhotoOpen = type => () => {
    if (type === "logo") setEditLogo(!editLogo);
  };

  const onDropPicture = (type, acceptedFiles) => {
    if (type === "host") {
      const data = host;
      data.push(acceptedFiles);
      setHost(data);
    } else if (type === "organizer") {
      const data = organizer;
      data.push(acceptedFiles);
      setOrganizer(data);
    } else if (type === "support") {
      const data = support;
      data.push(acceptedFiles);
      setSupport(data);
    } else if (type === "sponsor") {
      const data = sponsor;
      data.push(acceptedFiles);
      setSponsor(data);
    }
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemovePicture = (type, removeFile) => {
    if (type === "host") {
      const data = host;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setHost(data);
    } else if (type === "organizer") {
      const data = organizer;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setOrganizer(data);
    } else if (type === "support") {
      const data = support;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setSupport(data);
    } else if (type === "sponsor") {
      const data = sponsor;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setSponsor(data);
    }
  };

  // 기존 사진 삭제 이벤트
  const onRemoveOldPicture = (type, removeFile) => () => {
    console.log(type, removeFile);
    if (type === "host") {
      const data = [...oldHost];
      data.splice(
        data.findIndex(i => i === removeFile),
        1
      );
      setOldHost(data);
    } else if (type === "organizer") {
      const data = [...oldOrganizer];
      data.splice(
        data.findIndex(i => i === removeFile),
        1
      );
      setOldOrganizer(data);
    } else if (type === "support") {
      const data = [...oldSupport];
      data.splice(
        data.findIndex(i => i === removeFile),
        1
      );
      setOldSupport(data);
    } else if (type === "sponsor") {
      const data = [...oldSponsor];
      data.splice(
        data.findIndex(i => i === removeFile),
        1
      );
      setOldSponsor(data);
    }
  };

  // // 기존 사진 삭제 이벤트
  // const handleOldProfileDelete = async () => {
  //   await deleteProfileMutation({
  //     variables: { id: removeProfile }
  //   });
    
  //   const data = oldProfiles;
  //   data.splice(data.findIndex(i => i.id === removeProfile), 1);
  //   setOldProfiles(data);
  //   handleRemoveProfileClose();
  // }

  // // 기존 프로필 사진 삭제 확인 팝업
  // const handleRemoveProfileOpen = (id) => () => {
  //   setOpenRemoveProfile(true);
  //   setRemoveProfile(id);
  // };

  // // 기존 프로필 사진 삭제 확인 팝업
  // const handleRemoveProfileClose = () => {
  //   setOpenRemoveProfile(false);
  //   setRemoveProfile(null);
  // };
  

  // 전체 업로드
  const handleSubmit = async () => {
    if (uploading) return false;

    try {
      if (cupName.value === "") {
        toast.error("대회 이름을 추가해주세요");
      } else if (target.value === "") {
        toast.error("참가 대상을 입력해주세요");
      } else if (cupProcess.value === "") {
        toast.error("리그 진행 방식을 입력해주세요");
      } else if (addressS === "") {
        toast.error("대회 주소를 입력해주세요");
      } else if (cupStartDate.value >= cupEndDate.value) {
        toast.error("대회 날짜 정보를 정확히 입력해주세요");
      } else {
        setUploading(true);

        let hostUrl=[];
        let organizerUrl=[];
        let supportUrl=[];
        let sponsorUrl=[];

        console.log({
          host,
          organizer,
          support,
          sponsor
        });

        const serverURL =
          process.env.NODE_ENV === "development"
            ? "http://localhost:5000/"
            : "https://weplay2921.cafe24.com/";

        if (host && host.length > 0) {
          const formData = new FormData();

          host.forEach(file => {
            formData.append("cup", file);
          });

          const { data } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          hostUrl = data;
        }

        if (organizer && organizer.length > 0) {
          const formData = new FormData();

          organizer.forEach(file => {
            formData.append("cup", file);
          });

          const { data } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          organizerUrl = data;
        }

        if (support && support.length > 0) {
          const formData = new FormData();

          support.forEach(file => {
            formData.append("cup", file);
          });
          const { data } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          supportUrl = data;
        }

        if (sponsor && sponsor.length > 0) {
          const formData = new FormData();

          sponsor.forEach(file => {
            formData.append("cup", file);
          });

          const { data } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          sponsorUrl = data;
        }

        //moment.utc(utcTime ).local().format('YYYY-MM-DD HH:mm:ss');
        console.log({
          cupName: cupName.value,
          target: target.value,
          cupProcess: cupProcess.value,
          playerCnt: playerCnt.value,
          cupStartDate: moment.utc(cupStartDate.value).local().format(),
          formatStart: moment(cupStartDate.value).format(),
          cupEndDate: cupEndDate.value,
          placeId: place,
          lat: latS,
          lng: lngS,
          placeName: placeNameInput.value,
          address: addressS,
          shortAddress: shortAddressS,

          youtubeUrl: youtubeUrl.value,
          joinPrice: joinPriceUnit.value + joinPrice.value,
          host: [...oldHost,...hostUrl],
          organizer: [...oldOrganizer, ...organizerUrl],
          support:[...oldSupport, ...supportUrl],
          sponsor: [...oldSponsor,...sponsorUrl],

          contact: contact.value,
          DepositAccount: DepositAccount.value
        });
        const {
          data: { editCup }
        } = await editCupMutation({
          variables: {
            id:cupId,
            cupName: cupName.value,
            target: target.value,
            cupProcess: cupProcess.value,
            playerCnt: playerCnt.value,
            cupStartDate: cupStartDate.value,
            cupEndDate: cupEndDate.value,
            cupDateInfo: cupDateInfo.value,
            group,
            placeId: place,
            lat: latS,
            lng: lngS,
            placeName: placeNameInput.value,
            address: addressS,
            shortAddress: shortAddressS,

            youtubeUrl: youtubeUrl.value,
            joinPrice:joinPrice.value ===""? null :joinPriceUnit.value + joinPrice.value,
            host: [...oldHost,...hostUrl],
            organizer: [...oldOrganizer, ...organizerUrl],
            support:[...oldSupport, ...supportUrl],
            sponsor: [...oldSponsor,...sponsorUrl],
            contact: contact.value,
            DepositAccount: DepositAccount.value
          }
        });

        if (editCup.id) {
          toast.success("수정 완료!");
          refetch();
        }
      }
    } catch (e) {
      
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };


  const AddGroup = () => {
    const _group = [...group];
    if(groupInput.value === "") {
      toast.error("그룹 이름을 입력해주세요.");
      return;
    }
    if(_group.indexOf(groupInput.value) !== -1) {
      toast.error("이미 같은 이름의 그룹이 있습니다.");
      return;
    }
    _group.push(groupInput.value);
    setGroup(_group);
    groupInput.setValue("");
  }

  const DeleteGroup = g => () => {
    const _group = [...group];
    _group.splice(_group.findIndex(i => i === g), 1);
    setGroup(_group);
  }

  return (
    <CupEditPresenter
      loading={loading}
      data={data}
      cupId={cupId}
      uploading={uploading}
      editLogo={editLogo}
      handleEditPhotoOpen={handleEditPhotoOpen}
      handleSubmit={handleSubmit}
      addressS={addressS}
      onDropPicture={onDropPicture}
      onRemovePicture={onRemovePicture}
      onRemoveOldPicture={onRemoveOldPicture}
      cupName={cupName}
      target={target}
      cupProcess={cupProcess}
      playerCnt={playerCnt}
      cupStartDate={cupStartDate}
      cupEndDate={cupEndDate}
      cupDateInfo={cupDateInfo}

      oldHost={oldHost}
      oldOrganizer={oldOrganizer}
      oldSupport={oldSupport}
      oldSponsor={oldSponsor}
      setOldHost={setOldHost}
      setOldOrganizer={setOldOrganizer}
      setOldSupport={setOldSupport}
      setOldSponsor={setOldSponsor}

      group={group}
      setGroup={setGroup}
      groupInput={groupInput}
      AddGroup={AddGroup}
      DeleteGroup={DeleteGroup}

      url={url}
      youtubeUrl={youtubeUrl}
      joinPriceUnit={joinPriceUnit}
      joinPrice={joinPrice}
      firstPrize={firstPrize}
      secondPrize={secondPrize}
      thirdPrize={thirdPrize}
      mvpPrize={mvpPrize}
      mannerPrize={mannerPrize}
      contact={contact}
      DepositAccount={DepositAccount}
      placeNameInput={placeNameInput}
      setPlace={setPlace}
      setAddress={setAddress}
      setAddressInfo={setAddressInfo}
      onChangePlace={onChangePlace}
      searchPlaceData={searchPlaceData}
      searchPlace={searchPlace}
      searchingPlace={searchingPlace}
      openPlaceDialog={openPlaceDialog}
      handlePlaceDialogOpen={handlePlaceDialogOpen}
      handlePlaceDialogClose={handlePlaceDialogClose}
    />
  );
};

export default withRouter(CupEditContainer);
