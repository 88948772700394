import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_TEAMS,
  DELETE_TEAM
} from "./CupTeamListQueries";
import CupTeamListPresenter from "./CupTeamListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const desktopColumn = [
  { title: "팀이름", field: "teamName", headerStyle: { fontWeight: "800" } },
  {
    title: "대표자",
    field: "agent",
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "전적",
    editable: "never",
    headerStyle: { fontWeight: "800" },
    render: rowData => {
      return (
        rowData && (
          <span>
            {rowData.win}승 {rowData.draw}무 {rowData.lose}패
          </span>
        )
      );
    }
  },

  {
    title: "연락처",
    field: "contact",
    initialEditValue: "",
    headerStyle: { fontWeight: "800" }
  }
];

const mobileColumn = [
  { title: "팀이름", field: "teamName" },
  { title: "대표자", field: "agent" },
  { title: "연락처", field: "contact" },
];


export default withRouter(({ match: { params }, history }) => {
  const cupId = params.cup;
  
  const [first] = useState(10);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { data, loading, refetch, fetchMore } = useQuery(SEE_TEAMS, {
    variables: {
      first: first,
      skip: skip,
      cupId
    }
  });

  const [teams, setTeams] = useState({
    columns: matches ? mobileColumn : desktopColumn,
    data: []
  });

  const deleteTeamMutation = useMutation(DELETE_TEAM);

  useEffect(() => {
    if(loading)
      setTeams({columns : matches ? mobileColumn : desktopColumn, ...teams});
    else if(!loading && data && data.seeTeams) {
      setTeams({columns : matches ? mobileColumn : desktopColumn, data:data.seeTeams});
      refetch();
    }
      
  }, [matches, data, loading])


  const onChangePage = () => {

    setLoadMore(true);
    fetchMore({
      variables: {
        skip: data.seeCupTeams.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadMore(false);
        if(fetchMoreResult.seeCupTeams.length === 0)
          toast.error("조회할 데이터가 없습니다.");
        else
          setTeams({...teams, data:[...teams.data, ...fetchMoreResult.seeCupTeams]});

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          seeCupTeams: [...prev.seeCupTeams, ...fetchMoreResult.seeCupTeams]
        });
      }
    });
  };


  const handleDelete = async oldData => {
    await deleteTeamMutation({
      variables: { id: oldData.id, action:"DELETE" }
    });

    refetch();
  };

  const onCreatePage = () => {
    history.push(`/cup/${cupId}/team/create`);
  };

  const onUpdatePage = id => {
    history.push(`/cup/${cupId}/team/${id}`);
  };

  return (
    <CupTeamListPresenter
      loading={loading}
      data={data}
      cupId={cupId}
      loadMore={loadMore}
      onCreatePage={onCreatePage}
      onChangePage={onChangePage}
      handleDelete={handleDelete}
      teams={teams}
      setTeams={setTeams}
      matches={matches}
      onUpdatePage={onUpdatePage}
    />
  );
});

