import React from "react";
import styled from "styled-components";
import Input from "../../Components/Input";
import Button from "../../Components/AdminButton";

import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Box = styled.div`
  ${props => props.theme.whiteBox}
  border-radius:1px;
  width: 100%;
  max-width: 350px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 25px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 40px;
  margin-right: 12px;
`;

const StateChanger = styled(Box)`
  text-align: center;
  padding: 20px 0px;
  color: #000;
`;

const Link = styled.a`
  color: ${props => props.theme.blueColor};
  cursor: pointer;
`;

const Form = styled(Box)`
  padding: 40px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  form {
    width: 100%;
    input {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    button {
      margin-top: 10px;
    }
  }
`;

export default ({
  setAction,
  action,
  username,
  loginID,
  loginPW,
  onSubmit
}) => {
  return (
    <Wrapper>
      <Form>
        <form>
          <Header>
            <Logo src={"/imgs/weplay_symbol.png"} />
            위플레이 관리자
          </Header>
          <Helmet>
            <title>위플레이 관리자</title>
          </Helmet>

          {/* <form onSubmit={onSubmit}> */}
          <Input
            placeholder={"아이디"}
            {...loginID}
          />
          <Input
            placeholder={"비밀번호"}
            type="password"
            {...loginPW}
          />
          <Button text={"로그인"} onClick={onSubmit} />
          {/* </form> */}
         
          {/* {action === "signUp" && (
            <>
              <Helmet>
                <title>위플레이 관리자</title>
              </Helmet>
              <form onSubmit={onSubmit}>
                <Input placeholder={"아이디"} {...loginID} />
                <Input placeholder={"비밀번호"} type="password" {...loginPW} />
                <Input placeholder={"사용자 이름"} {...username} />
                <Button text={"가입"} />
              </form>
            </>
          )} */}
        </form>
      </Form>
      {/* {action !== "confirm" && (
        <StateChanger>
          {action === "logIn" ? (
            <>
              계정이 없으신가요?
              <Link onClick={() => setAction("signUp")}> 가입하기</Link>
            </>
          ) : (
            <>
              계정이 있으신가요?
              <Link onClick={() => setAction("logIn")}> 로그인</Link>
            </>
          )}
        </StateChanger>
      )} */}
    </Wrapper>
  );
};
