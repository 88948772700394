import { gql } from "apollo-boost";


export const GET_CUP_BANNER = gql`
  query seeCup($id: String!) {
    seeCup(id: $id) {
      id
      bannerMain,
      bannerNotice,
      bannerGames,
      bannerRank,
      bannerTeams
    }
  }
`;

export const UPDATE_BANNER = gql`
  mutation updateBanners(
      $id:String!
      $bannerMain:String
      $bannerNotice:String
      $bannerGames:String
      $bannerRank:String
      $bannerTeams:String
    ) {
      updateBanners(
        id:$id
        bannerMain:$bannerMain
        bannerNotice:$bannerNotice
        bannerGames:$bannerGames
        bannerRank:$bannerRank
        bannerTeams:$bannerTeams
      ) {
        id
      }
    }
`;

