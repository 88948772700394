import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from 'moment';
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
//import Button from "../../Components/AdminButton";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "../../Components/Avatar";
import CupHeader from "../../Components/CupHeader";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ClearIcon from "@material-ui/icons/Clear";

const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  overflow-x: scroll; */
  min-height: calc(100vh - 76px);
`;

const ContentWrapper = styled.div`
  color: #000;
`;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 20px 0 20px;
`;

const Title = styled.h4`
  font-size:24px;
  margin-bottom:15px;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  margin-left: 10px;
  min-width: 50px;
  @media (max-width: 600px) {
    font-size:14px;
    margin-left: 0px;
  }
`;

const HistoryWrap = styled.ul`
  padding: 0;
  max-width: 824px;
  margin: 0 auto;
  width: 95%;
  padding: 20px 0 30px;
`;

const Index = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  line-height: 32px;
  text-align: left;
  color: #333;
  font-size: 14px;
`;

const IndexMonth = styled.span`
  padding: 0 10px;
  font-weight: 600;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const IndexResult = styled.span`
  float: right;
  font-size: 13px;
  color: #666;
  text-align: right;
  padding: 0;
  padding-right: 10px;
  & > span {
    font-weight: 600;
  }
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 12px 15px 30px;
  overflow: auto;
  display: table;
  @media (max-width: 600px) {
    padding: 12px 8px 20px;
  }
`;

const ScheduleDate = styled.div`
  display:block;
  color: #999;
  font-size: 12px;
  padding-left: 7px;
  margin-top: 20px;
  & span:first-child {
    padding:5px 5px;
  }
`;

const GameInfoWrap = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      margin: 0 auto;
      width: 100%;
      justify-content: space-between;
    }
`;

const GameColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top:20px;
  display:flex;
  align-items: center;
  min-width:80px;
  position:relative;
  &:first-child, &:last-child {
    min-width: 130px;
  }
  @media (max-width: 600px) {
    padding: 1px;
    padding-top:20px;
    display: flex;
    flex-direction: column;
    &:first-child, &:last-child {
      min-width: auto;
      flex:1;
    }
  }
`;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position:relative;
  padding: 0px 10px;
`;

const EmptyAvatar = styled.div`
  border: 1px solid #000;
  border-radius: 50%;
  padding: 6px;
  min-width: 42px;
  min-height: 42px;
`;

const ScheduleInfo = styled.div`
  font-size: 13px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  vertical-align: middle;
  & > p {
    color: #999;
    font-size: 12px;
  }
`;

const ScheduleResult = styled.div`
  text-align: center;
  vertical-align: middle;
  max-width: ${props => props.mobile ?"auto":"250px"};
  display:${props => props.mobile ? "block":"table-cell"};
  position: relative;
`;

const GameNameBox = styled.div`
  border:1px solid #000;
  padding:7px 10px;
  position: relative;
  word-break: keep-all;
  margin-bottom:5px;

  @media (max-width: 600px) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const ELink = styled(Link)`
      display: table;
    width: 100%;
`;
const GameComplteBadge = styled.span`
  background-color:#4b4b4b;
  color:#fff;
  padding: 5px 8px;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  @media (max-width: 600px) {
    position:relative;
  }
`;

const WinText = styled.span`
  color: ${props => props.theme.yellowColor};
  font-size: 15px;
  font-family: 'Jalnan';
  left: 0px;
  right: 0px;
  top: 25px;
  @media (max-width: 600px) {
    top: 17px;
  }
  position: absolute;
  
`;

const CrownImg = styled.img`
  width: 30px;
  position: absolute;
  top: -18px;
  left: 6px;
  @media (max-width: 600px) {

  }
`;

const TeamAvatar = styled.div`
position: relative;
`;


const _gameDateText = gameDate => {
  // let date = new Date(gameDate);
  // return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
  return `${moment.utc(gameDate).format("YYYY. MM. DD")}`;
};

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const _gameTimeText = time => {
  const hour = parseInt(time.split(":")[0]);
  const minute =time.split(":")[1];
  const ampm = hour >= 12 ? "오후":"오전";
  if (hour > 12) return `${ampm} ${hour - 12}:${minute}`;
  else return `${ampm} ${hour}:${minute}`;
};

export default function MaterialTableDemo({
  cupId,
  loading,
  data,
  games,
  setGames,
  onScroll,
  onCreatePage,
  handleDelete,
  openDialog,
  handleDialogOpen,
  handleDialogClose,
  matches,
}) {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회 일정 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <CupHeader id={cupId} active={"games"} />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCupGames) {

    useEffect(() => {

      setGames(
        data.seeCupGames.reduce((acc, item) => {
          (acc[moment(item.gameDate).format("YYYY-MM")] =
            acc[moment(item.gameDate).format("YYYY-MM")] || []).push(item);
          return acc;
        }, {})
      );

      window.addEventListener('scroll', onScroll, true);
    }, [])


    console.log(data);

    return (
      <Wrapper>
        <Helmet>
          <title>대회 일정 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <CupHeader id={cupId} active={"games"} cupName={data.seeCup.cupName} />
        <ContentWrapper>
        <Section>
          <Title>일정/결과</Title>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreatePage}
         
          >
             경기 생성
            <AddIcon  />
          </Button>
        </Section>
        <HistoryWrap>
            {games &&
              Object.keys(games).map((key, i) => {
                const MONTH = key.split("-")[1];
                return (
                  <div key={i}>
                    <Index>
                      <IndexMonth>{MONTH}월</IndexMonth>
                    </Index>
                    {games[key].map(game => {

                      let routing = 
                        game.homeTeam && game.awayTeam ? `/cup/${cupId}/game/${game.id}`:`/cup/${cupId}/game/${game.id}/info`;
                    
                      return (
                       
                          <HistoryList key={game.id}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDialogOpen(game.id)}
                        
                          >
                            경기 삭제
                            <ClearIcon />
                          </Button>

                            <ScheduleDate>
                                {_gameDateText(game.gameDate)}{" "}
                                {_gameWeekText(game.gameDate)}{" "}
                                {_gameTimeText(game.startTime)}
                                <span>/</span>
                                <span>{game.place.placeName}</span>
                                <span> 
                                  
                                </span>
                               
                            </ScheduleDate>
                            <ELink key={game.id} to={routing}>
                            <ScheduleInfo>
                              <GameInfoWrap>
                                <GameColumn>
                                  {game.homeTeam? (
                                    <>
                                      <TeamAvatar>
                                        {game.winner === game.homeTeam.id && <CrownImg src="/imgs/crown.png"/>}
                                        <Avatar url={game.homeTeam.logo} size={"sm"}/>
                                      </TeamAvatar> 
                                      <TeamName>
                                        {game.homeTeam.teamName} 
                                        {game.winner === game.homeTeam.id && (<WinText left={true}>승</WinText>)}
                                      </TeamName>  
                                    </>  
                                  ):(
                                    <>
                                    <EmptyAvatar/>
                                    <TeamName>?</TeamName>
                                    </> 
                                  )}   
                                </GameColumn>
                                <GameColumn>
                                  <ColumnRow>
                                    <VSText>VS</VSText>
                                  </ColumnRow>
                                </GameColumn>
                                <GameColumn>
                                  {game.awayTeam? (
                                    <>
                                     <TeamAvatar>
                                        {game.winner === game.awayTeam.id && <CrownImg src="/imgs/crown.png"/>}
                                        <Avatar url={game.awayTeam.logo} size={"sm"} />
                                      </TeamAvatar>
                                    
                                      <TeamName>
                                        {game.awayTeam.teamName} 
                                        {game.winner === game.awayTeam.id && (<WinText right={true}>승</WinText>)}
                                      </TeamName>  
                                    </>  
                                  ):(
                                    <>
                                    <EmptyAvatar/>
                                    <TeamName>?</TeamName>
                                    </>  
                                  )}
                                </GameColumn>
                               
                              </GameInfoWrap>
                            </ScheduleInfo>
                            <ScheduleResult mobile={matches}>
                              <GameNameBox>{game.gameName} {">"} </GameNameBox>
                              {(game.gameStatus === "COMPLETE") && (
                                  <GameComplteBadge>경기 종료</GameComplteBadge>
                                )}
                            </ScheduleResult>
                            </ELink>
                            {/* {game.result ? (
                              <ScheduleResult>
                                <ScoreText>
                                  {game.homeTeam.id === teamId
                                    ? `${game.result.homeScore}:${game.result.awayScore}`
                                    : `${game.result.awayScore}:${game.result.homeScore}`}
                                </ScoreText>

                                {game.winner && game.winner === "draw" ? (
                                  <GameResultText state={game.winner} />
                                ) : (
                                  <GameResultText
                                    state={
                                      game.winner === teamId ? "win" : "lose"
                                    }
                                  />
                                )}
                              </ScheduleResult>
                            ) : (
                              <ScheduleResult>
                                <GameNameBox>{game.gameName} ></GameNameBox>
                                {/* <ScoreText>경기 대기</ScoreText> *
                              </ScheduleResult>
                            )} */}
                          </HistoryList>
                      
                      );
                    })}
                  </div>
                );
              })}
          </HistoryWrap>
        </ContentWrapper>
        

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"해당 경기를 삭제하시겠습니까?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
  else {
    return (
      <Wrapper>
        <Helmet>
          <title>대회 일정 | WePlay</title>
        </Helmet>
        <AdminHeader />
      </Wrapper>
    )
  }
  
}
