import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { GET_CUP } from "./CupMainQueries";
import CupMainPresenter from "./CupMainPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CupMainContainer = ({ match: { params }, history }) => {
  const cupId = params.cup;

  const { data, loading } = useQuery(GET_CUP, {
    variables: {
      id: cupId.toString()
    }
  });

  const onUpdatePage = () => {
    history.push(`/cup/${cupId}/edit`);
  };

  const onBannerPage = () => {
    history.push(`/cup/${cupId}/banner`);
  };
  
  return (
    <CupMainPresenter
      loading={loading}
      data={data}
      cupId={cupId}
      onUpdatePage={onUpdatePage}
      onBannerPage={onBannerPage}
    />
  );
};

export default withRouter(CupMainContainer);
