import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import AdminHeader from "../../Components/Header/AdminHeader";

import {
  fade,
  makeStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import {
  Button
} from "@material-ui/core";

import BoldText from "../../Components/BoldText";
import Progress from "../../Components/Progress";
import Loader from "../../Components/Loader";

import ImagesUploader from 'react-images-upload';

const Wrapper = styled.div``;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  userList: {
    border: "1px solid #f0f0f0",
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)",
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px",
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000",
    "& > p": {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      color: "#ff0000",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
  },
  control: {
    padding: theme.spacing(2),
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  appBar: {
    width: "500px",
    maxWidth: "100%",
    position: "relative",
    backgroundColor: "#227fff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px",
  },
  gridList: {
    marginTop: "20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  editLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "20px",
    "& > button": {
      marginLeft: "15px;",
    },
  },
  textField: {
    marginTop: "10px",
    marginBottom: "20px",
  },
  userField: {
    margin: "10px 10px 10px 0px",
    width: "50%",
  },
  joinPriceUnit: {
    width: 120,
    marginRight: 20,
  },
  textRight: {
    "& input": {
      textAlign: "right!important",
    },
  },
}));

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding: 15px 0px;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

export default ({
  loading,
  data,

  uploading,
  handleSubmit,
  onDropPicture,
  main,
  notice,
  game,
  rank,
  team,

  setMain,
  setNotice,
  setGame,
  setRank,
  setTeam,
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <Helmet>
          <title>팀 상세보기 | WePlay</title>
        </Helmet>
      </Wrapper>
    );
  }
  else if(!loading && data && data.seeCup) {
    const classes = useStyles();

    useEffect(() => {
      setMain(data.seeCup.bannerMain?[data.seeCup.bannerMain]:undefined);
      setNotice(data.seeCup.bannerNotice?[data.seeCup.bannerNotice]:undefined);
      setGame(data.seeCup.bannerGame?[data.seeCup.bannerGame]:undefined);
      setRank(data.seeCup.bannerRank?[data.seeCup.bannerRank]:undefined);
      setTeam(data.seeCup.bannerTeam?[data.seeCup.bannerTeam]:undefined);
    }, []);
  
    return (
      <Wrapper>
        <Helmet>
          <title>배너관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography variant="h3" className={classes.headerText} gutterBottom>
              배너관리
              <Link to={`/cups`}>
                <Button variant="contained" color="primary">
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>
  
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      메뉴별 배너
                      <p>※ 미입력시 기본 이미지가 표시됩니다.</p>
                    </Typography>
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <UserInfoRow>
                          <InputInfoColumn>
                            <BoldText text={"메인"} />
                          </InputInfoColumn>
                          <InputInfoColumn>
                            <DropZoneWrapper>
                              <ImagesUploader
                                withPreview={true}
                                defaultImages={main}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                onChange={(picture) => onDropPicture('main',picture)}
                                label="이미지를 업로드하세요."
                                buttonText='사진 선택'
                                singleImage={true}
                                maxFileSize={524288000}
                                />
                            </DropZoneWrapper>
                          </InputInfoColumn>
                        </UserInfoRow>
  
                        <UserInfoRow>
                          <InputInfoColumn>
                            <BoldText text={"공지"} />
                          </InputInfoColumn>
                          <InputInfoColumn>
                            <DropZoneWrapper>
                              <ImagesUploader
                                withPreview={true}
                                defaultImages={notice}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                onChange={(picture) => onDropPicture('notice',picture)}
                                label="이미지를 업로드하세요."
                                buttonText='사진 선택'
                                singleImage={true}
                                maxFileSize={524288000}
                                />
                            </DropZoneWrapper>
                          </InputInfoColumn>
                        </UserInfoRow>
  
                        <UserInfoRow>
                          <InputInfoColumn>
                            <BoldText text={"출전팀 리스트"} />
                          </InputInfoColumn>
                          <InputInfoColumn>
                            <DropZoneWrapper>
                              <ImagesUploader
                                withPreview={true}
                                defaultImages={team}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                onChange={(picture) => onDropPicture('team',picture)}
                                label="이미지를 업로드하세요."
                                buttonText='사진 선택'
                                singleImage={true}
                                maxFileSize={524288000}
                              />
                            </DropZoneWrapper>
                          </InputInfoColumn>
                        </UserInfoRow>
                      </Grid>
  
                      <Grid item xs={12} sm={6}>
                        <UserInfoRow>
                          <InputInfoColumn>
                            <BoldText text={"경기"} />
                          </InputInfoColumn>
                          <InputInfoColumn>
                            <DropZoneWrapper>
                              <ImagesUploader
                                withPreview={true}
                                defaultImages={game}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                onChange={(picture) => onDropPicture('game',picture)}
                                label="이미지를 업로드하세요."
                                buttonText='사진 선택'
                                singleImage={true}
                                maxFileSize={524288000}
                              />
                            </DropZoneWrapper>
                          </InputInfoColumn>
                        </UserInfoRow>
  
                        <UserInfoRow>
                          <InputInfoColumn>
                            <BoldText text={"랭킹"} />
                          </InputInfoColumn>
                          <InputInfoColumn>
                            <DropZoneWrapper>
                              <ImagesUploader
                                withPreview={true}
                                defaultImages={rank}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                onChange={(picture) => onDropPicture('rank',picture)}
                                label="이미지를 업로드하세요."
                                buttonText='사진 선택'
                                singleImage={true}
                                maxFileSize={524288000}
                              />
                            </DropZoneWrapper>
                          </InputInfoColumn>
                        </UserInfoRow>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
  
              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleSubmit}>저장하기</UploadButton1>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    );
  }
  
};
