import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
import Avatar from "../../Components/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import Progress from "../../Components/Progress";


export default ({
  loading,
  data,
  cupId,
  gameId,
  homeUsers,
  setHomeUsers,
  awayUsers,
  setAwayUsers,
  handleUpdate,
  handleScoreUpdate,
  setPlayCount,
  homeScore,
  setHomeScore,
  awayScore,
  setAwayScore,
  scoreEditMode,
  handleToggle,
  updateScore,
  setGameResultId,
  playTime,
  homeTeamFoul,
  awayTeamFoul,
  uploading,
  freeShotInput,
  freeShotTotalInput
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullGame) {

    const FiveGameColumn = [
      { title: "이름", field: "username", editable: "never" },
      { title: "포지션", field: "basket.position", editable: "never" },
      {
        title: "득점",
        field: "thisUserStat.score",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "자유투",
        field: "thisUserStat.freeShot",
        type: "numeric",
        render: rowData => {
          return (
            rowData && (
              <span>{rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal} </span>
            )
          );
        },
        editComponent:rowData => {
          return (
            rowData && (
              <FreeShotWrap>
                <TextField type="number" defaultValue={rowData.rowData.thisUserStat.freeShot} inputRef={freeShotInput} />
                <TextField type="number" defaultValue={rowData.rowData.thisUserStat.freeShotTotal} inputRef={freeShotTotalInput} id="freeShotTotal"  />
              </FreeShotWrap>
            )
          );
        },
        emptyValue: 0
      },
      {
        title: "리바운드",
        field: "thisUserStat.rebound",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "어시스트",
        field: "thisUserStat.assist",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "블로킹",
        field: "thisUserStat.blocking",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "스틸",
        field: "thisUserStat.steal",
        type: "numeric",
        emptyValue: 0
      },
      { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 },
      {
        title: "턴오버",
        field: "thisUserStat.turnOver",
        type: "numeric",
        emptyValue: 0
      }
    ];

    const ThreeGameColumn = [
      { title: "이름", field: "username", editable: "never" },
      { title: "포지션", field: "basket.position", editable: "never" },
      {
        title: "1점",
        field: "thisUserStat.oneScore",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "2점",
        field: "thisUserStat.twoScore",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "성공 자유투 ",
        field: "thisUserStat.freeShot",
        type: "numeric",
        render: rowData => {
          return (
            rowData && (
              <span>{rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal} </span>
            )
          );
        },
        editComponent:rowData => {
          return (
            rowData && (
              <FreeShotWrap>
                <TextField defaultValue={rowData.rowData.thisUserStat.freeShot} inputRef={freeShotInput} />
                <TextField defaultValue={rowData.rowData.thisUserStat.freeShotTotal} inputRef={freeShotTotalInput} id="freeShotTotal"  />
              </FreeShotWrap>
            )
          );
        },
        emptyValue: 0
      },
      {
        title: "리바운드",
        field: "thisUserStat.rebound",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "어시스트",
        field: "thisUserStat.assist",
        type: "numeric",
        emptyValue: 0
      },
      {
        title: "스틸",
        field: "thisUserStat.steal",
        type: "numeric",
        emptyValue: 0
      },
      { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 }
    ];
    
    const {
      seeFullGame: { gameName, homeTeam, awayTeam, result, playerCnt }
    } = data;
    
    let userColumn;
    const defaultStat = {
      score: 0,
      rebound: 0,
      assist: 0,
      blocking: 0,
      steal: 0,
      turnOver: 0,
      foul: 0,
      oneScore: 0,
      twoScore: 0,
      threeScore: 0,
      freeShot: 0,
      freeShotTotal: 0
    };


    if (playerCnt === 3) {
      userColumn = ThreeGameColumn ;
    } else if (playerCnt === 5) {
      userColumn = FiveGameColumn;
    }


    useEffect(() => {
      if (result) {
        setGameResultId(result.id);
        setHomeScore(result.homeTeam);
        setAwayScore(result.awayTeam);
        homeTeamFoul.setValue(result.homeTeamFoul);
        awayTeamFoul.setValue(result.awayTeamFoul);
        playTime.setValue(result.playingTime);
      } 

      setPlayCount(playerCnt);

      const homeMember = homeTeam.host? [homeTeam.host].concat(homeTeam.users): homeTeam.users;
      setHomeUsers(
        homeMember.map(user => {
          if (user.userStat.length > 0) {
            const filteredStat = user.userStat.filter(
              stat => stat.game.id === gameId
            )[0];
            user.thisUserStat = filteredStat? filteredStat:defaultStat;

          } else {
            user.thisUserStat = defaultStat;
          }
          return user;
        })
      );
      const awayMember = awayTeam.host? [awayTeam.host].concat(awayTeam.users): awayTeam.users;
      setAwayUsers(
        awayMember.map(user => {
          if (user.userStat.length > 0) {
            const filteredStat = user.userStat.filter(
              stat => stat.game.id === gameId
            )[0];
            user.thisUserStat = filteredStat? filteredStat:defaultStat;
          } else {
            user.thisUserStat = defaultStat;
          }
          return user;
        })
      );
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>경기 정보 | WePlay</title>
        </Helmet>
        <AdminHeader />
      {uploading && <Progress /> }
        <Section>
          <Grid item>
            <Typography variant="h4" gutterBottom>
              경기 상세 정보
              <Link to={`/cup/${cupId}/games`}>
                <Button variant="contained" color="primary">
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>
        </Section>
        <Section>
          <GameHeader>
            <GameHeaderColumn>
              <HeaderAvatar>
                <Link to={`/cup/${cupId}/team/${homeTeam.id}`}>
                  <Avatar url={homeTeam.logo} size={"lmd"} />
                </Link>
              </HeaderAvatar>
              <TeamName>{homeTeam.teamName}</TeamName>
            </GameHeaderColumn>
            <GameHeaderColumn>
              <VSText>VS</VSText>
            </GameHeaderColumn>
            <GameHeaderColumn>
              <HeaderAvatar>
                <Link to={`/cup/${cupId}/team/${awayTeam.id}`}>
                  <Avatar url={awayTeam.logo} size={"lmd"} />
                </Link>
              </HeaderAvatar>
              <TeamName>{awayTeam.teamName}</TeamName>
            </GameHeaderColumn>
          </GameHeader>

          {playerCnt === 5 ? (
            <ScoreTable>
              <ScoreThead>
                <tr>
                  <th>{gameName}</th>
                  <th>1쿼</th>
                  <th>2쿼</th>
                  <th>3쿼</th>
                  <th>4쿼</th>
                  <th>최종</th>
                </tr>
              </ScoreThead>
              <ScoreTbody>
                <tr>
                  <td>{homeTeam.teamName}</td>
                  {scoreEditMode
                    ? homeScore.map((score, i) => (
                        <td key={i}>
                          <Input
                            value={score}
                            placeholder={0}
                            type="text"
                            onChange={e => updateScore(e, i, "home")}
                          />
                        </td>
                      ))
                    : homeScore.map((score, i) => <td key={i}>{score}</td>)}
                  <td>
                    {homeScore.reduce(
                      (acc, cur) =>
                        parseInt(acc) + (cur === "" ? 0 : parseInt(cur))
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{awayTeam.teamName}</td>
                  {scoreEditMode
                    ? awayScore.map((score, i) => (
                        <td key={i}>
                          <Input
                            value={score}
                            placeholder={0}
                            type="text"
                            onChange={e => updateScore(e, i, "away")}
                          />
                        </td>
                      ))
                    : awayScore.map((score, i) => <td key={i}>{score}</td>)}
                  <td>
                    {awayScore.reduce(
                      (acc, cur) =>
                        parseInt(acc) + (cur === "" ? 0 : parseInt(cur))
                    )}
                  </td>
                </tr>
              </ScoreTbody>
            </ScoreTable>
          ) : (
            <ThreeGameWrapper>
              <TableWrapper>
                <ScoreTable>
                  <ScoreThead>
                    <tr>
                      <th>{gameName}</th>
                      <th>최종</th>
                      <th>팀파울</th>
                    </tr>
                  </ScoreThead>
                  <ScoreTbody>
                    <tr>
                      <td>{homeTeam.teamName}</td>
                      {scoreEditMode ? (
                        <td>
                          <Input
                            value={homeScore[0]}
                            placeholder={0}
                            type="text"
                            onChange={e => updateScore(e, 0, "home")}
                          />
                        </td>
                      ) : (
                        <td>{homeScore[0]}</td>
                      )}
                      {scoreEditMode ? (
                        <td>
                          <Input
                            value={homeTeamFoul.value}
                            placeholder={0}
                            type="text"
                            onChange={homeTeamFoul.onChange}
                          />
                        </td>
                      ) : (
                        <td>{homeTeamFoul.value}</td>
                      )}
                    </tr>

                    <tr>
                      <td>{awayTeam.teamName}</td>
                      {scoreEditMode ? (
                        <td>
                          <Input
                            value={awayScore[0]}
                            placeholder={0}
                            type="text"
                            onChange={e => updateScore(e, 0, "away")}
                          />
                        </td>
                      ) : (
                        <td>{awayScore[0]}</td>
                      )}
                      {scoreEditMode ? (
                        <td>
                          <Input
                            value={awayTeamFoul.value}
                            placeholder={0}
                            type="text"
                            onChange={awayTeamFoul.onChange}
                          />
                        </td>
                      ) : (
                        <td>{awayTeamFoul.value}</td>
                      )}
                    </tr>
                  </ScoreTbody>
                </ScoreTable>
              </TableWrapper>
              <PlayTime>
                <PlayTimeTitle>플레이 시간</PlayTimeTitle>
                {scoreEditMode ? (
                  <PlayTimeInputWrap>
                    <PlayTimeInput
                      value={playTime.value}
                      placeholder={0}
                      type="text"
                      onChange={playTime.onChange}
                    />
                  </PlayTimeInputWrap>
                ) : (
                  <PlayTimeInputWrap>{playTime.value}</PlayTimeInputWrap>
                )}
              </PlayTime>
            </ThreeGameWrapper>
          )}

          <GameComplete>
            <Link to={`/cup/${cupId}/game/${gameId}/info`}>
              <Button
                variant="contained"
              >
                경기 정보 수정
              </Button>
            </Link>
            {scoreEditMode ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleScoreUpdate}
              >
                경기 종료하기
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleToggle}
              >
                점수 수정하기
              </Button>
            )}
          </GameComplete>
        </Section>

        <Section>
          <TeamTitle>{homeTeam.teamName}</TeamTitle>
          <MaterialTable
            columns={userColumn}
            data={homeUsers}
            style={{ border: "1px solid #000", boxShadow: "none" }}
            options={{
              toolbar: false,
              search: false,
              actionsColumnIndex: -1,
              paging: false,
              filtering: false,
              sorting: false,
              draggable: false,
              headerStyle: { padding: 5, backgroundColor: "#dddddd" }
            }}
            localization={{
              header: {
                actions: ""
              }
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                if(freeShotInput.current && freeShotTotalInput.current) {
                  newData.thisUserStat.freeShot = freeShotInput.current.value;
                  newData.thisUserStat.freeShotTotal = freeShotTotalInput.current.value;
                }
                return handleUpdate(newData, oldData, "home")  
              }
            }}
          />

          <TeamTitle>{awayTeam.teamName}</TeamTitle>
          <MaterialTable
            columns={userColumn}
            data={awayUsers}
            style={{
              border: "1px solid #000",
              boxShadow: "none"
            }}
            options={{
              toolbar: false,
              search: false,
              pageSize: 5,
              actionsColumnIndex: -1,
              paging: false,
              filtering: false,
              sorting: false,
              draggable: false,
              headerStyle: { padding: 5, backgroundColor: "#dddddd" }
            }}
            localization={{
              header: {
                actions: ""
              }
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                if(freeShotInput.current && freeShotTotalInput.current) {
                  newData.thisUserStat.freeShot = freeShotInput.current.value;
                  newData.thisUserStat.freeShotTotal = freeShotTotalInput.current.value;
                }
                return handleUpdate(newData, oldData, "away")
              }
                
            }}
          />
        </Section>
      </Wrapper>
    );
  }
};




const Wrapper = styled.div``;
const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 95%;
  padding: 40px 20px 20px;
  background-color: #fff;
  & .MuiTableCell-root {
    min-width: 70px;
    text-align: center;
  }
  & .MuiInputBase-input {
    text-align: center !important;
    font-size: 13px;
  }
  &:last-child {
    padding-top: 10px;
    min-height: 50vh;
  }
`;

const GameHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background-color: #4c4d4f;
  color: #fff;
  padding: 10px 0px;
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const VSText = styled.span`
  font-size: 35px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 0px 15px;
  }
  @media (max-width: 425px) {
    font-size: 18px;
    padding: 0px 0px;
  }
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  min-width: 99px;
  min-height: 99px;
`;

const TeamName = styled.span`
  font-size: 38px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  margin-left: 10px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

const ThreeGameWrapper = styled.div`
  display:flex;
`;

const TableWrapper = styled.div`
  flex:1;
`;

const PlayTime = styled.div`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
  flex: 0.3;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 11px;
  }
`;

const PlayTimeTitle = styled.div`
  padding: 15px 0px;
  border-bottom: 1px solid #000;
`;

const PlayTimeInputWrap = styled.div`
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreTable = styled.table`
  border: 1px solid #000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 11px;
  }
`;

const ScoreThead = styled.thead`
  border-bottom: 1px solid #000;
  & > tr {
    border-bottom: 1px solid #000;
    & > th:first-child {
      font-weight: 600;
    }
  }
  & th {
    padding: 15px 0px;
  }
`;

const ScoreTbody = styled.tbody`
  & > tr {
    border-top: 1px solid #dddddd;
    & > td:first-child {
      font-weight: 600;
      max-width: 150px;
    }
  }
  & td {
    padding: 15px 0px;
  }
`;

const FreeShotWrap = styled.div`
  max-width: 160px;
`;

const GameComplete = styled.div`
  text-align: right;
  padding-top: 10px;
  & > button {
    margin-left:20px;
  }
`;

const TeamTitle = styled.h4`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  text-align: center;
  width: 40px;
  font-size: 16px;
  /* border-radius: 10px; */
  border: none;
  border: 1px solid #000;
  padding: 5px;
  &:focus {
    border: 1px solid;
  }
  &:disabled {
    color: #000;
    cursor: default;
    background-color: #fff;
  }
`;

const PlayTimeInput = styled(Input)`
  width: 80px;
`;