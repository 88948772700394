import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import Progress from "../../Components/Progress";
import AdminHeader from "../../Components/Header/AdminHeader";

import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import {
  TextField,
  Button,
  InputAdornment,
  DialogContent,
  DialogContentText,
  IconButton
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import MultipleSelect from "../../Components/MultiSelect";
import Loader from "../../Components/Loader";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import MaterialTable from "material-table";
import Avatar from "../../Components/Avatar";


export default ({
  loading,
  data,
  cupId,
  logoUrl,
  setLogoUrl,
  onDropProfles,
  onDropLogo,
  onRemoveProfile,
  onRemoveLogo,
  handleSubmit,
  teamNameInput,
  contactInput,
  bioInput,
  uploading,
  host,
  users,
  handleUpdate,
  setHost,
  setUsers,
  IsDialogHost,
  onUserAdd,
  editLogo,
  handleEditPhotoOpen,
  oldProfiles,
  setOldProfiles,
  editProfile,
  handleProfileDelete,
  openRemoveProfile,
  handleRemoveProfileOpen,
  handleRemoveProfileClose,
  openDialog,
  handleDialogOpen,
  handleDialogClose,
  handleUserDelete,
  email,
  username,
  backNo,
  userContact,
  age,
  height,
  weight,
  position,
  agentNameInput,
  positionInput
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <Helmet>
          <title>팀 상세보기 | WePlay</title>
        </Helmet>
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullTeam) {
    const classes = useStyles();


    const ThreeGameColumn = [
      { title: "이름", field: "username" },
      {
        title: "포지션",
        field: "basket.position",
        // render: rowData => {
        //   console.log("rowData:",rowData);
        //   return (
        //     rowData && (
        //       <MultipleSelect
        //         value={
        //           rowData.basket.position.length > 0 ? rowData.basket.position.split(",") : []
        //         }
        //         //onChange={rowData.setValue}
        //         datas={basketPositions}
        //       />
        //     )
        //   )
        // }
        render: rowData => {
          return (
            rowData && (
              <span>{rowData.basket.position} </span>
            )
          );
        },
        editComponent:rowData => {
          return (
            rowData && (
              <MultipleSelect
                value={
                  positionInput.value.length > 0
                  ? positionInput.value.split(",")
                  : rowData.rowData.basket.position.split(",")
                }
                onChange={rowData.onChange}
                datas={basketPositions}
              />
            )
          );
        },
        emptyValue: "미등록"
      },
      { title: "등번호", field: "basket.backNo", type: "numeric" },
      { title: "신장", field: "basket.height", type: "numeric" },
      { title: "체중", field: "basket.weight", type: "numeric" },
      {
        title: "득점",
        field: "userStatAvg.score",
        type: "numeric",
        emptyValue: 0,
        editable: "never"
      },
      {
        title: "자유투",
        field: "userStatAvg.freeShot",
        type: "numeric",
        editable: "never",
        render: rowData => {
          return (
            rowData && (
              <span>
                {rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal}{" "}
              </span>
            )
          );
        },
        emptyValue: 0
      },
      {
        title: "리바운드",
        field: "userStatAvg.rebound",
        type: "numeric",
        emptyValue: 0,
        editable: "never"
      },
      {
        title: "어시스트",
        field: "userStatAvg.assist",
        type: "numeric",
        emptyValue: 0,
        editable: "never"
      },
      {
        title: "스틸",
        field: "userStatAvg.steal",
        type: "numeric",
        emptyValue: 0,
        editable: "never"
      },
      {
        title: "파울",
        field: "userStatAvg.foul",
        type: "numeric",
        emptyValue: 0,
        editable: "never"
      }
    ];

    const componentConfig = {
      postUrl: "/"
    };
    const djsProfileConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 5
    };

    const djsLogoConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 1
    };

    const eventHandlers = {
      addedfile: file => {
        onDropProfles(file);
      },
      removedfile: file => onRemoveProfile(file),
      maxfilesexceeded: file => {
        console.log(file);
        alert("!!!");
      },
      init: () => {
        if (document.getElementsByClassName("dz-message")[0])
          document.getElementsByClassName("dz-message")[0].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
        if (document.getElementsByClassName("dz-message")[1])
          document.getElementsByClassName("dz-message")[1].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
      }
    };

    const eventHandlersLogo = {
      addedfile: file => onDropLogo(file),
      removedfile: file => onRemoveLogo(),
      maxfilesexceeded: file => {
        console.log(file);
        alert("!!!");
      },
      init: () => {
        if (document.getElementsByClassName("dz-message")[0])
          document.getElementsByClassName("dz-message")[0].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
        if (document.getElementsByClassName("dz-message")[1])
          document.getElementsByClassName("dz-message")[1].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
      }
    };

    const {
      seeFullTeam: {
        teamName,
        contact,
        agent,
        bio,
        host: teamHost,
        users: teamUsers,
        profile,
        logo: prevLogo
      },
      seeCup: { playerCnt }
    } = data;

    const defaultStat = {
      score: 0,
      rebound: 0,
      assist: 0,
      blocking: 0,
      steal: 0,
      turnOver: 0,
      foul: 0,
      oneScore: 0,
      twoScore: 0,
      threeScore: 0,
      freeShot: 0,
      freeShotTotal: 0
    };

    let userColumn;
    if (playerCnt === 3) {
      userColumn = ThreeGameColumn;
    } else if (playerCnt === 5) {
      userColumn = FiveGameColumn;
    }

    //console.log(data);

    useEffect(() => {
      teamNameInput.setValue(teamName);
      contactInput.setValue(contact);
      bioInput.setValue(bio);
      agentNameInput.setValue(agent);

      setLogoUrl(prevLogo);
      setOldProfiles(profile);

      if (teamHost) {
        if (teamHost.userStatAvg) {
          teamHost.thisUserStat = teamHost.userStatAvg;
        } else {
          teamHost.thisUserStat = defaultStat;
        }
        setHost(teamHost);
      }

      //const member = teamHost? [teamHost].concat(teamUsers): teamUsers;

      setUsers(
        teamUsers.map(user => {
          if (user.userStatAvg) {
            user.thisUserStat = user.userStatAvg;
          } else {
            user.thisUserStat = defaultStat;
          }
          return user;
        })
      );
    }, [data]);

    return (
      <Wrapper>
        <Helmet>
          <title>팀 상세보기 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              팀 상세보기
              <Link to={`/cup/${cupId}/teams`}>
                <Button variant="contained" color="primary">
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      팀 정보
                    </Typography>

                    <TextField
                      required
                      id="teamNameInput-required"
                      label="팀 이름"
                      value={teamNameInput.value}
                      onChange={teamNameInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                    <TextField
                      id="agentInput"
                      label="대표자(인솔자)"
                      value={agentNameInput.value}
                      onChange={agentNameInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />


                    <TextField
                      id="contactInput"
                      label="대표 연락처"
                      value={contactInput.value}
                      onChange={contactInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      id="filled-multiline-static"
                      label="팀 소개"
                      multiline
                      rows="4"
                      value={bioInput.value}
                      onChange={bioInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      미디어 정보
                    </Typography>

                    <DropZoneWrapper>
                      <ButtonWrap>
                        <FormLabel className={classes.editLabel}>
                          팀 프로필(최대 5개)
                          <Button
                            variant="contained"
                            onClick={handleEditPhotoOpen("profile")}
                          >
                            프로필 추가
                          </Button>
                        </FormLabel>
                      </ButtonWrap>

                      <GridList className={classes.gridList} cols={1.5}>
                        {oldProfiles.map(tile => (
                          <GridListTile key={tile.url}>
                            <img
                              src={tile.url}
                              alt={`위플레이 | ${teamName}`}
                            />
                            <GridListTileBar
                              classes={{
                                root: classes.titleBar,
                                title: classes.title
                              }}
                              actionIcon={
                                <IconButton
                                  aria-label={`Delete`}
                                  onClick={handleRemoveProfileOpen(tile.id)}
                                >
                                  <DeleteIcon className={classes.title} />
                                </IconButton>
                              }
                            />
                          </GridListTile>
                        ))}
                      </GridList>

                      {editProfile && (
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlers}
                          djsConfig={djsProfileConfig}
                        />
                      )}
                    </DropZoneWrapper>

                    <DropZoneWrapper>
                      <ButtonWrap>
                        <FormLabel className={classes.editLabel}>
                          팀 로고
                          <Button
                            variant="contained"
                            onClick={handleEditPhotoOpen("logo")}
                          >
                            로고 추가
                          </Button>
                        </FormLabel>
                      </ButtonWrap>

                      {logoUrl && 
                        <Avatar
                          size={"lg"}
                          url={logoUrl}
                          border={true}
                          alt={`위플레이 | ${teamName}`}
                        />
                      }

                      {editLogo && (
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlersLogo}
                          djsConfig={djsLogoConfig}
                        />
                      )}
                    </DropZoneWrapper>
                  </Paper>
                </Grid>
              </Grid>

              <Section>
                <ButtonWrap>
                  <FormLabel className={classes.editLabel}>
                    대표선수(감독)
                    <Button
                      variant="contained"
                      onClick={handleDialogOpen("host")}
                    >
                      추가
                    </Button>
                  </FormLabel>
                </ButtonWrap>

                {host && (
                  <MaterialTable
                    columns={userColumn}
                    data={[host]}
                    style={{ border: "1px solid #000", boxShadow: "none" }}
                    options={{
                      toolbar: false,
                      search: false,
                      actionsColumnIndex: -1,
                      paging: false,
                      filtering: false,
                      sorting: false,
                      draggable: false,
                      headerStyle: { padding: 5, backgroundColor: "#dddddd" }
                    }}
                    localization={{
                      header: {
                        actions: ""
                      },
                      body: {
                        emptyDataSourceMessage: "해당 데이터가 없습니다.",
                        editRow: {
                          deleteText: "정말로 삭제하시겠습니까?"
                        }
                      }
                    }}
                    editable={{
                      onRowUpdate: async (newData, oldData) =>
                        handleUpdate(newData, oldData),
                      onRowDelete: async oldData => handleUserDelete(oldData)
                    }}
                  />
                )}

                <ButtonWrap>
                  <FormLabel className={classes.editLabel}>
                    선수*
                    <Button
                      variant="contained"
                      onClick={handleDialogOpen("user")}
                    >
                      추가
                    </Button>
                  </FormLabel>
                </ButtonWrap>

                <MaterialTable
                  columns={userColumn}
                  data={users}
                  style={{ border: "1px solid #000", boxShadow: "none" }}
                  options={{
                    toolbar: false,
                    search: false,
                    actionsColumnIndex: -1,
                    paging: false,
                    filtering: false,
                    sorting: false,
                    draggable: false,
                    headerStyle: { padding: 5, backgroundColor: "#dddddd" }
                  }}
                  localization={{
                    header: {
                      actions: ""
                    },
                    body: {
                      emptyDataSourceMessage: "해당 데이터가 없습니다.",
                      editRow: {
                        deleteText: "정말로 삭제하시겠습니까?"
                      }
                    }
                  }}
                  editable={{
                    onRowUpdate: async (newData, oldData) => {
                      if(positionInput.length > 0) {
                        newData.basket.position = positionInput.value;
                      }
                      return handleUpdate(newData, oldData)
                    },
                    onRowDelete: async oldData => handleUserDelete(oldData)
                  }}
                />
              </Section>

              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleSubmit}>수정완료</UploadButton1>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          className={classes.dialog}
          open={openDialog}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">
            {IsDialogHost ? "대표선수(감독) 추가" : "선수 추가"}{" "}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>모든 정보를 기입해주세요.</DialogContentText>
            <TextField
              autoFocus
              value={email.value}
              onChange={email.onChange}
              margin="dense"
              id="name"
              label="이메일"
              type="email"
              fullWidth
            />
            <TextField
              value={userContact.value}
              onChange={userContact.onChange}
              className={classes.userField}
              margin="dense"
              id="name"
              label="연락처(숫자만 입력)"
              type="text"
            />

            <TextField
              required
              id="standard-required"
              label="이름"
              value={username.value}
              onChange={username.onChange}
              className={classes.userField}
            />
            <TextField
              required
              id="standard-required"
              label="등번호"
              value={backNo.value}
              onChange={backNo.onChange}
              className={classes.userField}
            />
            <TextField
              id="standard-required"
              label="나이"
              value={age.value}
              onChange={age.onChange}
              className={classes.userField}
            />
            <TextField
              required
              id="standard-required"
              label="키"
              value={height.value}
              onChange={height.onChange}
              className={classes.userField}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>
              }}
            />
            <TextField
              required
              id="standard-required"
              label="몸무게"
              value={weight.value}
              onChange={weight.onChange}
              className={classes.userField}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>
              }}
            />

            <PositionWrap>
              <FormLabel className={classes.label}>포지션</FormLabel>
              <MultipleSelect
                value={
                  position.value.length > 0 ? position.value.split(",") : []
                }
                onChange={position.setValue}
                datas={basketPositions}
              />
            </PositionWrap>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              취소
            </Button>
            <Button onClick={onUserAdd} color="primary">
              추가
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openRemoveProfile}
          onClose={handleRemoveProfileClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"해당 사진을 삭제하시겠습니까?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleRemoveProfileClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleProfileDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openRemoveProfile}
          onClose={handleRemoveProfileClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"해당 사진을 삭제하시겠습니까?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleRemoveProfileClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleProfileDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
};

const FiveGameColumn = [
  { title: "이름", field: "username" },
  {
    title: "포지션",
    field: "basket.position",
    lookup: {
      PG: "PG",
      SG: "SG",
      SF: "SF",
      PF: "PF",
      C: "C"
    }
  },
  { title: "등번호", field: "basket.backNo", type: "numeric"},
  { title: "신장", field: "basket.height", type: "numeric" },
  { title: "체중", field: "basket.weight", type: "numeric" },
  {
    title: "득점",
    field: "userStatAvg.score",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "자유투",
    field: "userStatAvg.freeShot",
    type: "numeric",
    editable: "never",
    render: rowData => {
      return (
        rowData && (
          <span>
            {rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal}
          </span>
        )
      );
    },
    emptyValue: 0
  },
  {
    title: "리바운드",
    field: "userStatAvg.rebound",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "어시스트",
    field: "userStatAvg.assist",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "블로킹",
    field: "userStatAvg.blocking",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "스틸",
    field: "userStatAvg.steal",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "파울",
    field: "userStatAvg.foul",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "턴오버",
    field: "userStatAvg.turnOver",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  }
];



const basketPositions = [
  {
    value: "PG",
    text: "PG"
  },
  {
    value: "SG",
    text: "SG"
  },
  {
    value: "SF",
    text: "SF"
  },
  {
    value: "PF",
    text: "PF"
  },
  {
    value: "C",
    text: "C"
  }
];

const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  userList: {
    border: "1px solid #f0f0f0"
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px",
    },
    minHeight: 550
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  appBar: {
    width: "500px",
    maxWidth: "100%",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop: "20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel: {
    marginBottom: "15px",
    marginTop: "20px",
    "& > button": {
      marginLeft: "15px;"
    }
  },
  textField: {
    marginBottom: "20px"
  },
  userField: {
    margin: "10px 10px 10px 0px",
    width: "50%"
  }
}));

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const PositionWrap = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

const ButtonWrap = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Section = styled.div`
  overflow: hidden;
  /* max-width: 1024px; */
  margin: 0 auto;
  width: 100%;
  padding: 40px 20px 20px;
  background-color: #fff;
  & .MuiTableCell-root {
    text-align: center;
  }
  & .MuiInputBase-input {
    text-align: center !important;
    font-size: 13px;
  }
  &:last-child {
    padding-top: 10px;
    min-height: 50vh;
  }
  @media (max-width:600px) {
    padding: 20px 0px 0px;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
