import { useState } from "react";

export default (defaultValue, validator, price) => {
  const [value, setValue] = useState(defaultValue);

  function removeComma(str) {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
}

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const onChange = e => {
    const {
      target: { value }
    } = e;
    let willUpdate = true;
 
    if (typeof validator === "function") {
      willUpdate = validator(price?removeComma(value):value);
    }

 
    if (willUpdate) {
      setValue(price?numberWithCommas(removeComma(value)):value);
      
      //setValue(value);
    }
  }

  return { value, onChange, setValue };
};
