import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import Progress from "../../Components/Progress";
import AdminHeader from "../../Components/Header/AdminHeader";
import Avatar from "../../Components/Avatar";

import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, Button, InputAdornment, DialogContent, DialogContentText, DialogTitle, DialogActions } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import MultipleSelect from "../../Components/MultiSelect";

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const basketPositions = [
  {
    value: "PG",
    text: "PG"
  },
  {
    value: "SG",
    text: "SG"
  },
  {
    value: "SF",
    text: "SF"
  },
  {
    value: "PF",
    text: "PF"
  },
  {
    value: "C",
    text: "C"
  }
];

const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  userList: {
    border: "1px solid #f0f0f0"
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  appBar: {
    width: "500px",
    maxWidth: "100%",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop: "20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop:"20px",
    "& > button": {
      marginLeft: "15px;"
    }
  },
  textField: {
    marginBottom:"20px"
  },
  userField: {
    margin: "10px 10px 10px 0px",
    width: "50%",
  }
}));

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;


const PositionWrap = styled.div`
  margin-top:25px;
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;


const SquadWrap = styled.ul`

`;

const IndexSquad = styled.div`
  width: 100%;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  line-height: 32px;
  text-align: left;
  color: #333;
  font-family: 'exo 2',sans-serif;
  font-size: 14px;
`;

const IndexInfo = styled.ul`
  width: 40%;
  overflow: auto;
  float: right;
`;

const IndexInfoList = styled.li`
  float: right;
  width: 50px;
  text-align: center;
`;

const UserList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  overflow: auto;
  display: table;
`;

const UserListLeft = styled.div`
  float: left;
  display: table;
  vertical-align: middle;
`;

const UserListNumber = styled.div`
    width: 28px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #000;
    font-weight: 600;
    font-family: "Jalnan";
`;

const UserAvatar = styled.div`
  margin:0px 5px;
`;

const UserName = styled.div`
  display: table-cell;
  vertical-align: middle;
  & > span {
    font-size: 15px;
  }
  & > p {
    font-size: 13px;
    color: #999;
  }
`;

const UserListInfo = styled.ul`
  width: 40%;
  overflow: auto;
  display: table-cell;
  vertical-align: middle;
  & > li {
    float: right;
    width: 50px;
    text-align: center;
    font-size: 14px;
  }
  & > li > button {
    padding:0!important;
  }
`;

const HeaderButton = styled.span`
  padding-left:8px;
`;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  cupId,
  editProfile,
  onDropLogo,
  onRemoveLogo,
  onDropProfles,
  onRemoveProfile,
  handleSubmit,
  agentNameInput,
  teamNameInput,
  contactInput,
  bioInput,
  uploading,
  host,
  users,
  IsDialogHost,
  onUserAdd,
  editLogo,
  handleEditPhotoOpen,
  onRemoveUser,
  onRemoveHost,
  openDialog,
  handleDialogOpen,
  handleUserDialogOpen,
  handleDialogClose,
  email,
  contact,
  username,
  backNo,
  age,
  height,
  weight,
  position,
  SaveDataToLocalStorage
}) => {
  const classes = useStyles();

  const componentConfig = {
    postUrl: "/"
  };

  const djsLogoConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif",
    maxFiles: 1
  };

  const djsProfileConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif",
    maxFiles: 5
  };

  const eventHandlers = {
    addedfile: file => {
      onDropProfles(file);
    },
    removedfile: file => onRemoveProfile(file),
    maxfilesexceeded: file => {
      alert("!!!");
    },
    init: () => {
      if (document.getElementsByClassName("dz-message")[0])
        document.getElementsByClassName("dz-message")[0].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
      if (document.getElementsByClassName("dz-message")[1])
        document.getElementsByClassName("dz-message")[1].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
    }
  };

  const eventHandlersLogo = {
    addedfile: file => onDropLogo(file),
    removedfile: file => onRemoveLogo(),
    init: () => {
      if (document.getElementsByClassName("dz-message")[0])
        document.getElementsByClassName("dz-message")[0].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
      if (document.getElementsByClassName("dz-message")[1])
        document.getElementsByClassName("dz-message")[1].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
    }
  };

  // useEffect(() => {
  //   LoadDataFromLocalStorage();
  // }, []);


  return (
    <Wrapper>
      <Helmet>
        <title>팀생성 | WePlay</title>
      </Helmet>
      <AdminHeader />
      {uploading && <Progress />}
      <Container className={classes.container}>
        <Grid item>
          <Typography variant="h3" className={classes.headerText} gutterBottom>
            대회 팀 생성
            <HeaderButton>
              <Link to={`/cup/${cupId}/teams`}>
                <Button variant="contained" color="primary">
                  목록보기
                </Button>
              </Link>
            </HeaderButton>
            <HeaderButton>
              <Button variant="contained" color="secondary" onClick={SaveDataToLocalStorage}>
                임시저장
              </Button>
            </HeaderButton>
            
          </Typography>

        </Grid>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headerText}
                    gutterBottom
                  >
                    선수 정보
                  </Typography>


                  <FormLabel className={classes.editLabel}>
                    대표선수(감독)
                    <Button variant="contained" onClick={handleDialogOpen('host')}>
                      추가
                    </Button>
                  </FormLabel>
                  {host && (
                    <SquadWrap>
                      <IndexSquad>
                        <span>선수</span>
                        <IndexInfo>
                          <IndexInfoList>삭제</IndexInfoList>
                          <IndexInfoList>수정</IndexInfoList>
                          <IndexInfoList>신장</IndexInfoList>
                          <IndexInfoList>몸무게</IndexInfoList>
                        </IndexInfo>
                      </IndexSquad>
                      <UserList key={host.email}>
                        <UserListLeft>
                          <UserListNumber>{host.backNo}</UserListNumber>
                          <UserAvatar>
                            <Avatar url={host.avatar} />
                          </UserAvatar>
                          <UserName>
                            <span>{`${host.username}(감독)`}</span>
                            <p>{`${host.position}`}</p>
                          </UserName>
                        </UserListLeft>
                        <UserListInfo>
                          <li onClick={onRemoveHost}>
                            <IconButton aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </li>
                          <li>
                            <IconButton aria-label="edit" onClick={handleUserDialogOpen('host',host)}>
                              <EditIcon />
                            </IconButton>
                          </li>
                          <li>{`${host.height}cm`}</li>
                          <li>{`${host.weight}kg`}</li>
                        </UserListInfo>
                      </UserList>
                    </SquadWrap>
                  )}

                  <FormLabel className={classes.editLabel}>
                    선수 정보*
                    <Button variant="contained" onClick={handleDialogOpen('user')}>
                      추가
                    </Button>
                  </FormLabel>

                  {users.length > 0 && 
                    <SquadWrap>
                      <IndexSquad>
                        <span>선수</span>
                        <IndexInfo>
                          <IndexInfoList>삭제</IndexInfoList>
                          <IndexInfoList>수정</IndexInfoList>
                          <IndexInfoList>신장</IndexInfoList>
                          <IndexInfoList>몸무게</IndexInfoList>
                        </IndexInfo>
                      </IndexSquad>
                      {users.map((user,idx) => (
                        <UserList  key={user.email}>
                          <UserListLeft>
                            <UserListNumber>{user.backNo}</UserListNumber>
                            <UserAvatar>
                              <Avatar url={user.avatar} />
                            </UserAvatar>
                            <UserName>
                              <span>{user.username}</span>
                              <p>{`${user.position}`} {user.age?`${user.age}세`:`나이 미입력`}</p>
                            </UserName>
                          </UserListLeft>
                          <UserListInfo>   
                          <li onClick={onRemoveUser}>
                              <IconButton aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                            </li>
                            <li>
                              <IconButton aria-label="edit" onClick={handleUserDialogOpen('user',user, idx)}>
                              <EditIcon />
                            </IconButton>
                            </li>

                            <li>{`${user.height}cm`}</li>
                            <li>{`${user.weight}kg`}</li>
                          </UserListInfo>
                        </UserList>
                      ))}
                    </SquadWrap>
                  }

                  {/* 
                  {users.length > 0 && (
                   
                  )} */}

                  {/* {host ? (
                    <List className={classes.userList}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar url={host.avatar} size={"sm"} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={host.username}
                          secondary={host.email}
                        />
                      </ListItem>
                    </List>
                  ) : (
                    
                  )} */}

                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headerText}
                    gutterBottom
                  >
                    팀 정보
                  </Typography>

                  <TextField
                    required
                    id="teamNameInput"
                    label="팀 이름"
                    value={teamNameInput.value}
                    onChange={teamNameInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />
                   <TextField
                    required
                    id="agentNameInput"
                    label="대표자(인솔자)"
                    value={agentNameInput.value}
                    onChange={agentNameInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />



                  <TextField
                    id="contactInput"
                    label="대표 연락처"
                    value={contactInput.value}
                    onChange={contactInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />

                  <TextField
                    required
                    id="filled-multiline-static"
                    label="팀 소개"
                    multiline
                    rows="4"
                    value={bioInput.value}
                    onChange={bioInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />


                  <DropZoneWrapper>
                    <FormLabel className={classes.editLabel}>
                      팀 프로필(최대 5개)
                      <Button
                        variant="contained"
                        onClick={handleEditPhotoOpen("profile")}
                      >
                        프로필 추가
                      </Button>
                    </FormLabel>

                    {editProfile && (
                      <DropzoneComponent
                        className={classes.dropzone}
                        config={componentConfig}
                        eventHandlers={eventHandlers}
                        djsConfig={djsProfileConfig}
                      />
                    )}
                  </DropZoneWrapper>

                  <DropZoneWrapper>
                    <FormLabel className={classes.editLabel}>
                      팀 로고
                      <Button
                        variant="contained"
                        onClick={handleEditPhotoOpen("logo")}
                      >
                        로고 추가
                      </Button>
                    </FormLabel>

                    {editLogo && (
                      <DropzoneComponent
                        className={classes.dropzone}
                        config={componentConfig}
                        eventHandlers={eventHandlersLogo}
                        djsConfig={djsLogoConfig}
                      />
                    )}
                  </DropZoneWrapper>
                </Paper>
              </Grid>
            </Grid>

            <Grid container justify="center" spacing={2}>
              <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        className={classes.dialog}
        open={openDialog}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
       <DialogTitle id="form-dialog-title">
         {IsDialogHost?"감독 추가":"선수 추가"} </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            정보를 기입해주세요.
          </DialogContentText>

         
            <TextField
              autoFocus
              required
              value={email.value}
              onChange={email.onChange}
              margin="dense"
              id="email"
              label="이메일(필수값, 이메일 없을 경우 연락처 등 입력)"
              type="email"
              fullWidth
            />

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={6}>
            <TextField
              required
              id="standard-username"
              label="이름"
              value={username.value}
              onChange={username.onChange}
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              id="standard-age"
              label="나이"
              value={age.value}
              onChange={age.onChange}
            />
            </Grid>
          </Grid>


          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={6}>
            <TextField
              value={contact.value}
              onChange={contact.onChange}
              margin="dense"
              id="contact"
              label="연락처(숫자만 입력)"
              type="text"
            />

            </Grid>
            <Grid item xs={6}>
            <TextField
              required
              id="standard-backNo"
              label="등번호"
              value={backNo.value}
              onChange={backNo.onChange}
            />
          
            </Grid>
          </Grid>

        
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={6}>
            <TextField
              required
              id="standard-height"
              label="키"
              value={height.value}
              onChange={height.onChange}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                )
              }}
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
              required
              id="standard-weight"
              label="몸무게"
              value={weight.value}
              onChange={weight.onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                )
              }}
            />
          
            </Grid>
          </Grid>
           
          
           

            <PositionWrap>
              <FormLabel className={classes.label}>포지션</FormLabel>
              <MultipleSelect
                value={
                  position.value.length > 0
                    ? position.value.split(",")
                    : []
                }
                onChange={position.setValue}
                datas={basketPositions}
              />
            </PositionWrap>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            취소
          </Button>
          <Button onClick={onUserAdd} color="primary">
            추가
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};
