import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { UPDATE_BANNER, GET_CUP_BANNER } from "./CupBannerQueries";
import CupBannerPresenter from "./CupBannerPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const CupBannerContainer = ({ match: { params } , history }) => {
  const cupId = params.cup;

  const [main, setMain] = useState();
  const [notice, setNotice] = useState();
  const [game, setGame] = useState();
  const [rank, setRank] = useState();
  const [team, setTeam] = useState();

  const [files, setFiles] = useState({
    main:null,
    notice:null,
    game:null,
    rank:null,
    team:null
  });


  const [uploading, setUploading] = useState(false);
  
  const [editorState, setEditorState] = useState();

  const { loading, data } = useQuery(GET_CUP_BANNER, {
    variables: {
      id: cupId
    }
  });

  const editCupBannerMutation = useMutation(UPDATE_BANNER, { 
    refetchQueries:[{query:GET_CUP_BANNER, variables: { id: cupId }}]
  });

  const onDropPicture = (type, acceptedFiles) => {
    console.log("onDropPicture // ", type, acceptedFiles[0]);
    const reader = new FileReader();
    if(acceptedFiles[0]) {

      setFiles({...files, [type]:acceptedFiles[0]});

      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        if(type === 'main') {
          setMain([reader.result]);
        } else if(type === 'notice') {
          setNotice([reader.result]);
        } else if(type === 'game') {
          setGame([reader.result]);
        } else if(type === 'rank') {
          setRank([reader.result]);
        } else if(type === 'team') {
          setTeam([reader.result]);
        }
      };
    } else {
      setFiles({...files, [type]:null});

      if(type === 'main') {
        setMain(undefined);
      } else if(type === 'notice') {
        setNotice(undefined);
      } else if(type === 'game') {
        setGame(undefined);
      } else if(type === 'rank') {
        setRank(undefined);
      } else if(type === 'team') {
        setTeam(undefined);
      }
    }
   
    

    
  };

  // 전체 업로드
  const handleSubmit = async () => {

    if(uploading)
      return false;
 
    try {
        setUploading(true);

        let mainUrl = null;
        let noticeUrl = null;
        let gameUrl = null;
        let rankUrl = null;
        let teamUrl = null;

        const serverURL = process.env.NODE_ENV === "development"
        ? "http://localhost:5000/"
        : "https://weplay2921.cafe24.com/"

        if (files.main) {
          const formData = new FormData();
          formData.append("bannerMain", files.main);

          const {
            data : { location }
          } = await axios.post(
            `${serverURL}api/cup/banner/main/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );

          console.log("main : ", location)
          mainUrl = location;
        } 

        if (files.notice) {
          const formData = new FormData();
          formData.append("bannerNotice", files.notice);

          const {
            data: { location }
          } = await axios.post(
            `${serverURL}api/cup/banner/notice/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          noticeUrl = location;
        } 

        if (files.game) {
          const formData = new FormData();
          formData.append("bannerGame", files.game);

          const {
            data: { location }
          } = await axios.post(
            `${serverURL}api/cup/banner/game/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          gameUrl = location;
        } 

        if (files.rank) {
          const formData = new FormData();
          formData.append("bannerRank", files.rank);

          const {
            data: { location }
          } = await axios.post(
            `${serverURL}api/cup/banner/rank/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          rankUrl = location;
        } 

        if (files.team) {
          const formData = new FormData();
          formData.append("bannerTeam", files.team);

          const {
            data: { location }
          } = await axios.post(
            `${serverURL}api/cup/banner/team/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          teamUrl = location;
        } 
  
       
        console.log({
          id:cupId,
          bannerMain:mainUrl,
          bannerNotice:noticeUrl,
          bannerGames:gameUrl,
          bannerRank:rankUrl,
          bannerTeams:teamUrl
        })
        
        const {
          data: { updateBanners }
        } = await editCupBannerMutation({
          variables: { 
            id:cupId,
            bannerMain:mainUrl,
            bannerNotice:noticeUrl,
            bannerGames:gameUrl,
            bannerRank:rankUrl,
            bannerTeams:teamUrl
          }
        });

        if (updateBanners.id) {
          toast.success("저장 완료!");
        }

    } catch (e) {
     
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    setEditorState(editorState);
  }

  return (
    <CupBannerPresenter
      cupId={cupId}
      loading={loading}
      data={data}
      uploading={uploading}

      handleSubmit={handleSubmit}
      onDropPicture={onDropPicture}

      main={main}
      notice={notice}
      game={game}
      rank={rank}
      team={team} 

      setMain={setMain}
      setNotice={setNotice}
      setGame={setGame}
      setRank={setRank}
      setTeam={setTeam}

      editorState={editorState}
      setEditorState={setEditorState}
      onEditorStateChange={onEditorStateChange}
      
    />
  );
};

export default withRouter(CupBannerContainer);
