import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { UPSERT_BANNERS, GET_BANNERS, DELETE_BANNER } from "./MainBannerQueries";
import CupBannerPresenter from "./MainBannerPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const MainBannerContainer = () => {
  const [banners, setBanners] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [removeBanner, setRemoveBanner] = useState();

  const [uploading, setUploading] = useState(false);
  const { loading, data } = useQuery(GET_BANNERS);

  const editBannersMutation = useMutation(UPSERT_BANNERS, { 
    refetchQueries:[{query:GET_BANNERS}]
  });

  const deleteBannerMutation = useMutation(DELETE_BANNER);

  const onAddBanner = () => {
    const _banners = [...banners];
    _banners.push({
      file:null,
      link:"http://weplaysports.net"
    });
    setBanners(_banners);
  }

  const onRemoveBanner = async () => {
    const data = [...banners];

    if(removeBanner && banners[removeBanner].id) {
      await deleteBannerMutation({
        variables: { id: banners[removeBanner].id }
      });
    }

    data.splice(removeBanner, 1);
    setBanners( data );
    handleRemovePopupClose();
  }

  // 배너 사진 드롭 시 이벤트
  const onDropFiles = (idx, acceptedFiles) => {

    const file = acceptedFiles[acceptedFiles.length - 1];
    console.log(file);

    if(file) {
      const reader = new FileReader();
      reader.onloadend = function (e) {
        
        const data = [...banners];
        data[idx].url =  reader.result;
        data[idx].file = file;
        setBanners(data);
      };
      reader.readAsDataURL(file);

     
    } else {
      const data = [...banners];
      data[idx].file = null;
      data[idx].url = null;
      setBanners(data);
    }
  };

  // 기존 배너 사진 삭제 확인 팝업
  const handleRemovePopupOpen = (idx) => () => {
    setOpenRemoveDialog(true);
    setRemoveBanner(idx);
  };

  // 기존 배너 사진 삭제 확인 팝업
  const handleRemovePopupClose = () => {
    setOpenRemoveDialog(false);
    setRemoveBanner(null);
  };

  // 전체 업로드
  const handleSubmit = async () => {

    if(uploading)
      return false;
 
    if(banners.length === 0) {
      toast.error("배너를 추가해주세요.");
      return false;
    }

    let isImgError = false;
    let isUrlError = false;
    banners.forEach(banner => {
      if(banner.file === null || banner.file === undefined) 
        isImgError = true;

      if(banner.link === "" || banner.link === null) 
        isUrlError = true;
    });
    
    if(isImgError) {
      toast.error("이미지를 업로드해주세요.");
      return false;
    }

    if(isUrlError) {
      toast.error("연결할 URL을 모두 입력해주세요.");
      return false;
    }

    try {
        setUploading(true);

        const serverURL = process.env.NODE_ENV === "development"
        ? "http://localhost:5000/"
        : "https://weplay2921.cafe24.com/"

        let bannerJson = [];

        if (banners && banners.length > 0) {
         
          const formData = new FormData();
          
          const newBanners = banners.filter(banner => typeof banner.file === 'object');
          console.log(newBanners);
          banners.forEach(banner => {
            if(typeof banner.file === 'object') {
              //delete banner.file.url;

              formData.append("banner", banner.file);
            }
          });
          // if(newBanners.length > 0) {
          //   newBanners.forEach(newbanner => {
          //     if(typeof newbanner.file === 'object') {
          //       //delete banner.file.url;
          //       formData.append("banner", newbanner.file);
          //     }
          //   });
          // }
          

          const { data:CDN_links } = await axios.post(
            `${serverURL}api/banner/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
            console.log(CDN_links);

          banners.forEach((banner) => {
            if(typeof banner.file === 'object') {
              const fileSrc = CDN_links.shift();
              banner.file = fileSrc;
              banner.url = fileSrc;
            } 
          });
        }
  
        console.log(banners)
        const {
          data: { upsertBanners }
        } = await editBannersMutation({
          variables: { 
            banners:JSON.stringify(banners),
          }
        });
        console.log("upsertBanners:", upsertBanners)

        if (upsertBanners) {
          toast.success("저장 완료!");
        }

    } catch (e) {
     
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <CupBannerPresenter
      loading={loading}
      data={data}
      uploading={uploading}

      banners={banners}
      setBanners={setBanners}
      removeBanner={removeBanner}
      onAddBanner={onAddBanner}
      onRemoveBanner={onRemoveBanner}

      oldFiles={oldFiles}
      setOldFiles={setOldFiles}
      handleSubmit={handleSubmit}
      onDropFiles={onDropFiles}

      handleRemovePopupOpen={handleRemovePopupOpen}
      handleRemovePopupClose={handleRemovePopupClose}
      openRemoveDialog={openRemoveDialog}
    />
  );
};

export default withRouter(MainBannerContainer);
