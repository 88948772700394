import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_GAMES,
} from "./GameListQueries";
import GameListPresenter from "./GameListPresenter";
import { useQuery } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";

const desktopColumn = [
  {
    title: "홈팀",
    editable: "never",
    headerStyle: { fontWeight: "800" },
    render: rowData => {
      return (
        rowData && (
          <>
            <p>{rowData.homeTeam.teamName} VS</p>
            <span>{rowData.awayTeam ? rowData.awayTeam.teamName : "미정"}</span>
          </>
        )
      );
    }
  },
  {
    title: "경기장",
    editable: "never",
    field: "place.placeName",
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "경기상태",
    editable: "never",
    field: "gameStatus",
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "경기일정",
    editable: "never",
    field: "gameDate",
    render: rowData => moment(rowData.gameDate).format("YYYY-MM-DD"),
    initialEditValue: "",
    headerStyle: { fontWeight: "800" }
  }
];

const mobileColumn = [
  {
    title: "팀",
    render: rowData => {
      return (
        rowData && (
          <>
            <p>{rowData.homeTeam.teamName} VS</p>
            <span>{rowData.awayTeam ? rowData.awayTeam.teamName : "미정"}</span>
          </>
        )
      );
    }
  },
  {
    title: "경기일정",
    field: "gameDate",
    render: rowData => moment(rowData.gameDate).format("YYYY-MM-DD")
  }
];

export default withRouter(({history})=>{
  const [first] = useState(15);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const searchInput = useInput("");

  const { data, loading, fetchMore } = useQuery(SEE_GAMES, {
    variables: {
      term: searchInput.value,
      first: first,
      skip: skip
    }
  });

  const [games, setGames] = useState({
    columns: matches ? mobileColumn : desktopColumn,
    data: []
  });

  
  const onScroll = ({currentTarget}) => {
    if (
      canMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeGames.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
          if(fetchMoreResult.seeGames.length === 0)
            toast.error("조회할 데이터가 없습니다.");
          else
            setGames({...games, data:[...games.data, ...fetchMoreResult.seeGames]});
  
          if (!fetchMoreResult) return prev;
  
          return Object.assign({}, prev, {
            seeGames: [...prev.seeGames, ...fetchMoreResult.seeGames]
          });
        }
      });
    }
   
  };

  useEffect(() => {
    if (loading)
      setGames({ columns: matches ? mobileColumn : desktopColumn, ...games });
    else if (!loading && data && data.seeGames) {
      setGames({
        columns: matches ? mobileColumn : desktopColumn,
        data: data.seeGames
      });
    }
  }, [matches, data, loading]);


  const onUpdatePage = (id, code) => {
    history.push(`/game/${code}/${id}`);
  };

  return (
    <GameListPresenter
      loading={loading}
      data={data}
      games={games}
      setGames={setGames}
      loadMore={loadMore}
      canMore={canMore}
      setCanMore={setCanMore}
      onScroll={onScroll}
      onUpdatePage={onUpdatePage}
      searchInput={searchInput}
    />
  );
});
