import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import AdminHeader from "../../Components/Header/AdminHeader";
import MaterialTable, { MTableToolbar } from "material-table";
import { CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
  & .MuiTableCell-root {
    padding: 6px;
  }
`;

const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
  padding: 0px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
  /* padding-bottom:100px; */
`;

const LoaderWapeer = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: #fff;
  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 30px;
  }
`;

const useStyles = makeStyles(theme => ({
  toolBar: {
    display: "flex",
    justifyContent:"flex-end",
    paddingTop: "5px",
    width: "100%"
  },
  radioRroup: {
    padding:"0px 24px 20px"
  }
}));

export default function MaterialTableDemo({
  loading,
  data,
  matches,
  handleCreate,
  onUpdatePage,
  handleDelete,
  onScroll,
  loadMore,
  users,
  setUsers,
  canMore,
  searchInput,
  userType,
}) {

  // if (loading) {
  //   const superAdminEditable = {
  //     onRowAdd: async newData => handleCreate(newData),
  //     onRowDelete: oldData => handleDelete(oldData)
  //   };
    
  //   return (
  //     <Wrapper>
  //       <Helmet>
  //         <title>회원관리 | WePlay</title>
  //       </Helmet>
  //       <AdminHeader />
  //       <Loader background={false}/>

  //       <TableWrapper onScroll={e => onScroll(e)}>
  //         <MaterialTable
  //           title="회원 테이블"
  //           columns={users.columns}
  //           data={users.data}
  //           style={{
  //             margin: "0 auto",
  //             minHeight: "calc(100vh - 234px)",
  //             position: "unset",
  //             boxShadow: "none"
  //           }}
  //           actions={[
  //             {
  //               icon: "edit",
  //               tooltip: "수정",
  //               onClick: (event, rowData) => {
  //                 onUpdatePage(rowData.id);
  //               }
  //             }
  //           ]}
  //           onSearchChange={text => handleSearch(text)}
  //           options={{
  //             addRowPosition: "first",
  //             pageSize: 5,
  //             actionsColumnIndex: -1,
  //             detailPanelType: "single",
  //             paging: false,
  //             search: false
  //           }}
  //           // components={{
  //           //   Toolbar: props => (
  //           //     <div>
  //           //       <MTableToolbar {...props} />
  //           //       <Toolbar className={classes.toolBar}>
  //           //         <IconButton
  //           //           edge="end"
  //           //           color="inherit"
  //           //           //onClick={onClickClose}
  //           //           aria-label="close"
  //           //         >
  //           //           <Close />
  //           //         </IconButton>
  //           //         <div className={classes.search}>
  //           //           <div className={classes.searchIcon}>
  //           //             <SearchIcon />
  //           //           </div>
  //           //           <InputBase
  //           //             placeholder="회원검색"
  //           //             classes={{
  //           //               root: classes.inputRoot,
  //           //               input: classes.inputInput
  //           //             }}
  //           //             inputProps={{ "aria-label": "search" }}
  //           //             value={searchInput.value}
  //           //             onChange={searchInput.onChange}
  //           //           />
  //           //         </div>
  //           //       </Toolbar>
  //           //     </div>
  //           //   ),
  //           // }}
  //           localization={{
  //             pagination: {
  //               labelDisplayedRows: "총 {count}개 중 {from}-{to} "
  //             },
  //             toolbar: {
  //               nRowsSelected: "{0}개 선택",
  //               searchPlaceholder: "회원검색"
  //             },
  //             header: {
  //               actions: ""
  //             },

  //             body: {
  //               emptyDataSourceMessage: "해당 데이터가 없습니다.",
  //               filterRow: {
  //                 filterTooltip: "필터"
  //               },
  //               editRow: {
  //                 deleteText: "정말로 삭제하시겠습니까?"
  //               }
  //             }
  //           }}
  //           editable={matches ? null : superAdminEditable}
  //         />

  //          {canMore && <LoaderWapeer>{loadMore && <CircularProgress />}</LoaderWapeer>}
          
  //       </TableWrapper>
  //     </Wrapper>
  //   );
  // } 
  // else if (!loading && data && data.seeUsers) {
    
  const classes = useStyles();
  
    useEffect(() => {
      if (!loading && data && data.seeUsers) {
        //setCanMore(data.seeUsers.length >= 15);
        setUsers({ ...users, data: data.seeUsers });
      }
    }, [loading, data]);

    useEffect(() => {
      console.log("useEffect");
      window.addEventListener('hashchange', handlePopState, false);
  
      return () => {
        window.removeEventListener('hashchange', handlePopState, false);
      };
      
    }, []);
  
    const handlePopState = () => {
      console.log("handlePopState");
      if(!window.location.hash.includes("?term")) {
        searchInput.setValue("");
      }
    }

    //console.log(data.seeUsers);

    const superAdminEditable = {
      onRowAdd: async newData => handleCreate(newData),
      onRowDelete: oldData => handleDelete(oldData)
    };

    return (
      <Wrapper>
        <Helmet>
          <title>회원관리 | WePlay</title>
        </Helmet>
        {loading && <Loader background={false}/> }
        <AdminHeader searchInput={searchInput} />
        <TableWrapper onScroll={e => onScroll(e)}>
          <MaterialTable
            title="회원 테이블"
            columns={users.columns}
            data={users.data}
            style={{
              margin: "0 auto",
              minHeight: "calc(100vh - 234px)",
              position: "unset",
              boxShadow: "none"
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "수정",
                onClick: (event, rowData) => {
                  onUpdatePage(rowData.id);
                }
              }
            ]}
            options={{
              addRowPosition: "first",
              pageSize: 5,
              actionsColumnIndex: -1,
              detailPanelType: "single",
              paging: false,
              search:false,
              exportButton: true
            }}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <FormControl className={classes.radioRroup} component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup aria-label="position" name="position" value={userType.value} onChange={userType.onChange} row>
                      <FormControlLabel
                        value="2000"
                        control={<Radio color="primary" />}
                        label="전체"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="2004"
                        control={<Radio color="primary" />}
                        label="일반회원"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="2003"
                        control={<Radio color="primary" />}
                        label="오픈방 매니저"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="2005"
                        control={<Radio color="primary" />}
                        label="대회회원"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "총 {count}개 중 {from}-{to} "
              },
              toolbar: {
                nRowsSelected: "{0}개 선택",
                searchPlaceholder: "회원검색"
              },
              header: {
                actions: ""
              },

              body: {
                emptyDataSourceMessage: "",
                filterRow: {
                  filterTooltip: "필터"
                },
                editRow: {
                  deleteText: "정말로 삭제하시겠습니까?"
                }
              }
            }}
            editable={matches ? null : superAdminEditable}
          />

           {canMore && <LoaderWapeer>{loadMore && <CircularProgress />}</LoaderWapeer>}
          
        </TableWrapper>
      </Wrapper>
    );
  //}


  // else {
  //   return (
  //     <Wrapper>
  //       <Helmet>
  //         <title>회원관리 | WePlay</title>
  //       </Helmet>
  //       <AdminHeader />
  //       <Loader />
  //     </Wrapper>
  //   );
  // }
}
