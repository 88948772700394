import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_USERS,
  CREATE_ACCOUNT,
  REMOVE_USER,
} from "./UserListQueries";
import UserListPresenter from "./UserListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import useInput from "../../Hooks/useInput";



const desktopColumn = [
  {
    title: "프로필",
    editable: "never",
    field: "avatar",
    sorting: false,
    searchable: false,
    // initialEditValue: {
    //   avatar: "https://ssl.pstatic.net/static/pwe/address/img_profile.png"
    // },
    initialEditValue:
      "./imgs/user_icon_list.png",
    render: rowData => {
      //console.log(rowData);
      return (
        rowData && (
          <img
            src={rowData.avatar ? rowData.avatar : rowData}
            alt="avatar"
            onError={(e)=>{e.target.onerror = null; e.target.src="./imgs/user_icon_list.png"}}
            style={{ width: 50, height:50, borderRadius: "50%" }}
          />
        )
      );
    },
    headerStyle: { fontWeight: "800" }
  },
  { title: "이름", field: "username", headerStyle: { fontWeight: "800" } },
  { title: "이메일", field: "email", headerStyle: { fontWeight: "800" } },
  {
    title: "연락처",
    field: "contact",
    initialEditValue: "",
    sorting: false,
    searchable: false,
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "가입일",
    editable: "never",
    searchable: false,
    initialEditValue: moment().format("YYYY-MM-DD"),
    render: rowData => {
      //console.log(rowData);
      return rowData? moment(rowData.createdAt).format("YYYY-MM-DD") : rowData
    }
  }
];

const mobileColumn = [
  { title: "이름", field: "username" },
  { title: "연락처", field: "contact" }
];



export default withRouter(({ history }) => {
  const [first] = useState(15);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const searchInput = useInput("");
  const userType = useInput("2000");

  const { data, loading, refetch, fetchMore } = useQuery(SEE_USERS, {
    variables: {
      term:searchInput.value,
      code:userType.value,
      skip: skip,
      first: first
    }
  });

  // const { data :dataUsers, loading:loadingUsers } = useQuery(SEARCH_USER, {
  //   skip:searchInput.value ==="",
  //   variables: {
  //     term:searchInput.value
  //   }
  // });

  const [users, setUsers] = useState({
    columns: matches ? mobileColumn : desktopColumn,
    data: []
  });


  const createUserMutation = useMutation(CREATE_ACCOUNT);
  const deleteUserMutation = useMutation(REMOVE_USER);
  const lastCardRef = useRef(null);

  useEffect(() => {
    if (loading)
      setUsers({ columns: matches ? mobileColumn : desktopColumn, ...users });
    else if (!loading && data && data.seeUsers)
      setUsers({
        columns: matches ? mobileColumn : desktopColumn,
        data: data.seeUsers
      });

    // if(searchInput.value !== "") {
    //   if (loadingUsers)
    //     setUsers({ columns: matches ? mobileColumn : desktopColumn, ...users });
    //   else if (!loadingUsers && dataUsers && dataUsers.searchUser)
    //     setUsers({
    //       columns: matches ? mobileColumn : desktopColumn,
    //       data: dataUsers.searchUser
    //     });
    // }

  }, [data, matches, loading]);

  const onScroll = ({currentTarget}) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeUsers.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log(fetchMoreResult);
          console.log(fetchMoreResult.seeUsers);
          setLoadMore(false);
          // if(fetchMoreResult.seeUsers.length === 0)
          //   toast.error("조회할 데이터가 없습니다.");
          // else
          //   setUsers({...users, data:[...users.data, ...fetchMoreResult.seeUsers]});
  
          if(fetchMoreResult.seeUsers.length === 0) setCanMore(false);
          if (!fetchMoreResult) return prev;
  
          return Object.assign({}, prev, {
            seeUsers: [...prev.seeUsers, ...fetchMoreResult.seeUsers]
          });
        }
      });
    }   
  };


  const handleCreate = async newData => {
    if (newData.username && newData.email && newData.contact) {
      try {
        const {
          data: { createAccountByAdmin: newUser }
        } = await createUserMutation({
          variables: {
            avatar: newData.avatar,
            username: newData.username,
            email: newData.email,
            contact: newData.contact
          }
        });

        refetch();

        const data = [...users.data];
        data.pop();
        data.unshift(newUser);
        setUsers({ ...users, data });
       
      } catch (e) {
        console.log(e);
        if(e.indexOf("already") !== -1) {
          toast.error("이메일 또는 연락처가 이미 존재합니다.");
        } else {
          toast.error("올바른 정보를 입력해주세요.");
        }
      }
    } else {
      toast.error("모든 필드를 입력해주세요.");
    }
  };

  const handleDelete = async oldData => {
    await deleteUserMutation({
      variables: { id: oldData.id }
    });

    refetch();

    const data = [...users.data];
    data.splice(data.findIndex(i => i.id === oldData.id), 1);
    setUsers({ ...users, data });
  };

  const onUpdatePage = id => {
    history.push(`/user/${id}`);
  };

  return (
    <UserListPresenter
      loading={loading}
      data={data}
      //loadingUsers={loadingUsers}
      //dataUsers={dataUsers}
      matches={matches}
      loadMore={loadMore}
      onScroll={onScroll}
      handleCreate={handleCreate}
      onUpdatePage={onUpdatePage}
      handleDelete={handleDelete}
      users={users}
      setUsers={setUsers}
      canMore={canMore}
      setCanMore={setCanMore}
      lastCardRef={lastCardRef}
      searchInput={searchInput}

      userType={userType}
    />
  );
});
