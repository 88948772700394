import React, { useEffect, useState } from "react";
import styled from "styled-components";

const getAttr = isInit => {
  if (isInit) {
    return `
      position: unset;
      z-index: -1;
      visibility: unset;
    `;
  } else {
    return `
      position: absolute;
      z-index: -1;
      visibility: hidden;
    `;
  }
};

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  ${props => getAttr(props.isInit)};
  /* visibility: hidden; */
`;

const Map = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 30vh;
`;

const AddressTitle = styled.div`
  padding: 10px;
  background-color: #e7e7e7;
  color: #000;
  text-align:center;
  font-weight:700;
  font-size:14px;
`;

const AddressDestail = styled.div`
  background-color: #fff;
  min-height: 50px;
  color:#000;
  display: flex;
  font-size:14px;
  justify-content: center;
  align-items: center;
`;

export default ({ lat, lng, isInit, placeName, address, showPlaceName=true, showAddress=true }) => {
  const kakao = window.kakao;

  useEffect(() => {
    if (kakao !== undefined && lat !== 0 && lng !== 0) {
      const marker = {
        position: new kakao.maps.LatLng(lat, lng)
      };

      const map = new kakao.maps.StaticMap(document.getElementById("map"), {
        center: new kakao.maps.LatLng(lat, lng),
        level: 3,
        marker: marker
      });

      window.kakao.mymap = map;
    }
  }, [window.kakao, lat, lng]);

  return (
    <Container isInit={isInit}>
      <Map id="map" />
      {showPlaceName && <AddressTitle>{placeName}</AddressTitle>}
      {showAddress && <AddressDestail>{address}</AddressDestail>}
     
    </Container>
  );
};
