import { gql } from "apollo-boost";

export const SEE_CUP_GAMES = gql`
  query seeCupGames($first: Int, $skip: Int, $cupId: String!) {
    seeCupGames(first: $first, skip: $skip, cupId: $cupId) {
      id
      gameName
      winner
      sportsCode {
        id
        code
      }
      homeTeam {
        id
        logo
        teamName
        level
      }
      awayTeam {
        id
        logo
        teamName
        level
      }
      place {
        id
        placeName
        address
      }
      playerCnt
      gameStatus
      gameDate
      startTime
      endTime
    },
    seeCup(id:$cupId) {
      id
      cupName
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccountByAdmin(
    $avatar: String!
    $username: String!
    $email: String!
    $contact: String
  ) {
    createAccountByAdmin(
      avatar: $avatar
      username: $username
      email: $email
      contact: $contact
    ) {
      id
      avatar
      username
      email
      contact
      basket {
        id
      }
    }
  }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id: String!
    $userId: String!
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $level: Int
    $grade: String
  ) {
    upsertBasket(
      id: $id
      userId: $userId
      position: $position
      height: $height
      weight: $weight
      backNo: $backNo
      level: $level
      grade: $grade
    ) {
      id
    }
  }
`;

export const EDIT_TEAM = gql`
  mutation editTeamByAdmin(
    $id: String!
    $teamName: String
    $level: Int
    $contact: String
    $action: String!
  ) {
    editTeamByAdmin(
      id: $id
      teamName: $teamName
      level: $level
      contact: $contact
      action: $action
    ) {
      id
    }
  }
`;

export const DELETE_GAME = gql`
  mutation editGame($id: String!, $action: String!) {
    editGame(id: $id, action: $action) {
      id
    }
  }
`;
