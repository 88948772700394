import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import moment from "moment";
import DatePicker from 'react-mobile-datepicker';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginTop: theme.spacing(2),
    maxWidth:300,
    width:"49%",
    "& > .MuiInputBase-root.Mui-disabled": {
      color:"#000"
    },
    "&:first-child": {

    }
  },
}));

const Header = styled.div`
  font-family: "NanumSqure";
  font-size: 23px;
  padding: 10px 0px;
  font-weight: 600;
  color: #000;
`;

const Container = styled.div`
  display:flex;
  flex-direction: column;
`;

const DateFormatter = styled.div`
  height: 80px;
  font-size: 1.895em;
  & > span {
    font-size:16px;
  }
`;



const TimeWrapper = styled.div`
  /* display:flex;
  justify-content: space-between; */
`;

const Wrapper = styled.div`
display:block;
`;

const timeConfig = {
  'hour': {
      //format: 'hh',
      format:value => <DateFormatter>{moment(value).format("kk")}<span>시</span></DateFormatter>,
      caption: '시',
      step: 1,
  },
  'minute': {
      //format: 'mm',
      format:value => <DateFormatter>{moment(value).format("mm")}<span>분</span></DateFormatter>,
      caption: '분',
      step: 1,
  }
}

export default function TimePicker({
  required = true,
  startTime,
  endTime
}) {
  const classes = useStyles();
  const [stime, setSTime] = useState(new Date(moment().format(`YYYY-MM-DDT${startTime.value}:ss`)));
  const [etime, setETime] = useState(new Date(moment().format(`YYYY-MM-DDT${endTime.value}:ss`)));

  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const handleStartClick = () => {
		setIsStartOpen(true);
	}

	const handleStartCancel = () => {
		setIsStartOpen(false);
  }
  
  const handleEndClick = () => {
		setIsEndOpen(true);
	}

	const handleEndCancel = () => {
		setIsEndOpen(false);
  }
  
  const handleStartSelect = (time) => {
    setIsStartOpen(false);
    setSTime(time);
    startTime.setValue(moment(time).format("kk:mm"));
    if(endTime.value < moment(time).format("kk:mm")) {
      endTime.setValue(moment(time).format("kk:mm"));
      setETime(time);
    }
  }

  const handleEndSelect = (time) => {
    setIsEndOpen(false);
    setETime(time);
    endTime.setValue(moment(time).format("kk:mm"));
    if(startTime.value > moment(time).format("kk:mm")) {
      startTime.setValue(moment(time).format("kk:mm"));
      setSTime(time);
    }
  }

  useEffect(() => {
    isStartOpen || isEndOpen? document.body.style = "overflow-y:hidden":document.body.style ="";

    function handleTouchMove(event) {
      if (isStartOpen || isEndOpen) {
        event.preventDefault(); // 여기가 핵심
      }
    }
    window.addEventListener("touchmove", handleTouchMove, {
      passive: false
    });
    return () =>
      window.removeEventListener("touchmove", handleTouchMove);
    }, [isStartOpen, isEndOpen]);

  return (
    <Container >
      <TimeWrapper>
        <Wrapper>
          <TextField
            id="time"
            type="time"
            value={startTime.value}
            onChange={startTime.onChange}
            required={required}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </Wrapper>
        
        {/* <DatePicker
          dateConfig={timeConfig}
					value={stime}
          isOpen={isStartOpen}
          customHeader={<Header>시간 설정</Header>}
          confirmText={"등록"}
          cancelText={"취소"}
					onSelect={handleStartSelect}
					onCancel={handleStartCancel} /> */}

        <Wrapper>
          <TextField
            id="time"
            type="time"
            value={endTime.value}
            onChange={endTime.onChange}
            required={required}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </Wrapper>
       
{/* 
        <DatePicker
          dateConfig={timeConfig}
					value={etime}
          isOpen={isEndOpen}
          customHeader={<Header>시간 설정</Header>}
          confirmText={"등록"}
          cancelText={"취소"}
					onSelect={handleEndSelect}
					onCancel={handleEndCancel} /> */}
      </TimeWrapper>
      
      
    </Container>
    
  );
}
