import { gql } from "apollo-boost";

export const SEE_NOTICES = gql`
  query seeNotices($cupId: String!) {
    seeNotices(cupId: $cupId) {
      id
      writer {
        id
        username
      }
      title
      text
      createdAt
    }
    seeCup(id:$cupId) {
      id
      cupName
    }
  }
`;


export const DELETE_NOTICE = gql`
  mutation editNotice($id: String!, $action: String!) {
    editNotice(id: $id, action: $action) {
      id
    }
  }
`;


