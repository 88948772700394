import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { GET_GAME, UPSERT_USER_STAT, EDIT_GAME } from "./CupGameQueries";
import CupGamePresenter from "./CupGamePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";



const CupGameContainer = ({ match: { params } }) => {
  const cupId = params.cup;
  const gameId = params.game;

  const { data, loading } = useQuery(GET_GAME, {
    variables: {
      id: gameId.toString()
    }
  });
  const upsertUserStatMutation = useMutation(UPSERT_USER_STAT);
  const completeGameMutation = useMutation(EDIT_GAME);

  const [scoreEditMode, setScoreEditMode] = useState(false);

  const [homeScore, setHomeScore] = useState([0, 0, 0, 0]);
  const [awayScore, setAwayScore] = useState([0, 0, 0, 0]);

  const [homeUsers, setHomeUsers] = useState([]);
  const [awayUsers, setAwayUsers] = useState([]);

  const [gameResultId, setGameResultId] = useState("");

  const [playCount, setPlayCount] = useState();
  const playTime = useInput("00:00");
  const homeTeamFoul = useInput(0);
  const awayTeamFoul = useInput(0);
  const [uploading, setUploading] = useState(false);

  const freeShotInput = useRef();
  const freeShotTotalInput = useRef();

  const updateScore = (e, i, type) => {
    const {
      target: { value }
    } = e;

    console.log(value);
    if (!isNaN(value)) {
      if (type === "home") {
        const _prevScore = [...homeScore];
        _prevScore[i] = value?parseInt(value):value;
        setHomeScore(_prevScore);
      } else {
        const _prevScore = [...awayScore];
        _prevScore[i] = value?parseInt(value):value;
        setAwayScore(_prevScore);
      }
    }
  };

  const handleToggle = () => {
    setScoreEditMode(!scoreEditMode);
  };

  const handleUpdate = async (newData, oldData, type) => {
    try {
      
      let variables;

      if(playCount === 3) {
        variables = {
          cupId,
          userId: newData.id,
          gameId,
          oneScore: parseInt(newData.thisUserStat.oneScore),
          twoScore:parseInt(newData.thisUserStat.twoScore),
          freeShot:parseInt(newData.thisUserStat.freeShot),
          freeShotTotal:parseInt(newData.thisUserStat.freeShotTotal),
          rebound: parseInt(newData.thisUserStat.rebound),
          assist: parseInt(newData.thisUserStat.assist),
          steal: parseInt(newData.thisUserStat.steal),
          foul: parseInt(newData.thisUserStat.foul)
        }
      } else if(playCount === 5){
        variables = {
          cupId,
          userId: newData.id,
          gameId,
          score: parseInt(newData.thisUserStat.score),
          freeShot:parseInt(newData.thisUserStat.freeShot),
          freeShotTotal:parseInt(newData.thisUserStat.freeShotTotal),
          rebound: parseInt(newData.thisUserStat.rebound),
          assist: parseInt(newData.thisUserStat.assist),
          blocking: parseInt(newData.thisUserStat.blocking),
          steal: parseInt(newData.thisUserStat.steal),
          turnOver: parseInt(newData.thisUserStat.turnOver),
          foul: parseInt(newData.thisUserStat.foul)
        }
      }

      const {
        data: { upsertUserStat }
      } = await upsertUserStatMutation({variables});

      console.log(upsertUserStat);
      if(upsertUserStat.id) {
        toast.success("입력되었습니다.");
        if (type === "home") {
          const prevUsers = [...homeUsers];
          prevUsers[
            homeUsers.findIndex(i => i.id === oldData.id)
          ].thisUserStat = upsertUserStat;
          setHomeUsers(prevUsers);
        } else if (type === "away") {
          const prevUsers = [...awayUsers];
          prevUsers[
            awayUsers.findIndex(i => i.id === oldData.id)
          ].thisUserStat = upsertUserStat;
          setAwayUsers(prevUsers);
        }
      }

      
   
    } catch (e) {
      console.log(e);
      toast.error("올바른 정보를 입력해주세요.");
    }
  };

  const handleScoreUpdate = async () => {
    try {
      setUploading(true);
      const {
        data: { editGame }
      } = await completeGameMutation({
        variables: {
          id: gameId,
          gameResultId,
          homeId: data.seeFullGame.homeTeam.id,
          awayId: data.seeFullGame.awayTeam.id,
          action: "COMPLETE",
          homeScore,
          awayScore,
          homeTeamFoul:parseInt(homeTeamFoul.value),
          awayTeamFoul:parseInt(awayTeamFoul.value),
          playTime:playTime.value
        }
      });

      setGameResultId(editGame.result.id);
      if(editGame.result.id) {
        toast.success("입력되었습니다.");
      }
    } catch (e) {
      console.log(e);
      toast.error("서버 오류로 결과 저장이 실패하였습니다.");
    } finally {
      setUploading(false);
      handleToggle();
    }
  };

  return (
    <CupGamePresenter
      loading={loading}
      data={data}
      uploading={uploading}
      cupId={cupId}
      gameId={gameId}
      homeUsers={homeUsers}
      setHomeUsers={setHomeUsers}
      awayUsers={awayUsers}
      setAwayUsers={setAwayUsers}
      scoreEditMode={scoreEditMode}
      handleToggle={handleToggle}
      homeScore={homeScore}
      setHomeScore={setHomeScore}
      awayScore={awayScore}
      setAwayScore={setAwayScore}
      handleUpdate={handleUpdate}
      handleScoreUpdate={handleScoreUpdate}
      updateScore={updateScore}
      setGameResultId={setGameResultId}
      playTime={playTime}
      homeTeamFoul={homeTeamFoul}
      awayTeamFoul={awayTeamFoul}
      setPlayCount={setPlayCount}
      freeShotInput={freeShotInput}
      freeShotTotalInput={freeShotTotalInput}
    />
  );
};

export default withRouter(CupGameContainer);
