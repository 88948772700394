import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import useInput from "../Hooks/useInput";
import ImagesUploader from "react-images-upload";
import { Button, TextField } from "@material-ui/core";
import BoldText from "./BoldText";

const Container = styled.form`
  margin-left: 0;
  padding: 10px;
  display: inline-block;
  width: 50%;
`;

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const Row = styled.div``;

const Title = styled.div`
  margin-top: 10px;
  margin-bottom: 7px;
  font-weight: 500;
`;

const Input = styled.div`
  & .MuiFormControl-root {
    display: flex !important;
  }
  & .MuiInput-root {
    border-bottom: 1px solid #000;
    width: 100%;
  }
  & .MuiInputBase-input {
    padding: 3px 0px !important;
    /* min-width: 195px; */
    text-overflow: ellipsis;
  }
`;

const BannerList = styled.li`

  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const BannerCard = styled.div`
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
  }
`;

const LabelHint = styled.span`
  font-size: 13px;
  font-weight: 500;
  padding-left: 7px;
  margin-right: 10px;
`;

const BannerForm = ({
  banners,
  index,
  setBanners,
  handleRemovePopupOpen,
  onDropFiles,
}) => {
  const linkInput = useInput("");

  const onChange = (e) => {
    if(e.target.name === 'link') {
      const data = [...banners];
      data[index] = {
        ...data[index],
        [e.target.name]: e.target.value,
      };
      setBanners(data);
    }
  };

  //console.log(index,  banners[index].file);

  return (
    <Container onChange={onChange}>
      <BannerList>
        <BannerCard>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRemovePopupOpen(index)}
          >
            삭제
          </Button>
          <DropZoneWrapper>
            <ImagesUploader
              defaultImages={banners[index].url?[banners[index].url]:undefined}
              withPreview={true}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              onChange={(picture) =>
                onDropFiles(index, picture)
              }
              label="이미지를 업로드하세요."
              buttonText="사진 선택"
              singleImage={true}
              maxFileSize={524288000}
            />
          </DropZoneWrapper>

          <Row>
            <Title>연결 URL</Title>
            <Input>
              <TextField
                name={"link"}
                value={banners[index].link}
                onChange={linkInput.onChange}
              />
            </Input>
          </Row>

          {/* <InputInfoColumn>
            <BoldText text={"연결 url"} />
            <LabelHint>*</LabelHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <TextField
              value={banners[index].link.value}
              onChange={linkInput.onChange}
            />
          </InputInfoColumn> */}
        </BannerCard>
      </BannerList>
    </Container>
  );
};

// BannerForm.propTypes = {
//   placeholder: Proptypes.string,
//   required: Proptypes.bool,
//   value: Proptypes.string,
//   onChange: Proptypes.func,
//   type: Proptypes.string,
//   onKeyDown: Proptypes.func,
// };

export default BannerForm;
