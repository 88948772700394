import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const getSize = size => {
  let number;
  if (size === "sm") {
    number = 38;
  } else if (size === "md") {
    number = 54;
  } else if (size === "lmd") {
    number = 85;
  } else if (size === "lg") {
    number = 110;
  } else if (size === "xlg") {
    number = 130;
  }

  return `
    width:${number}px;
    height:${number}px;
  `;
};

const Container = styled.div`
  ${props => getSize(props.size)};
  background-image: url(${props => props.url});
  background-size: cover;
  background-position:center;
  border-radius: 50%;
  flex-basis: ${props=>props.size};
  flex-shrink: 0;
  ${props=> props.border && "border:1px solid #000"};
`;

function Avatar({ size = "sm", url, className, border=false }) {
  return <Container className={className} size={size} url={url} border={border} />;
}

Avatar.propTypes = {
  size: PropTypes.oneOf(["sm","md","lmd","lg","xlg"]),
  url: PropTypes.string.isRequired
};

export default Avatar;
