import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_CUP_GAMES,
  DELETE_GAME
} from "./CupScheduleListQueries";
import CupTeamListPresenter from "./CupScheduleListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




export default withRouter(({ match: { params }, history }) => {
  const cupId = params.cup;
  
  const [first] = useState(10);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { data, loading, fetchMore, refetch } = useQuery(SEE_CUP_GAMES, {
    variables: {
      first: first,
      skip: skip,
      cupId
    }
  });

  const [games, setGames] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGame, setSelectedGame] = useState();
  const deleteGameMutation = useMutation(DELETE_GAME);

  const handleDialogOpen = (id) => () => {
    setOpenDialog(true);
    setSelectedGame(id);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedGame(null);
  };

  const onChangePage = () => {
    setLoadMore(true);
    fetchMore({
      variables: {
        skip: data.seeCupGames.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadMore(false);
        if(fetchMoreResult.seeCupGames.length === 0)
          toast.error("조회할 데이터가 없습니다.");
        else
        setGames({...games, data:[...games.data, ...fetchMoreResult.seeCupGames]});

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          seeCupGames: [...prev.seeCupGames, ...fetchMoreResult.seeCupGames]
        });
      }
    });
  };


  const handleDelete = async () => {
    try {
      if(selectedGame) {
        const data = games;
        for(var game in data) {
          data[game].splice(data[game].findIndex(i => i.id === selectedGame), 1);
        }
        setGames( data );

        await deleteGameMutation({
          variables: { id: selectedGame, action:"CANCEL" }
        });
      }
    } catch(e) {
      console.log(e);
    } finally {
      handleDialogClose();
      refetch();
    }
  };

  const onCreatePage = () => {
    history.push(`/cup/${cupId}/game/create`);
  };

  const onUpdatePage = id => {
    history.push(`/game/${id}`);
  };

  return (
    <CupTeamListPresenter
      cupId={cupId}
      loading={loading}
      data={data}
      loadMore={loadMore}
      onCreatePage={onCreatePage}
      onChangePage={onChangePage}
      handleDelete={handleDelete}
      openDialog={openDialog}
      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}
      games={games}
      setGames={setGames}
      matches={matches}
      onUpdatePage={onUpdatePage}
    />
  );
});

