import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
//import Button from "../../Components/AdminButton";

import MaterialTable, { MTableToolbar } from "material-table";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  overflow-x: scroll; */
  min-height: calc(100vh - 76px);
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    minHeight: "100px"
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  container: {},
  paper: {
    height: 100,
    width: 100
  }
}));

const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
  padding: 0px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
  /* padding-bottom:100px; */
`;

const LoaderWapeer = styled.div`
  width: 100%;
  min-height: 170px;
  background-color: #fff;
  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 30px;
  }
`;

export default function MaterialTableDemo({
  loading,
  data,
  handleDelete,
  teams,
  setTeams,
  onCreatePage,
  onUpdatePage,
  loadMore,
  matches,
  onScroll,
  canMore,
  setCanMore,
  searchInput
}) {
    const classes = useStyles();
    useEffect(() => {
      if (!loading && data && data.seeTeams) {
        setCanMore(data.seeTeams.length >= 15);
        setTeams({ ...teams, data:data.seeTeams });
      }
    }, [])

    const superAdminEditable = {
      onRowDelete: oldData =>
        new Promise(resolve => {
          setTimeout(() => {
            console.log(oldData);
            resolve();
            handleDelete(oldData);
            const data = [...teams.data];
            data.splice(data.findIndex(i => i.id === oldData.id), 1);
            setTeams({ ...teams, data });
          }, 600);
        })
    };

    return (
      <Wrapper>
        <Helmet>
          <title>팀관리 | WePlay</title>
        </Helmet>
        {loading && <Loader background={false}/> }
        <AdminHeader searchInput={searchInput} />
        <TableWrapper onScroll={e => onScroll(e)}>
        <MaterialTable
          title="팀 관리"
          style={{
            margin: "0 auto",
            minHeight: "calc(100vh - 234px)",
            position: "unset",
            boxShadow: "none"
          }}
          columns={teams.columns}
          data={teams.data}
          options={{
            search: false,
            paging: false,
            draggable:false,
            actionsColumnIndex: -1,
            detailPanelType: "single"
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: '수정',
              onClick: (event, rowData) => {
                console.log(rowData);
                onUpdatePage(rowData.id)
              }
            }
          ]}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <Button
                  variant="contained"
                  color="default"
                  onClick={onCreatePage}
                  className={classes.button}
                >
                  팀 생성
                  <AddIcon className={classes.rightIcon} />
                </Button>
              </div>
            )
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "총 {count}개 중 {from}-{to} "
            },
            toolbar: {
              nRowsSelected: "{0}개 선택",
              searchPlaceholder: "팀검색"
            },
            header: {
              actions: ""
            },
            body: {
              emptyDataSourceMessage: "",
              filterRow: {
                filterTooltip: "필터"
              },
              editRow: {
                deleteText: "정말로 삭제하시겠습니까?"
              }
            }
          }}
          editable={matches ? null : superAdminEditable}
        />
         <LoaderWapeer>{canMore && loadMore && <CircularProgress />}</LoaderWapeer>
      
        </TableWrapper>
      </Wrapper>
    );
}
