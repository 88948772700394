import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_PLACE,
  EDIT_PLACE,
  DELETE_PICTURE,
  EDIT_BASKET_PLACE
} from "./EditPlaceQueries";
import TeamProfilePresenter from "./EditPlacePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";

const TeamProfileContainer = ({ match: { params } }) => {
  const placeId = params.place;
  const { data, loading, refetch } = useQuery(GET_PLACE, {
    variables: {
      id: placeId.toString()
    }
  });

  const [pictures, setPictures] = useState([]);
  const [oldPictures, setOldPictures] = useState([]);

  const placeNameInput = useInput("");
  const contactInput = useInput("", (newValue) => !isNaN(newValue) );
  const managerInput = useInput("");
  const isFreeInput = useInput("false");
  const isAuthenticatedInput = useInput("false");


  const [addressS, setAddress] = useState("");

  const [uploading, setUploading] = useState(false);
  const [openRemovePicture, setOpenRemovePicture] = useState(false);
  const [removePicture, setRemovePicture] = useState();

  const [editPicture, setEditPicture] = useState(false);
  //console.log(data);
  
  const editPlacemutation = useMutation(EDIT_PLACE);
  const editBasketPlacemutation = useMutation(EDIT_BASKET_PLACE);
  const deletePictureMutation = useMutation(DELETE_PICTURE);
  

  const sizeInput = useInput("");
  const floorMaterialInput = useInput("");
  const isGroundLine = useInput("false");
  const isGroundLight = useInput("false");
  const isNet = useInput("false");
  const isShowerRoom = useInput("false");
  const isParkingLot = useInput("false");

  // 경기장 사진 드롭 시 이벤트
  const onDropPictures = acceptedFiles => {
    const data = pictures;
    data.push(acceptedFiles);
    setPictures(data);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemovePictures = removeFile => {
    const data = pictures;
    data.splice(data.findIndex(i => i.upload.uuid === removeFile.upload.uuid), 1);
    setPictures( data );
  }

  // 기존 사진 삭제 이벤트
  const handleOldPictureDelete = async () => {
    await deletePictureMutation({
      variables: { id: removePicture }
    });
    
    const data = oldPictures;
    data.splice(data.findIndex(i => i.id === removePicture), 1);
    setOldPictures(data);
    handleRemovePictureClose();
  }

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemovePictureOpen = (id) => () => {
    setOpenRemovePicture(true);
    setRemovePicture(id);
  };

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemovePictureClose = () => {
    setOpenRemovePicture(false);
    setRemovePicture(null);
  };


  const handleEditPhotoOpen = () => {
    setEditPicture(!editPicture);
  };

  const onReadyMenu = () => {
    alert("준비 중입니다.");
  };

  
  const _ConvertBoolean = value => (value === "true");

  //console.log(data);

  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes
    setUploading(true);
   
    let PictureUrls;
   

    if(pictures && pictures.length > 0) {
      const formData = new FormData();
      pictures.forEach(file => {
        formData.append("place", file);
      }); 

      const { data } = await axios.post("https://weplay2921.cafe24.com/api/place/picture/upload", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      });

      PictureUrls = data;     
    }
   

    console.log({
      id: placeId,
      placeName: placeNameInput.value,
      manager:managerInput.value,
      contact: contactInput.value,
      picture : PictureUrls,
      isFree : _ConvertBoolean(isFreeInput.value),
      isAuthenticated : _ConvertBoolean(isAuthenticatedInput.value),
      size : sizeInput.value,
      floorMaterial: floorMaterialInput.value,
      net:_ConvertBoolean(isNet.value),
      groundLine : _ConvertBoolean(isGroundLine.value),
      groundLight: _ConvertBoolean(isGroundLight.value),
      showerRoom : _ConvertBoolean(isShowerRoom.value),
      parkingLot: _ConvertBoolean(isParkingLot.value)
    });

    try {
      const { data : { editPlaceByAdmin : place }} = await editPlacemutation({
        variables: {
          id: placeId,
          placeName: placeNameInput.value,
          manager:managerInput.value,
          contact: contactInput.value,
          pictures : PictureUrls,
          isFree : _ConvertBoolean(isFreeInput.value),
          isAuthenticated : _ConvertBoolean(isAuthenticatedInput.value),
        }
      });

      console.log(place);

      if(place.id) {
  
        const { data : { editBasketPlace : basketPlace }} = await editBasketPlacemutation({
          variables: {
            placeId: placeId,
            size : sizeInput.value,
            floorMaterial: floorMaterialInput.value,
            net:_ConvertBoolean(isNet.value),
            groundLine : _ConvertBoolean(isGroundLine.value),
            groundLight: _ConvertBoolean(isGroundLight.value),
            showerRoom : _ConvertBoolean(isShowerRoom.value),
            parkingLot: _ConvertBoolean(isParkingLot.value)
          }
        });

        console.log(basketPlace);
        
        if(basketPlace.id) {
          setOldPictures(place.picture);
          setEditPicture(false);
          refetch();
          toast.success("수정 완료!");
        }
      }
    }
    catch(e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    }
    finally {
      setUploading(false);
    }
   
  }
  
  
  return (
    <TeamProfilePresenter
      loading={loading}
      data={data}
      uploading={uploading}
      oldPictures={oldPictures}
      setOldPictures={setOldPictures}
      handleOldPictureDelete={handleOldPictureDelete}
      handleRemovePictureOpen={handleRemovePictureOpen}
      handleRemovePictureClose={handleRemovePictureClose}

      editPicture={editPicture}
      handleEditPhotoOpen={handleEditPhotoOpen}
     
      onDropPictures={onDropPictures}
      onRemovePictures={onRemovePictures}
      handleSubmit={handleSubmit}

      placeNameInput={placeNameInput}
      contactInput={contactInput}
      managerInput={managerInput}

      addressS={addressS}
      setAddress={setAddress}

      openRemovePicture={openRemovePicture}

      isFreeInput={isFreeInput}
      isAuthenticatedInput={isAuthenticatedInput}
      onReadyMenu={onReadyMenu}

      sizeInput={sizeInput}
      floorMaterialInput={floorMaterialInput}
      isGroundLine={isGroundLine}
      isGroundLight={isGroundLight}
      isNet={isNet}
      isShowerRoom={isShowerRoom}
      isParkingLot={isParkingLot}
    />
  );
};

export default withRouter(TeamProfileContainer);
