import { gql } from "apollo-boost";

export const SEE_TEAMS = gql`
  query seeCupTeams($first: Int, $skip: Int, $cupId: String!) {
    seeCupTeams(first: $first, skip: $skip, cupId: $cupId) {
      id
      agent
      teamName
      win
      draw 
      lose
      host {
        id
        username
      }
      managers {
        id
        username
      }
      users {
        id
        username
      }
      place {
        id
        placeName
        address
      }
      level
      sport {
        id
        code
        description
      }
      contact
    },
    seeCup(id:$cupId) {
      id
      cupName
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccountByAdmin(
    $avatar: String!
    $username: String!
    $email: String!
    $contact: String
  ) {
    createAccountByAdmin(
      avatar: $avatar
      username: $username
      email: $email
      contact: $contact
    ) {
      id
      avatar
      username
      email
      contact
      basket {
        id
      }
    }
  }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id: String!
    $userId: String!
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $level: Int
    $grade: String
  ) {
    upsertBasket(
      id: $id
      userId: $userId
      position: $position
      height: $height
      weight: $weight
      backNo: $backNo
      level: $level
      grade: $grade
    ) {
      id
    }
  }
`;

export const EDIT_TEAM = gql`
  mutation editTeamByAdmin(
    $id: String!
    $teamName: String
    $level: Int
    $contact: String
    $action: String!
  ) {
    editTeamByAdmin(
      id: $id
      teamName: $teamName
      level: $level
      contact: $contact
      action: $action
    ) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation editTeamByAdmin($id: String!, $action: String!) {
    editTeamByAdmin(id: $id, action: $action) {
      id
    }
  }
`;
