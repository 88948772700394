import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function SwipeableTemporaryDrawer({ left, toggleDrawer }) {
  const classes = useStyles();

  const onReadyMenu = () => {
    alert("준비 중입니다.");
  };

  return (
    <div>
      <SwipeableDrawer
        open={left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <Link to={"/users"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"회원 관리"} />
              </ListItem>
            </List>
          </Link>

          <Link to={"/teams"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"팀 관리"} />
              </ListItem>
            </List>
          </Link>

          <Link to={"/games"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"경기 관리"} />
              </ListItem>
            </List>
          </Link>

          <Link to={"/places"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"경기장 관리"} />
              </ListItem>
            </List>
          </Link>

          <Divider />
          <Link to={"/cups"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"대회 관리"} />
              </ListItem>
            </List>
          </Link>

          <List>
            <ListItem button onClick={onReadyMenu}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"오픈방 관리"} />
            </ListItem>
          </List>

          <Divider />



          <Link to={"/notification"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"푸시 보내기"} />
              </ListItem>
            </List>
          </Link>

          <Link to={"/banners"}>
            <List>
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"배너 관리"} />
              </ListItem>
            </List>
          </Link>

          <List>
            <ListItem button onClick={onReadyMenu}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"포인트/결제 관리"} />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
