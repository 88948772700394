import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { EDIT_GAME_INFO, SEE_TEAMS } from "./CupEditGameQueries";
import CupEditGamePresenter from "./CupEditGamePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import moment from "moment";
import { SEE_CUP_GAMES } from "../CupScheduleList/CupScheduleListQueries";

const CupEditGameContainer = ({ match: { params } , history }) => {
  const cupId = params.cup;
  const gameId = params.game;
  const [first] = useState(20);
  const [skip] = useState(0);
  const { data, loading, refetch } = useQuery(SEE_TEAMS, {
    variables: {
      first: first,
      skip: skip,
      cupId,
      id:gameId
    }
  });

  const [selectedTeam, setSelectedTeam] = useState();
  const [teams, setTeams] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [awayTeam, setAwayTeam] = useState();

  const gameName = useInput("");
  const gameDate = useInput(moment().format("YYYY-MM-DD"));
  const startTime = useInput("06:30");
  const endTime = useInput("07:30");
  const playerCntInput = useInput();

  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();
  const placeNameInput = useInput("");
  const [cupPlaceId, setCupPlaceId] = useState();

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    placeNameInput.setValue(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };


  const [DialogType, setDialogType] = useState();

  const [uploading, setUploading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const editGameMutation = useMutation(EDIT_GAME_INFO, {
    refetchQueries:[{query:SEE_CUP_GAMES, variables:{cupId}}]
  });


  const handleTeamAdd = () => {
    if(DialogType==='home') {
      if(awayTeam && selectedTeam.id === awayTeam.id) {
        toast.error("같은 팀을 선택할 수 없습니다");
      }
      else {
        setHomeTeam(selectedTeam);
        setSelectedTeam(null);
        handleDialogClose();
      }
        
    }
    else {
      if(homeTeam && selectedTeam.id === homeTeam.id) {
        toast.error("같은 팀을 선택할 수 없습니다");
      }
      else {
        setAwayTeam(selectedTeam);
        setSelectedTeam(null);
        handleDialogClose();
      }
    }
  };

  const handleTeamRemove = type => () => {
    if(type === 'home')
      setHomeTeam(null);
    else if(type === 'away')
      setAwayTeam(null);
  }

 

  // 팀 검색창 오픈 이벤트
  const handleDialogOpen = (type)=> () => {
    setDialogType(type);
    setOpenDialog(true);
  };

  // 팀 검색창 오픈 이벤트
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes

    if(uploading)
      return false;
 
    try {

      if(gameName === "") {
        toast.error("경기 이름을 입력해주세요.");
      }
      else {
        setUploading(true);

        const {
          data: { editGameInfo }
        } = await editGameMutation({
          variables: {
            id:gameId,
            gameName:gameName.value,
            homeId:homeTeam?homeTeam.id:null,
            awayId:awayTeam?awayTeam.id:null,
            gameDate:moment(new Date(gameDate.value+" "+startTime.value)).format("YYYY-MM-DDTHH:mm"),
            startTime:startTime.value,
            endTime:endTime.value,
            placeId:cupPlaceId,
            playerCnt:playerCntInput.value,
            placeName:placeNameInput.value,
            lat:latS,
            lng:lngS,
            address:addressS,
            shortAddress:shortAddressS
          }
        });

        if (editGameInfo.id) {
          toast.success("수정 완료!");
          refetch();
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <CupEditGamePresenter
      data={data}
      loading={loading}
      cupId={cupId}
      uploading={uploading}
      handleSubmit={handleSubmit}
      selectedTeam={selectedTeam}
      setSelectedTeam={setSelectedTeam}
      teams={teams}
      setTeams={setTeams}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      setHomeTeam={setHomeTeam}
      setAwayTeam={setAwayTeam}
      gameName={gameName}
      gameDate={gameDate}
      startTime={startTime}
      endTime={endTime}
      playerCntInput={playerCntInput}

      cupPlaceId={cupPlaceId}
      setCupPlaceId={setCupPlaceId}
      placeNameInput={placeNameInput}
      setAddressInfo={setAddressInfo}
      setAddress={setAddress}
      addressS={addressS}

      openDialog={openDialog}
      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}

      handleTeamAdd={handleTeamAdd}
      handleTeamRemove={handleTeamRemove}
    />
  );
};

export default withRouter(CupEditGameContainer);
