import React from 'react';
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from './Avatar';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

const BlueText = styled.span`
  color:blue;
`;

const RedText = styled.span`
  color:red;
`;


export default function VoteList({ url, primary, secondary, isButton=true, answer  }) {
  const classes = useStyles();

  return (
    <ListItem button={isButton}>
      <ListItemAvatar>
        <Avatar size={"sm"} url={url} />
      </ListItemAvatar>
      <ListItemText
        primary={primary}
      />
      <ListItemSecondaryAction>
        {answer === "AGREE"? <BlueText>참가</BlueText> :  <RedText>불참</RedText>}
      </ListItemSecondaryAction>
    </ListItem>
  );
}