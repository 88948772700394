import React, { useState } from "react";
import AuthPresenter from "./AuthPresenter";
import {
  CREATE_ADMIN,
  LOG_IN,
  LOCAL_LOG_IN
} from "./AuthQueries";
import { useMutation } from "react-apollo-hooks";
import useInput from "../../Hooks/useInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default () => {
  const [action, setAction] = useState("logIn");

  const username = useInput("");
  const authority = useInput("2001");
  const loginID = useInput("");
  const loginPW = useInput("");

  const createAdminMutation = useMutation(CREATE_ADMIN, {
    variables: {
      authority: authority.value,
      username: username.value,
      loginID: loginID.value,
      loginPW: loginPW.value
    }
  });

  const loginMutation = useMutation(LOG_IN, {
    variables: {
      loginID: loginID.value,
      loginPW: loginPW.value
    }
  });

  const localLogInMutation = useMutation(LOCAL_LOG_IN);

  const onSubmit = async (e)=> {
    e.preventDefault();
    if (action === "logIn") {
      if (loginID.value !== "" || loginPW.value !== "") {
        try {
          const {
            data : { logIn : token } 
          } = await loginMutation();
          
          if (token !== "" && token !== undefined) {
            console.log(token);
            localLogInMutation({ 
                variables: { token } 
            });
          } else {
            toast.error("비밀번호가 틀립니다.");
          }

          // if (login) {
          //   toast.success("로그인되었습니다.", {
          //     position: toast.POSITION.TOP_RIGHT,
          //     className: "foo-bar"
          //   });
          //   setTimeout(() => setAction("confirm"), 100);
          // } else {
          //   toast.error("등록된 계정이 없습니다.");
          //   //setTimeout(() => setAction("signUp"), 3000);
          // }
        } catch {
          toast.error("등록된 계정이 없습니다.");
        }
      } else {
        toast.error("아이디, 비밀번호를 입력해주세요.");
      }
    } else if (action === "signUp") {
      if (
        loginID.value !== "" &&
        username.value !== "" &&
        loginPW.value !== "" 
      ) {
        try {
          const {
            data: { createAdmin }
          } = await createAdminMutation();
          console.log(createAdmin);
          if (createAdmin) {
            toast.success("가입되었습니다. 로그인해주세요.", {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar"
            });
            setTimeout(() => setAction("logIn"), 1000);
          } else {
            toast.error("가입에 실패하였습니다. 다시 시도해주세요.");
          }
        } catch (e) {
          if (e.message === "GraphQL error: This ID is already taken.")
            toast.error("등록된 아이디가 있습니다.");
          else toast.error("가입에 실패하였습니다. 다시 시도해주세요.");
        }
      } else {
        toast.error("모든 필드를 입력해주세요.");
      }
    } 
  };

  return (
    <AuthPresenter
      setAction={setAction}
      action={action}
      username={username}
      loginID={loginID}
      loginPW={loginPW}
      onSubmit={onSubmit}
    />
  );
};
