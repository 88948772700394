import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CREATE_CUP, SEARCH_PLACE, SEARCH_USER } from "./CreateCupQueries";
import CreateCupPresenter from "./CreateCupPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";
import moment from "moment";
import { SEE_CUPS } from "../CupList/CupListQueries";

const CreateCupContainer = ({ match: { params } , history }) => {
  const cupId = params.cup;

  const [host, setHost] = useState([]);
  const [organizer, setOrganizer] = useState([]);
  const [support, setSupport] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [group, setGroup] = useState([]);

  const cupName = useInput("");
  const url = useInput("");
  const youtubeUrl = useInput("");
  const target = useInput("");
  const firstPrize = useInput("");
  const secondPrize = useInput("");
  const thirdPrize = useInput("");
  const mvpPrize = useInput("");
  const mannerPrize = useInput("");
  const joinPriceUnit = useInput("인당 ");
  const joinPrice = useInput("", (newValue) => !isNaN(newValue), true);
  const contact = useInput("");
  const cupProcess = useInput("");
  const DepositAccount = useInput("");
  const groupInput = useInput("");


  const [place,setPlace] = useState();

  const playerCnt = useInput(3);

  const cupStartDate = useInput(moment().format("YYYY-MM-DDThh:mm"));
  const cupEndDate = useInput(moment().format("YYYY-MM-DDThh:mm"));
  const cupDateInfo = useInput("");

  const [editLogo, setEditLogo] = useState(false);
  const [uploading, setUploading] = useState(false);

  const createCupMutation = useMutation(CREATE_CUP, {
    refetchQueries:[{query:SEE_CUPS}]
  });

  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();
  const placeNameInput = useInput("");

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setPlace(null);
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    placeNameInput.setValue(placeName);
    setAddress(address);
    setShortAddress(shortAddress);

    console.log({
      placeName, address, shortAddress, lat, lng
    });
  };


  // 경기장 검색 관련
  const searchPlace = useInput("");
  const [searchingPlace, setSearchingPlace] = useState(false);
  const [openPlaceDialog, setOpenPlaceDialog] = useState(false);

  const { loading: loadingPlace, data: searchPlaceData } = useQuery(
    SEARCH_PLACE,
    {
      skip: searchPlace.value === "" && searchPlace.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchPlace.value
      }
    }
  );

  useEffect(() => {
    //setEditorState(EditorState.createEmpty());
    if(localStorage.getItem("addCup")) {

      const {
        LS_cupName,
        LS_target,
        LS_cupProcess,
        LS_playerCnt,
        LS_cupStartDate,
        LS_cupEndDate,
        LS_placeId,
        LS_lat,
        LS_lng,
        LS_placeName,
        LS_address,
        LS_shortAddress,
        LS_youtubeUrl,
        LS_joinPrice,
        LS_host,
        LS_organizer,
        LS_support,
        LS_sponsor,
        LS_contact,
        LS_DepositAccount,
      } = JSON.parse(localStorage.getItem("addCup"));

      cupName.setValue(LS_cupName);
      target.setValue(LS_target);
      cupProcess.setValue(LS_cupProcess);
      playerCnt.setValue(LS_playerCnt);
      cupStartDate.setValue(LS_cupStartDate);
      cupEndDate.setValue(LS_cupEndDate);
      setPlace(LS_placeId);
      setLat(LS_lat);
      setLng(LS_lng);
      placeNameInput.setValue(LS_placeName);
      setAddress(LS_address);
      setShortAddress(LS_shortAddress);

      youtubeUrl.setValue(LS_youtubeUrl);

      if(LS_joinPrice) {
        if(LS_joinPrice.split.length > 1) {
          joinPriceUnit.setValue (LS_joinPrice.split(" ")[0]+" ");
          joinPrice.setValue(LS_joinPrice.split(" ")[1]);
        } else if(LS_joinPrice.split.length === 1) {
          joinPrice.setValue(LS_joinPrice.split(" ")[0]);
        }   
      }
      setHost(LS_host);
      setOrganizer(LS_organizer);
      setSupport(LS_support);
      setSponsor(LS_sponsor);
      contact.setValue(LS_contact);
      DepositAccount.setValue(LS_DepositAccount);

      localStorage.removeItem('addCup');
    }
  }, []);

  // 경기장 변경 시 변경값 설정
  const onChangePlace = place => () => {
    setPlace(place.id);
    setAddress(place.address);
    placeNameInput.setValue(place.placeName);
    handlePlaceDialogClose();
  };

   // 경기장 검색창 오픈 이벤트
   const handlePlaceDialogOpen = () => {
    setOpenPlaceDialog(true);
  };

  // 경기장 검색창 닫기 이벤트
  const handlePlaceDialogClose = () => {
    setOpenPlaceDialog(false);
  };
  
   // 경기장 검색 값 변할 경우 리렌더링
   useEffect(() => {
    if (searchPlace.value.length > 1) {
      setSearchingPlace(true);
    } else {
      setSearchingPlace(false);
    }

    if (!loadingPlace && searchPlace) {
      setSearchingPlace(false);
    }

  }, [
    searchPlace.value,
    loadingPlace,
  ]);


  const handleEditPhotoOpen = type => () => {
    if (type === "logo") setEditLogo(!editLogo);
  };

  const onDropPicture = (type, acceptedFiles) => {
    if(type === 'host') {
      const data = host;
      data.push(acceptedFiles);
      setHost(data);
    } else if(type === 'organizer') {
      const data = organizer;
      data.push(acceptedFiles);
      setOrganizer(data);
    } else if(type === 'support') {
      const data = support;
      data.push(acceptedFiles);
      setSupport(data);
    } else if(type === 'sponsor') {
      const data = sponsor;
      data.push(acceptedFiles);
      setSponsor(data);
    }
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemovePicture = (type,removeFile) => {

    if(type === 'host') {
      const data = host;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setHost(data);
    } else if(type === 'organizer') {
      const data = organizer;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setOrganizer(data);
    } else if(type === 'support') {
      const data = support;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setSupport(data);
    } else if(type === 'sponsor') {
      const data = sponsor;
      data.splice(
        data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
        1
      );
      setSponsor(data);
    }

  
  };

  // 전체 업로드
  const handleSubmit = async () => {

    if(uploading)
      return false;
 
    try {
      if (cupName.value === "") {
        toast.error("대회 이름을 추가해주세요");
      } 
      else if (target.value === "") {
        toast.error("참가 대상을 입력해주세요");
      } 
      else if (cupProcess.value === "") {
        toast.error("리그 진행 방식을 입력해주세요");
      } 
      else if (addressS === "") {
        toast.error("대회 주소를 입력해주세요");
      }
      else if (cupStartDate.value >= cupEndDate.value) {
        toast.error("대회 날짜 정보를 정확히 입력해주세요");
      }
      else {
        setUploading(true);

        let hostUrl;
        let organizerUrl;
        let supportUrl;
        let sponsorUrl;

        console.log({
          cupName:cupName.value,
          target:target.value,
          cupProcess:cupProcess.value,
          playerCnt:playerCnt.value,
          cupStartDate:cupStartDate.value,
          cupEndDate:cupEndDate.value,
          placeId:place,
          lat:latS,
          lng:lngS,
          placeName:placeNameInput.value,
          address:addressS,
          shortAddress:shortAddressS,

          youtubeUrl:youtubeUrl.value,
          joinPrice:joinPriceUnit.value + joinPrice.value,
          host:host,
          organizer:organizer,
          support:support,
          sponsor:sponsor,
          
          contact:contact.value,
          DepositAccount:DepositAccount.value,
        });

        

        const serverURL = process.env.NODE_ENV === "development"
        ? "http://localhost:5000/"
        : "https://weplay2921.cafe24.com/"

        if (host && host.length > 0) {
          const formData = new FormData();

          host.forEach(file => {
            formData.append("cup", file);
          });

          const {
            data
          } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          hostUrl = data;
        } 

  
        if (organizer && organizer.length > 0) {
          const formData = new FormData();

          organizer.forEach(file => {
            formData.append("cup", file);
          });

          const {
            data
          } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          organizerUrl = data;
        } 

        
        if (support && support.length > 0) {
          const formData = new FormData();

          support.forEach(file => {
            formData.append("cup", file);
          });
          const {
            data
          } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          supportUrl = data;
        } 


        if (sponsor && sponsor.length > 0) {
          const formData = new FormData();
          formData.append("cup", sponsor);

          sponsor.forEach(file => {
            formData.append("cup", file);
          });

          const { data } = await axios.post(
            `${serverURL}api/cup/upload`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          sponsorUrl = data;
        } 

        const {
          data: { createCup }
        } = await createCupMutation({
          variables: { 
            cupName:cupName.value,
            target:target.value,
            cupProcess:cupProcess.value,
            playerCnt:playerCnt.value,
            cupStartDate:cupStartDate.value,
            cupEndDate:cupEndDate.value,
            cupDateInfo:cupDateInfo.value,

            placeId:place,
            lat:latS,
            lng:lngS,
            placeName:placeNameInput.value,
            address:addressS,
            shortAddress:shortAddressS,

            youtubeUrl:youtubeUrl.value,
            joinPrice:joinPrice.value ===""? null :joinPriceUnit.value + joinPrice.value,
            host:hostUrl,
            organizer:organizerUrl,
            support:supportUrl,
            sponsor:sponsorUrl,
            contact:contact.value,
            DepositAccount:DepositAccount.value,
          }
        });

        console.log(createCup)

        if (createCup.id) {
          toast.success("생성 완료!");

          setTimeout(() => {
            history.push(`/cup/${createCup.id}`);
          }, 1000);
        }
      }
    } catch (e) {
      localStorage.setItem("addCup",JSON.stringify({
        LS_cupName:cupName.value,
        LS_target:target.value,
        LS_cupProcess:cupProcess.value,
        LS_playerCnt:playerCnt.value,
        LS_cupStartDate:cupStartDate.value,
        LS_cupEndDate:cupEndDate.value,
        LS_placeId:place,
        LS_lat:latS,
        LS_lng:lngS,
        LS_placeName:placeNameInput.value,
        LS_address:addressS,
        LS_shortAddress:shortAddressS,

        LS_youtubeUrl:youtubeUrl.value,
        LS_joinPrice:joinPrice.value ===""? null :joinPriceUnit.value + joinPrice.value,
        LS_host:host,
        LS_organizer:organizer,
        LS_support:support,
        LS_sponsor:sponsor,
        
        LS_contact:contact.value,
        LS_DepositAccount:DepositAccount.value,
      }));
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  const AddGroup = () => {
    const _group = [...group];
    if(groupInput.value === "") {
      toast.error("그룹 이름을 입력해주세요.");
      return;
    }
    if(_group.indexOf(groupInput.value) !== -1) {
      toast.error("이미 같은 이름의 그룹이 있습니다.");
      return;
    }
    _group.push(groupInput.value);
    setGroup(_group);
    groupInput.setValue("");
  }

  const DeleteGroup = g => () => {
    const _group = [...group];
    _group.splice(_group.findIndex(i => i === g), 1);
    setGroup(_group);
  }


  return (
    <CreateCupPresenter
      cupId={cupId}
      uploading={uploading}
      editLogo={editLogo}
      handleEditPhotoOpen={handleEditPhotoOpen}
      handleSubmit={handleSubmit}

      addressS={addressS}
      AddGroup={AddGroup}
      DeleteGroup={DeleteGroup}
      onDropPicture={onDropPicture}
      onRemovePicture={onRemovePicture}

      cupName={cupName}
      target={target}
      cupProcess={cupProcess}
      playerCnt={playerCnt}

      cupStartDate={cupStartDate}
      cupEndDate={cupEndDate}
      cupDateInfo={cupDateInfo}

      host={host}
      organizer={organizer}
      support={support}
      sponsor={sponsor}
      group={group}
      
      url={url}
      youtubeUrl={youtubeUrl}
      joinPriceUnit={joinPriceUnit}
      joinPrice={joinPrice}
     
      firstPrize={firstPrize}
      secondPrize={secondPrize}
      thirdPrize={thirdPrize}
      mvpPrize={mvpPrize}
      mannerPrize={mannerPrize}
      
      contact={contact}
      DepositAccount={DepositAccount}
      placeNameInput={placeNameInput}
      groupInput={groupInput}


      setAddressInfo={setAddressInfo}

      onChangePlace={onChangePlace}
      searchPlaceData={searchPlaceData}
      searchPlace={searchPlace}
      searchingPlace={searchingPlace}
      openPlaceDialog={openPlaceDialog}
      handlePlaceDialogOpen={handlePlaceDialogOpen}
      handlePlaceDialogClose={handlePlaceDialogClose}
      
    />
  );
};

export default withRouter(CreateCupContainer);
