import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_NOTICES,
  DELETE_NOTICE
} from "./CupNoticeListQueries";
import CupNoticeListPresenter from "./CupNoticeListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";

const CupNoticeListContainer = ({ match: { params }, history }) => {
  const cupId = params.cup;

  const { data, loading, fetchMore, refetch } = useQuery(SEE_NOTICES, {
    variables: {
      cupId
    }
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const deleteNoticeMutation = useMutation(DELETE_NOTICE);
  
  const [notices, setNotices] = useState([]);
  
  const handleInfiniteScroll = () => {
    fetchMore({
      variables: {
        skip: data.seeRanking.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          seeRanking: [...prev.seeUsers, ...fetchMoreResult.seeUsers]
        });
      }
    });
  };
  const onCreatePage = () => {
    history.push(`/cup/${cupId}/notice/create`);
  };

  const handleDelete = async () => {
    try {
      if(selectedItem) {
        const data = [...notices];
        data.splice(data.findIndex(i => i.id === selectedItem), 1);
        setNotices( data );
        handleDialogClose();
        await deleteNoticeMutation({
          variables: { id: selectedItem, action:"DELETE" }
        });
      }
    } catch(e) {
      console.log(e);
    } finally {
      handleDialogClose();
      refetch();
    }
  };

  const handleDialogOpen = (id) => () => {
    setOpenDialog(true);
    setSelectedItem(id);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };
  
  return (
    <CupNoticeListPresenter
      loading={loading}
      data={data}
      notices={notices}
      cupId={cupId}
      onCreatePage={onCreatePage}
      setNotices={setNotices}
      openDialog={openDialog}
      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}

      handleDelete={handleDelete}
      handleInfiniteScroll={handleInfiniteScroll}
    />
  );
};

export default withRouter(CupNoticeListContainer);
