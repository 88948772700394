import { gql } from "apollo-boost";

export const SEE_LOGS = gql`
  query seeNotiLogs {
    seeNotiLogs {
      id
      type
      target
      title
      message
      createdAt
      updatedAt
    }
  }
`;


export const SEND_NOTIFICATION = gql`
  mutation createNotification(
    $teamId: String
    $userId: String
    $action: String!
    $title: String!
    $message: String!
  ) {
    createNotification(
      teamId:$teamId
      userId:$userId
      action:$action
      title:$title
      message:$message
    ) 
  }
`;

export const SEARCH_USER = gql`
  query searchUser($skip: Int, $first: Int, $term: String!) {
    searchUser(skip: $skip, first: $first, term: $term) {
      id
      avatar
      username
    }
  }
`;

export const SEARCH_TEAM = gql`
  query searchAllTeams($skip: Int, $first: Int, $term: String!) {
    searchAllTeams(skip: $skip, first: $first, term: $term) {
      id
      logo
      teamName
      cup {
        id
        cupName
      }
    }
  }
`;