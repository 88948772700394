import { gql } from "apollo-boost";

export const CREATE_CUP_NOTICE = gql`
  mutation createCupNotice($cupId: String!, $title: String!, $text: String!) {
    createCupNotice(cupId: $cupId, title: $title, text: $text) {
      id
    }
  }
`;

export const EDIT_CUP_NOTICE = gql`
  mutation editNotice($id: String!, $title: String!, $text: String!, $action: String!) {
    editNotice(id: $id, title: $title, text: $text, action:$action) {
      id
      text
      title
    }
  }
`;

export const SEE_NOTICE = gql`
  query seeFullNotice($id: String!) {
    seeFullNotice(id: $id) {
      id
      writer {
        id
        username
      }
      title
      text
      createdAt
    }
  }
`;
