import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
import VoteList from "../../Components/VoteList";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import { Button } from "@material-ui/core";
import MapContainer from "../../Components/Map";

const Wrapper = styled.div``;
const Divider = styled.hr`
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
`;
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  subHeaderText: {
    fontFamily: "NanumSqure",
    paddingTop: "10px",
    color: "#000"
  },
  detailHeader: {
    marginTop: "15px"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  dialog: {}
}));

const DetailBox = styled.div``;

const VoteLabel = styled.h6`
  padding:10px 0px 0px;
`;

const ScoreTable = styled.table`
  border: 1px solid #000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 11px;
  }
`;

const ScoreThead = styled.thead`
  border-bottom: 1px solid #000;
  & > tr {
    border-bottom: 1px solid #000;
    & > th:first-child {
      font-weight: 600;
    }
  }
  & th {
    padding: 15px 0px;
  }
`;

const ScoreTbody = styled.tbody`
  & > tr {
    border-top: 1px solid #dddddd;
    & > td:first-child {
      font-weight: 600;
      max-width: 150px;
    }
  }
  & td {
    padding: 15px 0px;
  }
`;

export default ({ loading, data }) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullGame) {
    const {
      seeFullGame: {
        homeTeam,
        awayTeam,
        place: {
          placeName,
          address,
          location: { latitude, longitude }
        },
        result,
        playerCnt,
        votes,
        gameStatus,
        gameDate,
        startTime,
        endTime
      }
    } = data;
    console.log(data);
    const classes = useStyles();
    const homeVotes = votes.filter(element => element.team.id === homeTeam.id);
    const awayVotes = votes.filter(element => element.team.id === awayTeam.id);

    console.log(homeVotes);
    console.log(awayVotes);

    return (
      <Wrapper>
        <Helmet>
          <title>경기 정보 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              경기 상세 정보
              <Link to={"/games"}>
                <Button variant="contained" color="primary">
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="h5"
                      className={classes.subHeaderText}
                      gutterBottom
                    >
                      경기 정보
                      <span>
                        <Chip color="secondary" label={`경기 상태 : ${gameStatus}`} />
                      </span>
                    </Typography>

                    <DetailBox>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.detailHeader}
                      >
                        경기 일정
                      </Typography>
                      <div>
                        <Chip
                          color="primary"
                          label={`날짜: ${moment(gameDate).format(
                            "YYYY-MM-DD"
                          )} / 시간: ${startTime} - ${endTime}`}
                        />
                      </div>
                    </DetailBox>
                    <DetailBox>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.detailHeader}
                      >
                        경기장 정보
                      </Typography>
                      <div>
                        <Chip color="primary" label={address} />
                      </div>
                    </DetailBox>
                    <DetailBox className={classes.detailHeader}>
                      <Typography gutterBottom variant="body1">
                        경기 타입
                      </Typography>
                      <div>
                        <Chip
                          color="primary"
                          label={`${playerCnt} x ${playerCnt}`}
                        />
                      </div>
                    </DetailBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MapContainer
                      lat={latitude}
                      lng={longitude}
                      isInit={true}
                      placeName={placeName}
                      address={address}
                      showPlaceName={true}
                      showAddress={false}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.subHeaderText}
                      gutterBottom
                    >
                      {homeTeam.teamName}(홈팀)
                      <Typography
                        gutterBottom
                        display="inline"
                        component="span"
                        variant="h6"
                      >
                        - Lv.{homeTeam.level}
                      </Typography>
                    </Typography>
                    <Typography
                      gutterBottom
                      display="inline"
                      component="span"
                      variant="h6"
                    >
                      총 전적 : {homeTeam.win}승 {homeTeam.lose}패
                    </Typography>
                    
                    <Divider />

                    <VoteLabel>
                    참불 투표
                    </VoteLabel>
    
                    <List>
                      {homeVotes &&
                        homeVotes.map(vote => (
                          <VoteList
                            key={vote.id}
                            url={vote.user.avatar}
                            primary={vote.user.username}
                            secondary={`Lv.${vote.user.level}`}
                            answer={vote.answer}
                            isButton={false}
                          />
                        ))}
                    </List>
                  </Paper>
                </Grid>
                {awayTeam ? (
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      <Typography
                        variant="h5"
                        className={classes.subHeaderText}
                        gutterBottom
                      >
                        {awayTeam.teamName}(어웨이팀)
                        <Typography
                          gutterBottom
                          display="inline"
                          component="span"
                          variant="h6"
                        >
                          - Lv.{awayTeam.level}
                        </Typography>
                      </Typography>
                      <Typography
                        gutterBottom
                        display="inline"
                        component="span"
                        variant="h6"
                      >
                        총 전적 : {awayTeam.win}승 {awayTeam.lose}패
                      </Typography>

                      <Divider />
                      <VoteLabel>
                      참불 투표
                      </VoteLabel>
                      <List>
                        {awayVotes &&
                          awayVotes.map(vote => (
                            <VoteList
                              key={vote.id}
                              url={vote.user.avatar}
                              primary={vote.user.username}
                              secondary={`Lv.${vote.user.level}`}
                              answer={vote.answer}
                              isButton={false}
                            />
                          ))}
                      </List>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      <Typography
                        variant="h5"
                        className={classes.subHeaderText}
                        gutterBottom
                      >
                        미정 (어웨이팀)
                      </Typography>


                      <Divider />
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {(gameStatus === "CONFIRM" || gameStatus === "COMPLETE") && (
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.subHeaderText}
                    gutterBottom
                  >
                    경기 결과
                  </Typography>

                  <ScoreTable>
                    <ScoreThead>
                      <tr>
                        <th></th>
                        {result.homeTeam.map((_,i) => <th key={i}>{i+1}쿼</th>)}
                        <th>최종</th>
                      </tr>
                    </ScoreThead>
                    <ScoreTbody>
                      <tr>
                        <td>{homeTeam.teamName}</td>
                        {result.homeTeam.map((score, i) => <td key={i}>{score}</td>)}
                        <td>
                          {result.homeTeam.reduce(
                            (acc, cur) =>
                              parseInt(acc) + (cur === "" ? 0 : parseInt(cur))
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{awayTeam.teamName}</td>
                        {result.awayTeam.map((score, i) => <td key={i}>{score}</td>)}
                        <td>
                          {result.awayTeam.reduce(
                            (acc, cur) =>
                              parseInt(acc) + (cur === "" ? 0 : parseInt(cur))
                          )}
                        </td>
                      </tr>
                    </ScoreTbody>
                  </ScoreTable>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>

        {/* <Dialog
          open={openRemovePicture}
          onClose={handleRemovePictureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"해당 사진을 삭제하시겠습니까?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleRemovePictureClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleOldPictureDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog> */}
      </Wrapper>
    );
  }
};
