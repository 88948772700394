import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import Loader from "../../Components/Loader";
import Progress from "../../Components/Progress";
import AdminHeader from "../../Components/Header/AdminHeader";
import PostCode from "../../Components/PostCode";
import Avatar from "../../Components/Avatar";

import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const Wrapper = styled.div``;

const memberColumn = [
  {
    title: "회원권한",
    field: "authority",
    emptyValue: "user",
    lookup: { host: "호스트(대표)", manager: "호스트", user: "회원"},
    cellStyle: { width: "150px" }
  },
  { title: "이름", editable: "never", field: "username" },
  { title: "이메일", editable: "never", field: "email" }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  dialog: {},
  appBar: {
    width: "360px",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop:"20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel : {
    display:"flex",
    alignItems:"center",
    marginBottom:"15px",
    "& > button": {
      marginLeft:"15px;"
    }
  }
}));

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  loading,
  data,
  onDropProfles,
  onDropLogo,
  onRemoveLogo,
  onRemoveProfile,
  handleSubmit,
  teamNameInput,
  levelInput,
  contactInput,
  bioInput,
  onChangePlace,
  addressS,
  member,
  setMember,
  open,
  uploading,
  handleSearchOpen,
  handleSearchClose,
  searchData,
  search,
  searching,
  oldProfiles,
  setOldProfiles,
  editLogo,
  editProfile,
  handleEditPhotoOpen,
  handleProfileDelete,
  openRemoveProfile,
  handleRemoveProfileOpen,
  handleRemoveProfileClose,
  handleUserUpdate,
  handleUserDelete,
  logoUrl,
  setLogoUrl
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullTeam) {
    const classes = useStyles();
    

    const componentConfig = {
      postUrl: "/"
    };

    const djsProfileConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 5
    };

    const djsLogoConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 1
    };

    const eventHandlers = {
      addedfile: file => {
        console.log(file);
        
        onDropProfles(file)
      },
      removedfile: file => onRemoveProfile(file),
      maxfilesexceeded: (file) => { console.log(file); alert("!!!")},
      init: () => {
        if (document.getElementsByClassName("dz-message")[0])
          document.getElementsByClassName("dz-message")[0].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
        if (document.getElementsByClassName("dz-message")[1])
          document.getElementsByClassName("dz-message")[1].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
      }
    };

    const eventHandlersLogo = {
      addedfile: file => onDropLogo(file),
      removedfile: file => onRemoveLogo(),
      maxfilesexceeded: (file) => { console.log(file); alert("!!!")},
      init: () => {
        if (document.getElementsByClassName("dz-message")[0])
          document.getElementsByClassName("dz-message")[0].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
        if (document.getElementsByClassName("dz-message")[1])
          document.getElementsByClassName("dz-message")[1].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
      }
    };

    const {
      seeFullTeam: {
        teamName,
        contact,
        bio,
        level,
        host,
        managers,
        users,
        place,
        profile,
        logo:prevLogo
      }
    } = data;

    useEffect(() => {
      teamNameInput.setValue(teamName);
      levelInput.setValue(level);
      contactInput.setValue(contact);
      bioInput.setValue(bio);
      console.log(profile);
      setOldProfiles(profile);
      setLogoUrl(prevLogo);
      
      let _member = [];
      if(host) {
        host["authority"] = "host";
        _member = [host];
      }
      if (managers && managers.length > 0) {
        managers.map(manager => (manager["authority"] = "manager"));
      }
      if (users && users.length > 0) {
        users.map(user => (user["authority"] = "user"));
      }

      setMember(_member.concat(managers, users));
      if(place)
        onChangePlace(place.id, place.address);
      //setAddressInfo(placeName, address, shortAddress, latitude, longitude);
    }, [loading]);

    const superAdminEditable = {
      onRowUpdate: async (newData, oldData) => handleUserUpdate(newData, oldData),
      //onRowDelete: oldData => handleUserDelete(oldData)
    };

    return (
      <Wrapper>
        <Helmet>
          <title>팀관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              팀 정보 관리
              <Link to={"/teams"}>
                <Button
                  variant="contained"
                  color="primary"
              
                >
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
           
            <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      필수 정보
                    </Typography>
                    
                    <TextField
                      required
                      id="standard-teamNameInput"
                      label="팀 이름"
                      value={teamNameInput.value}
                      onChange={teamNameInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                    <TextField
                      required
                      id="standard-levelInput"
                      label="팀 레벨"
                      type="number"
                      inputProps={{
                        max:5,
                        min:1
                      }}
                      value={levelInput.value}
                      onChange={levelInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      required
                      id="standard-contactInput"
                      label="연락처"
                      value={contactInput.value}
                      onChange={contactInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      id="filled-multiline-static"
                      label="팀 소개"
                      multiline
                      rows="4"
                      value={bioInput.value}
                      onChange={bioInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <FormLabel className={classes.label}>경기장 주소</FormLabel>
                    <PostCode
                      setAddressInfo={() => {}}
                      defaultValue={addressS}
                      text={"주소 검색"}
                      search={false}
                    />
                    <Button variant="contained" onClick={handleSearchOpen}>
                      경기장 변경
                    </Button>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <DropZoneWrapper>
                      <FormLabel  className={classes.editLabel}>
                        팀 프로필(최대 5개)
                        <Button
                          variant="contained"
                          onClick={handleEditPhotoOpen("profile")}
                        >
                          프로필 추가
                        </Button>
                      </FormLabel>

                      <GridList className={classes.gridList} cols={1.5}>
                        {oldProfiles.map(tile => (
                          <GridListTile key={tile.url}>
                            <img
                              src={tile.url}
                              alt={`위플레이 | ${teamName}`}
                            />
                            <GridListTileBar
                              classes={{
                                root: classes.titleBar,
                                title: classes.title
                              }}
                              actionIcon={
                                <IconButton aria-label={`Delete`} onClick={handleRemoveProfileOpen(tile.id)}>
                                  <DeleteIcon className={classes.title} />
                                </IconButton>
                              }
                            />
                          </GridListTile>
                        ))}
                      </GridList>

                      {editProfile && (
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlers}
                          djsConfig={djsProfileConfig}
                        />
                      )}
                    </DropZoneWrapper>

                    <DropZoneWrapper>
                      <FormLabel className={classes.editLabel}>
                        팀 로고
                        <Button
                          variant="contained"
                          onClick={handleEditPhotoOpen("logo")}
                        >
                          로고 변경
                        </Button>
                      </FormLabel>

                      {logoUrl && 
                        <Avatar
                          size={"lg"}
                          url={logoUrl}
                          alt={`위플레이 | ${teamName}`}
                        />
                      }

                      {editLogo && (
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlersLogo}
                          djsConfig={djsLogoConfig}
                        />
                      )}
                    </DropZoneWrapper>
                  </Paper>
                </Grid>
              </Grid>

              <Grid>
                <MaterialTable
                  columns={memberColumn}
                  data={member}
                  options={{
                    //pageSize: 1,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    sorting: false
                  }}
                  localization={{
                    header: {
                      actions: ""
                    }
                  }}
                  editable={superAdminEditable}
                />
              </Grid>
              
              <Grid container justify="center" spacing={2}>
                <UploadButton1 >업로드</UploadButton1>
              </Grid>
              </form>
            </Grid>
           
          </Grid>
        </Container>

        <Dialog
          className={classes.dialog}
          open={open}
          onClose={handleSearchClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleSearchClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="경기장을 검색해주세요."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={search.value}
                  onChange={search.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>
          <Container className={classes.searchContainer}>
            <List dense>
              {searching && <CircularProgress />}
              {searchData &&
                searchData.seePlaces &&
                searchData.seePlaces.length > 0 &&
                searchData.seePlaces.map(place => (
                  <ListItem
                    button
                    key={place.id}
                    onClick={() => {
                      onChangePlace(place.id, place.address);
                    }}
                  >
                    <ListItemText
                      primary={place.placeName}
                      secondary={place.address}
                    />
                  </ListItem>
                ))}
            </List>
          </Container>
        </Dialog>

        <Dialog
          open={openRemoveProfile}
          onClose={handleRemoveProfileClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"해당 사진을 삭제하시겠습니까?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleRemoveProfileClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleProfileDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
};
