import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import Progress from "../../Components/Progress";
import AdminHeader from "../../Components/Header/AdminHeader";
import Avatar from "../../Components/Avatar";
import BoldText from "../../Components/BoldText";
import DatePicker from "../../Components/DatePicker";
import TimePicker from "../../Components/TimePicker";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import {
  TextField,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  IconButton
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import RoundCheckBox from "../../Components/RoundCheckBox";
import PostCode from "../../Components/PostCode";
import Loader from "../../Components/Loader";
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';


const Wrapper = styled.div``;

const GameHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  border: 1px solid #c8c8c8;
  margin: 0 auto;
  padding-top: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  &:nth-child(2) {
    padding-top: 0px;
    align-self: center;
  }
`;

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position: relative;
  padding: 0px 10px;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const TeamNameWrapper = styled.div`
  & > .MuiIconButton-root {
    padding:0px!important;
    margin-left:10px;
  }
`;

const HeaderAvatar = styled.div`
  border: 1px solid #227fff;
  border-radius: 50%;
  padding: 6px;
  min-width: 99px;
  min-height: 99px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;

  flex-direction: column;
  padding: 15px 0px;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

const TeamSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  dialog: {
    "& .MuiDialog-paper": {
      minWidth: 400
    },
    "& .MuiDialogContent-dividers": {
      minHeight: 150
    }
  },
  userList: {
    border: "1px solid #f0f0f0"
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    minHeight: 500
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  title: {
    color: theme.palette.primary.light
  },
  editLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "20px",
    flexDirection: "column",
    "& > button": {
      marginTop: "15px;"
    }
  },
  textField: {
    marginBottom: "20px"
  }
}));

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  data,
  loading,
  cupId,
  handleSubmit,
  uploading,
  homeTeam,
  awayTeam,
  setHomeTeam,
  setAwayTeam,
  gameName,
  gameDate,
  startTime,
  endTime,
  setTeams,
  teams,
  handleTeamAdd,
  handleTeamRemove,
  openDialog,
  handleDialogOpen,
  handleDialogClose,
  selectedTeam,
  setSelectedTeam,
  placeNameInput,
  setAddressInfo,
  setCupPlaceId,
  addressS,
  playerCntInput
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCup && data.seeCupTeams) {
    const classes = useStyles();

    const handleToggle = value => () => {
      setSelectedTeam(value);
    };
    
    useEffect(() => {
      startTime.setValue(data.seeFullGame.startTime);
      endTime.setValue(data.seeFullGame.endTime);
      gameDate.setValue(moment(data.seeFullGame.gameDate).format("YYYY-MM-DD"));
      gameName.setValue(data.seeFullGame.gameName);
      setHomeTeam(data.seeFullGame.homeTeam);
      setAwayTeam(data.seeFullGame.awayTeam);
      playerCntInput.setValue(data.seeFullGame.playerCnt);

      setCupPlaceId(data.seeFullGame.place.id);
      placeNameInput.setValue(data.seeFullGame.place.placeName);
      setTeams(data.seeCupTeams);
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>경기 생성 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              대회 경기 수정
              <Link to={`/cup/${cupId}/games`}>
                <Button variant="contained" color="primary">
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      경기 팀정보
                    </Typography>

                    <TeamSelectWrapper>
                      <FormLabel className={classes.editLabel}>
                        1팀 선택*
                        <Button
                          variant="contained"
                          onClick={handleDialogOpen("home")}
                        >
                          검색
                        </Button>
                      </FormLabel>

                      <FormLabel className={classes.editLabel}>
                        2팀 선택*
                        <Button
                          variant="contained"
                          onClick={handleDialogOpen("away")}
                        >
                          검색
                        </Button>
                      </FormLabel>
                    </TeamSelectWrapper>

                    <GameHeader>
                      <GameHeaderColumn>
                        <ColumnWrap>
                          <ColumnRow>
                            <HeaderAvatar>
                              {homeTeam ? (
                                <Avatar url={homeTeam.logo} size={"lmd"} />
                              ) : null}
                            </HeaderAvatar>
                          </ColumnRow>
                          <ColumnRow>
                            {homeTeam ? (
                               <TeamNameWrapper>
                                  <BoldText text={homeTeam.teamName} /> 
                                  <IconButton onClick={handleTeamRemove('home')}><ClearIcon /></IconButton>
                               </TeamNameWrapper>
                            ) : (
                              <BoldText text={"?"} />
                            )}
                          </ColumnRow>
                        </ColumnWrap>
                      </GameHeaderColumn>
                      <GameHeaderColumn>
                        <ColumnWrap>
                          <ColumnRow>
                            <VSText>VS</VSText>
                          </ColumnRow>
                        </ColumnWrap>
                      </GameHeaderColumn>
                      <GameHeaderColumn>
                        <ColumnWrap>
                          <ColumnRow>
                            {awayTeam ? (
                              <HeaderAvatar>
                                <Avatar url={awayTeam.logo} size={"lmd"} />
                              </HeaderAvatar>
                            ) : (
                              <HeaderAvatar></HeaderAvatar>
                            )}
                          </ColumnRow>
                          <ColumnRow>
                            {awayTeam ? (
                              <TeamNameWrapper>
                                <BoldText text={awayTeam.teamName} />
                                <IconButton onClick={handleTeamRemove('away')}><ClearIcon /></IconButton>
                              </TeamNameWrapper>
                             
                            ) : (
                              <BoldText text={"?"} />
                            )}
                          </ColumnRow>
                        </ColumnWrap>
                      </GameHeaderColumn>
                    </GameHeader>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      경기 정보
                    </Typography>

                    <TextField
                      required
                      id="gameName"
                      label="경기 이름"
                      placeholder="ex> xx대회 16강 2차전"
                      value={gameName.value}
                      onChange={gameName.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <UserInfoRow>
                      <InputInfoColumn>경기 일정</InputInfoColumn>
                      <InputInfoColumn>
                        <DatePicker gameDate={gameDate} />
                      </InputInfoColumn>
                    </UserInfoRow>

                    <UserInfoRow>
                      <InputInfoColumn>
                        경기 시간 ( 시작 - 종료 )
                      </InputInfoColumn>
                      <InputInfoColumn>
                        <TimePicker startTime={startTime} endTime={endTime} />
                      </InputInfoColumn>
                    </UserInfoRow>
                    <UserInfoRow>
                      <InputInfoColumn>경기 장소</InputInfoColumn>
                      <TextField
                        required
                        id="placeName"
                        label="장소명"
                        disabled={addressS === undefined}
                        value={placeNameInput.value}
                        onChange={placeNameInput.onChange}
                        margin="normal"
                      />
                      <PostCode
                        defaultValue={data.seeFullGame.place.address}
                        setAddressInfo={setAddressInfo}
                        text={"주소 검색"}
                        search={true}
                      />
                    </UserInfoRow>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleSubmit}>수정완료</UploadButton1>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          className={classes.dialog}
          open={openDialog}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">팀 선택</DialogTitle>
          <DialogContent dividers>
            <List>
              {!teams && <CircularProgress />}
              {teams &&
                teams.length > 0 &&
                teams.map(team => (
                  <ListItem key={team.id} button onClick={handleToggle(team)}>
                    <ListItemAvatar>
                      <Avatar url={team.logo} />
                    </ListItemAvatar>
                    <ListItemText id={team.id} primary={team.teamName} />
                    <ListItemSecondaryAction>
                      <RoundCheckBox
                        checked={
                          selectedTeam ? selectedTeam.id === team.id : false
                        }
                        onChange={handleToggle(team)}
                      />
                      {/* <Checkbox
                    edge="end"
                    onChange={handleToggle(vote.id)}
                    checked={checked.indexOf(vote.id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                  /> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              취소
            </Button>
            <Button onClick={handleTeamAdd} color="primary">
              추가
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
};
