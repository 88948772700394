import React, { useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { CREATE_TEAM} from "./CreateCupTeamQueries";
import CreateCupTeamPresenter from "./CreateCupTeamPresenter";
import {  useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";
import { SEE_TEAMS } from "../CupTeamList/CupTeamListQueries";

const CreateCupTeamContainer = ({ match: { params }, history }) => {
  const cupId = params.cup;

  const [logo, setLogo] = useState();
  const [profiles, setProfiles] = useState([]);

  const sportInput = useInput("1001");
  const teamNameInput = useInput("");
  const agentNameInput = useInput("");
  const contactInput = useInput("", newValue => !isNaN(newValue));
  const bioInput = useInput("");

  const email = useInput("");
  const contact = useInput("", newValue => !isNaN(newValue));
  const username = useInput("");
  const backNo = useInput("", newValue => !isNaN(newValue));
  const age = useInput("", newValue => !isNaN(newValue));
  const height = useInput("", newValue => !isNaN(newValue));
  const weight = useInput("", newValue => !isNaN(newValue));
  const position = useInput("");

  const [IsDialogHost, setIsDialogHost] = useState(false);
  const [host, setHost] = useState();
  const [users, setUsers] = useState([]);

  const [editLogo, setEditLogo] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const createTeamMutation = useMutation(CREATE_TEAM, {
    refetchQueries:[{query:SEE_TEAMS, variables:{ cupId }}]
  });

  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const onUserAdd = () => {
    if(email.value === "") {
      email.setValue(contact.value);
      toast.info(<div>이메일이 없을 경우 <br/>이메일 대신 연락처가 등록됩니다.</div>);
    }

    if (
      username.value === "" ||
      backNo === "" ||
      height.value === "" ||
      weight.value === "" ||
      position.value === ""
    ) {
      toast.error(<div>필수 정보를 입력해주세요.</div>);
      return;
    }

    if (IsDialogHost) {
      setHost({
        avatar: "/imgs/user_icon_list.png",
        contact: contact.value,
        email: email.value,
        username: username.value,
        backNo: backNo.value,
        age: age.value,
        height: height.value,
        weight: weight.value,
        position: position.value
      });
    } else {
      const _users = [...users];

      if (editMode) {
        _users[selectedUser] = {
          avatar: "/imgs/user_icon_list.png",
          contact: contact.value,
          email: email.value,
          username: username.value,
          backNo: backNo.value,
          age: age.value,
          height: height.value,
          weight: weight.value,
          position: position.value
        };
      } else {
        _users.push({
          avatar: "/imgs/user_icon_list.png",
          contact: contact.value,
          email: email.value,
          username: username.value,
          backNo: backNo.value,
          age: age.value,
          height: height.value,
          weight: weight.value,
          position: position.value
        });
      }

      setUsers(_users);
    }

    email.setValue("");
    username.setValue("");
    backNo.setValue("");
    contact.setValue("");
    age.setValue("");
    height.setValue("");
    weight.setValue("");
    position.setValue("");
    handleDialogClose();
  };

  // 호스트 선택 시 변경값 설정
  const onChangeHost = (id, avatar, username, email) => {
    setHost({
      id,
      avatar,
      username,
      email
    });
  };

  // 프로필 사진 드롭 시 이벤트
  const onDropProfles = acceptedFiles => {
    const data = profiles;
    data.push(acceptedFiles);
    setProfiles(data);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveProfile = removeFile => {
    const data = profiles;
    data.splice(
      data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
      1
    );
    setProfiles(data);
  };

  const onRemoveUser = user => {
    const data = [...users];
    data.splice(
      data.findIndex(i => i.email === user.email),
      1
    );
    setUsers(data);
  };

  const onRemoveHost = () => {
    setHost(null);
  };

  const handleEditPhotoOpen = type => () => {
    if (type === "logo") setEditLogo(!editLogo);
    else if (type === "profile") setEditProfile(!editProfile);
  };

  // 로고파일 드롭 이벤트
  const onDropLogo = acceptedFile => {
    setLogo(acceptedFile);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveLogo = () => {
    setLogo(null);
  };

  // 회원창 오픈 이벤트
  const handleDialogOpen = type => () => {
    if (type === "host" && host) {
      email.setValue(host.email);
      contact.setValue(host.contact);
      username.setValue(host.username);
      backNo.setValue(host.backNo);
      age.setValue(host.age);
      height.setValue(host.height);
      weight.setValue(host.weight);
      position.setValue(host.position);
    } else {
      email.setValue("");
      username.setValue("");
      backNo.setValue("");
      contact.setValue("");
      age.setValue("");
      height.setValue("");
      weight.setValue("");
      position.setValue("");
    }
    setEditMode(false);
    setIsDialogHost(type === "host" ? true : false);
    setOpenDialog(true);
  };

  // 회원창 오픈 이벤트
  const handleUserDialogOpen = (type, user, idx) => () => {
    email.setValue(user.email);
    contact.setValue(user.contact);
    username.setValue(user.username);
    backNo.setValue(user.backNo);
    age.setValue(user.age);
    height.setValue(user.height);
    weight.setValue(user.weight);
    position.setValue(user.position);
    
    setSelectedUser(idx);
    setIsDialogHost(type === "host" ? true : false);
    setEditMode(true);
    setOpenDialog(true);
  };

  // 회원 닫기 이벤트
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes

    if (uploading) return false;

    try {
      if (users.length === 0) {
        toast.error("선수는 최소 1명 이상 등록해주세요");
      } else if (teamNameInput.value === "") {
        toast.error("팀 이름은 필수값입니다.");
      } else {
        setUploading(true);

        let LogoUrl;
        let ProfileUrls;
        if (logo) {
          const formData = new FormData();
          formData.append("logo", logo);

          const {
            data: { location }
          } = await axios.post(
            "https://weplay2921.cafe24.com/api/team/upload",
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          LogoUrl = location;
        }

        if (profiles && profiles.length > 0) {
          const formData = new FormData();
          profiles.forEach(file => {
            formData.append("profile", file);
          });

          const { data } = await axios.post(
            "https://weplay2921.cafe24.com/api/team/profile/upload",
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );

          ProfileUrls = data;
        }

        const {
          data: { createCupTeam }
        } = await createTeamMutation({
          variables: {
            cupId,
            sportId: sportInput.value,
            teamName: teamNameInput.value,
            agent: agentNameInput.value,
            contact: contactInput.value,
            bio: bioInput.value,
            logo: LogoUrl,
            profiles: ProfileUrls,
            host: JSON.stringify(host),
            users: JSON.stringify(users)
          }
        });

        if (createCupTeam.id) {
          toast.success("업로드 완료!");

          setTimeout(() => {
            history.push(`/cup/${cupId}/team/${createCupTeam.id}`);
            //window.location.href = `/cup/${cupId}/team/${createCupTeam.id}`;
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
      setTimeout(()=> SaveDataToLocalStorage(),1000);
      //SaveDataToLocalStorage();

    } finally {
      setUploading(false);
    }
  };

  const SaveDataToLocalStorage = () => {
    localStorage.setItem("addCupTeam",JSON.stringify({
      LS_sportId: sportInput.value,
      LS_teamName: teamNameInput.value,
      LS_agent: agentNameInput.value,
      LS_contact: contactInput.value,
      LS_bio: bioInput.value,
      LS_host: JSON.stringify(host),
      LS_users: JSON.stringify(users)
    }));

    toast.info("임시 저장되었습니다.");
  };

  const LoadDataFromLocalStorage = () => {

    
    if(localStorage.getItem("addCupTeam")) {
      const {
        LS_teamName,
        LS_agent,
        LS_contact,
        LS_bio,
        LS_host,
        LS_users
      } = JSON.parse(localStorage.getItem("addCupTeam"));


      if(LS_teamName)
        teamNameInput.setValue(LS_teamName);
      if(LS_agent)
        agentNameInput.setValue(LS_agent);
      if(LS_contact)
        contactInput.setValue(LS_contact);
      if(bioInput)
        bioInput.setValue(LS_bio);

      if(LS_host) 
        setHost(JSON.parse(LS_host));

      if(LS_users) 
        setUsers(JSON.parse(LS_users));

      localStorage.removeItem('addCupTeam');
    }
  }


  useEffect(() => {
    LoadDataFromLocalStorage();
  }, []);

  return (
    <CreateCupTeamPresenter
      cupId={cupId}
      uploading={uploading}
      editLogo={editLogo}
      handleEditPhotoOpen={handleEditPhotoOpen}
      onDropLogo={onDropLogo}
      onRemoveLogo={onRemoveLogo}
      handleSubmit={handleSubmit}
      editProfile={editProfile}
      onDropProfles={onDropProfles}
      onRemoveProfile={onRemoveProfile}
      sportInput={sportInput}
      teamNameInput={teamNameInput}
      agentNameInput={agentNameInput}
      contactInput={contactInput}
      bioInput={bioInput}
      onRemoveUser={onRemoveUser}
      onRemoveHost={onRemoveHost}
      email={email}
      username={username}
      backNo={backNo}
      age={age}
      height={height}
      weight={weight}
      position={position}
      contact={contact}
      openDialog={openDialog}
      handleUserDialogOpen={handleUserDialogOpen}
      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}
      host={host}
      users={users}
      IsDialogHost={IsDialogHost}
      onUserAdd={onUserAdd}
      onChangeHost={onChangeHost}

      SaveDataToLocalStorage={SaveDataToLocalStorage}
    />
  );
};

export default withRouter(CreateCupTeamContainer);
