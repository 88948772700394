import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
//import Button from "../../Components/AdminButton";
import AddIcon from "@material-ui/icons/Add";
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
`;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 80px 0 40px;
`;

const ListSection = styled(Section)`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 0px 0px 60px;
`;

const Title = styled.h4`
  font-size:24px;
  margin-bottom:15px;
`;

const CupListWrap = styled.div`
  margin: 0 auto;
`;

const CupListUl = styled.ul`

`;

const CupList = styled.li`
  text-align: center;
  margin-left: 0;
  padding: 10px;
  display: inline-block;
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
    margin:0 auto;
  }
`;

const CupCard = styled.div`

  background-color: white;
  border-radius: 4px;
  border: 1px solid #ddd;
`;


const CupCardBottom = styled.div`
  padding: 0px 20px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:${props => props.active? "#000":"#e0e0e0"};
  & > h4 {
    font-size:20px;
    font-weight:500;
    cursor: pointer;
  }
  & > h5 {
    font-size:15px;
    font-weight:500;
  }
  & > span {
    font-size: 13px;
    color: #999;
  }
`;

const CupCardActions = styled.div`
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    & > a {
      margin:5px;
    }
    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

`;

const DialogContent = styled.div`
  padding: 15px 24px;
  font-size: 20px;
  text-align: center;
  & > p {
    font-weight:900;
    font-size:25px;
    margin-bottom:30px;
  }
`;

const DialogButtons = styled.div`
  padding: 8px 24px;
    display: flex;
    justify-content: center;
`;

const DButton = styled(Button)`
  margin:5px 10px!important;
  /* &:first-child > span {
    color:red;
  }
  &:nth-child(2) > span {
    color:red;
  }
  &:lst-child > span {
    color:red;
  }
   */
`;


export default function CupListPresenter({
  loading,
  data,
  onCreatePage,
  onUpdatePage,
  handleDelete,
  handleToggleActive,
  selectedCup,
  open,
  handleClickOpen,
  handleClose,
  uploading
}) {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCups) {

    console.log(data);

    return (
      <Wrapper>
        <Helmet>
          <title>대회관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Loader />}
        <Section>
          <Title>대회 목록</Title>
          <Button variant="contained" color="primary" onClick={onCreatePage}>
            대회 생성
            <AddIcon />
          </Button>
        </Section>
        <ListSection>
          <CupListWrap>
            <CupListUl>
              {data.seeCups.length > 0 &&
                data.seeCups.map((cup) => (
                  <CupList key={cup.id}>
                    <CupCard>
                      {/* <CupCardHeader profile={cup.cupProfile}>
                      
                    </CupCardHeader> */}
                      <CupCardBottom active={cup.active}>
                        <h4 onClick={onUpdatePage(cup.id)}>{cup.cupName}</h4>
                        {cup.active === false && <h5>[ 비활성화 ]</h5>}
                      </CupCardBottom>
                      <CupCardActions>
                        <div>
                          <Link to={`/cup/${cup.id}`}>
                            <Button variant="contained" color="default">
                              정보보기
                            </Button>
                          </Link>
                          <Link to={`/cup/${cup.id}/teams`}>
                            <Button variant="contained" color="default">
                              참가팀
                            </Button>
                          </Link>
                          <Link to={`/cup/${cup.id}/games`}>
                            <Button variant="contained" color="default">
                              경기일정
                            </Button>
                          </Link>
                        </div>
                        <div>
                          <Link to={`/cup/${cup.id}/banner`}>
                            <Button variant="contained" color="default">
                              배너관리
                            </Button>
                          </Link>
                          <Link to={`/cup/${cup.id}/edit`}>
                            <Button variant="contained" color="primary">
                              수정
                            </Button>
                          </Link>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClickOpen(cup)}
                          >
                            {cup.active ? "숨김/삭제" : "숨김해제/삭제"}
                          </Button>
                        </div>
                      </CupCardActions>
                    </CupCard>
                  </CupList>
                ))}
            </CupListUl>
          </CupListWrap>
        </ListSection>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {selectedCup && (
              <>
                <p>{selectedCup.cupName}</p>
                숨김/삭제하시겠습니까?
              </>
            )}
          </DialogContent>
          <DialogButtons>
            <DButton
              onClick={handleToggleActive}
              variant="outlined"
              color={"primary"}
            >
              {selectedCup && selectedCup.active ? "숨김" : "숨김해제"}
            </DButton>
            <DButton
              onClick={handleDelete}
              variant="outlined"
              color={"secondary"}
            >
              삭제
            </DButton>
            <DButton onClick={handleClose} variant="outlined">
              취소
            </DButton>
          </DialogButtons>
        </Dialog>
      </Wrapper>
    );
  } 
}
