import { gql } from "apollo-boost";

export const CREATE_PLACE = gql`
  mutation createPlace(
    $lat: Float!
    $lng: Float!
    $placeName: String!
    $address: String!
    $shortAddress: String!
    $manager: String
    $contact: String
    $isAuthenticated: Boolean
    $isFree: Boolean
    $pictures: [String]
  ) {
    createPlace(
      lat: $lat
      lng: $lng
      placeName: $placeName
      address: $address
      shortAddress: $shortAddress
      manager:$manager
      contact:$contact
      isAuthenticated:$isAuthenticated
      isFree:$isFree
      pictures:$pictures
    ) {
      id
      placeName
      address
      manager
      contact
    }
  }
`;

export const EDIT_BASKET_PLACE = gql`
  mutation editBasketPlace(
    $placeId:String!
    $size:String
    $floorMaterial:String
    $groundLine:Boolean!
    $groundLight:Boolean!
    $net:Boolean!
    $showerRoom:Boolean!
    $parkingLot:Boolean!
    ) {
      editBasketPlace(
        placeId:$placeId
        size:$size
        floorMaterial:$floorMaterial
        groundLine:$groundLine
        groundLight:$groundLight
        net:$net
        showerRoom:$showerRoom
        parkingLot:$parkingLot
      ) {
        id
        size
        floorMaterial
        groundLine
        groundLight
        net
        showerRoom
        parkingLot
      }
    }
`;