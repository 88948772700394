import { gql } from "apollo-boost";

export const GET_PLACE = gql`
  query seeFullPlace($id: String!) {
    seeFullPlace(id: $id) {
      id
      placeName
      address
      picture {
        id
        url
      }
      location {
        id
        latitude
        longitude
      }
      contact
      manager
      isAuthenticated
      isFree
      basket {
        id
        size
        floorMaterial
        groundLine
        groundLight
        net
        showerRoom
        parkingLot
      }
    }
  }
`;

export const DELETE_PICTURE = gql`
  mutation deletePicture(
    $id:String!
    ) {
      deletePicture(
        id:$id
      ) 
    }
`;

export const EDIT_PLACE = gql`
  mutation editPlaceByAdmin(
    $id:String!
    $manager:String
    $contact:String
    $placeName:String!
    $isAuthenticated:Boolean!
    $isFree:Boolean!
    $pictures:[String]
    ) {
      editPlaceByAdmin(
        id:$id
        manager:$manager
        contact:$contact
        placeName:$placeName
        isAuthenticated:$isAuthenticated
        isFree:$isFree
        pictures:$pictures
      ) {
        id
        picture {
          id
          url
        }
      }
    }
`;



export const EDIT_BASKET_PLACE = gql`
  mutation editBasketPlace(
    $placeId:String!
    $size:String
    $floorMaterial:String
    $groundLine:Boolean!
    $groundLight:Boolean!
    $net:Boolean!
    $showerRoom:Boolean!
    $parkingLot:Boolean!
    ) {
      editBasketPlace(
        placeId:$placeId
        size:$size
        floorMaterial:$floorMaterial
        groundLine:$groundLine
        groundLight:$groundLight
        net:$net
        showerRoom:$showerRoom
        parkingLot:$parkingLot
      ) {
        id
        size
        floorMaterial
        groundLine
        groundLight
        net
        showerRoom
        parkingLot
      }
    }
`;

export const EDIT_USER = gql`
  mutation editMemberByAdmin(
    $id:String!
    $oppId:String!
    $oldData:String!
    $newData:String!
    ) {
      editMemberByAdmin(
        id:$id
        oppId:$oppId
        oldData:$oldData
        newData:$newData
      ) 
    }
`;


export const REMOVE_USER = gql`
  mutation removeUser(
    $id:String!
    ) {
      removeUser(
        id:$id
      ) 
    }
`;