import { gql } from "apollo-boost";

export const SEE_GAMES = gql`
  query seeGames(
    $term: String
    $first: Int!
    $skip: Int!
  ) {
    seeGames(
      term: $term,
      first: $first, 
      skip: $skip) {
      id
      sportsCode {
        id
        code
      }
      homeTeam {
        id
        teamName
        level
      }
      awayTeam {
        id
        teamName
        level
      }
      place {
        id
        placeName
        address
      }
      playerCnt
      gameStatus
      gameDate
      startTime
      endTime
    }
    gameTotalCount
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccountByAdmin(
    $avatar: String!
    $username: String!
    $email: String!
    $contact:String
    ) {
      createAccountByAdmin(
        avatar:$avatar
        username:$username
        email:$email
        contact:$contact
      ) {
        id,
        avatar,
        username,
        email,
        contact,
        basket {
          id
        }
      }
    }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id:String!
    $userId:String!
    $position:String
    $height:Int
    $weight:Int
    $backNo:Int
    $level:Int
    $grade:String
    ) {
      upsertBasket(
        id:$id
        userId:$userId
        position:$position
        height:$height
        weight:$weight
        backNo:$backNo
        level:$level
        grade:$grade
      ) {
        id
      }
    }
`;

export const EDIT_TEAM = gql`
  mutation editTeamByAdmin(
    $id:String!
    $teamName:String
    $level:Int
    $contact:String
    $action:String!
    ) {
      editTeamByAdmin(
        id:$id
        teamName:$teamName
        level:$level
        contact:$contact
        action:$action
      ) {
        id
      }
    }
`;


export const DELETE_TEAM = gql`
   mutation editTeamByAdmin(
    $id:String!
    $action:String!
    ) {
      editTeamByAdmin(
        id:$id
        action:$action
      ) {
        id
      }
    }
`;