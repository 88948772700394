import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_TEAM,
  EDIT_TEAM,
  SEARCH_PLACE,
  DELETE_PROFILE,
  EDIT_USER,
  REMOVE_USER
} from "./TeamProfileQueries";
import TeamProfilePresenter from "./TeamProfilePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";

const TeamProfileContainer = ({ match: { params } }) => {
  const teamId = params.team;
  const { data, loading, refetch } = useQuery(GET_TEAM, {
    variables: {
      id: teamId.toString()
    }
  });

  const [logo, setLogo] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [profiles, setProfiles] = useState([]);
  const [oldProfiles, setOldProfiles] = useState([]);

  const teamNameInput = useInput("");
  const levelInput = useInput(1, (newValue) => !isNaN(newValue));
  const contactInput = useInput("", (newValue) => !isNaN(newValue) );
  const bioInput = useInput("");

  const [addressS, setAddress] = useState("");
  const [place, setPlace] = useState();

  const [uploading, setUploading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRemoveProfile, setOpenRemoveProfile] = useState(false);
  const [removeProfile, setRemoveProfile] = useState();

  const search = useInput("");

  const [member, setMember] = useState();
  //console.log(data);
  
  const editTeamMutation = useMutation(EDIT_TEAM);
  const deleteProfileMutation = useMutation(DELETE_PROFILE);
  const editUserMutation = useMutation(EDIT_USER);
  const deleteUserMutation = useMutation(REMOVE_USER);

  const { loading:loadingSearch, data: searchData } = useQuery(SEARCH_PLACE, {
    skip: search.value === "",
    variables: {
      first: 30,
      skip: 0,
      term: search.value
    }
  });

  // 경기장 검색 값 변할 경우 리렌더링
  useEffect(() => {
    if(search.value.length > 0) {
      setSearching(true);
    } else {
      setSearching(false);
    }

    if(!loadingSearch && searchData) {
      setSearching(false);
    }
  }, [search.value, loadingSearch, searchData, data, loading]);

  // 경기장 변경 시 변경값 설정
  const onChangePlace = (placeId, address) => {
    setPlace(placeId);
    setAddress(address);
    handleSearchClose();
  }

  // 프로필 사진 드롭 시 이벤트
  const onDropProfles = acceptedFiles => {
    const data = profiles;
    data.push(acceptedFiles);
    setProfiles(data);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveProfile = removeFile => {
    const data = profiles;
    data.splice(data.findIndex(i => i.upload.uuid === removeFile.upload.uuid), 1);
    setProfiles( data );
  }

  // 기존 프로필 삭제 이벤트
  const handleOldProfileDelete = async () => {
    await deleteProfileMutation({
      variables: { id: removeProfile }
    });
    
    const data = oldProfiles;
    data.splice(data.findIndex(i => i.id === removeProfile), 1);
    setOldProfiles(data);
    handleRemoveProfileClose();
  }

  // 로고파일 드롭 이벤트
  const onDropLogo = acceptedFile => {
    setLogo(acceptedFile);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveLogo = () => {
    setLogo(null);
  }

  // 경기장 검색창 오픈 이벤트
  const handleSearchOpen = () => {
    setOpen(true);
  };

  // 경기장 검색창 닫기 이벤트
  const handleSearchClose = () => {
    setOpen(false);
  };

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemoveProfileOpen = (id) => () => {
    setOpenRemoveProfile(true);
    setRemoveProfile(id);
  };

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemoveProfileClose = () => {
    setOpenRemoveProfile(false);
    setRemoveProfile(null);
  };

  // 유저 테이블 수정 이벤트
  const handleUserUpdate = async (newData,oldData) => {
    setUploading(true);
    const newMembers = member;
    newMembers[member.findIndex(i => i.id === oldData.id)] = newData;
    setMember(newMembers);

    const result = await editUserMutation({
      variables: {
        id: teamId,
        oppId: oldData.id,
        oldData: oldData.authority,
        newData: newData.authority,
      }
    });

    
    
    setUploading(false);
    if(result) {
      toast.success("수정되었습니다.");
    }
  }

  // 유저 테이블 삭제 이벤트
  const handleUserDelete = async oldData => {
    await deleteUserMutation({
      variables: { id: oldData.id }
    });
    const data = member;
    data.splice(data.findIndex(i => i.id === oldData.id), 1);
    setMember(data);
  }

  const [editLogo, setEditLogo] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const handleEditPhotoOpen = type => () => {
    if (type === "logo") setEditLogo(!editLogo);
    else if (type === "profile") setEditProfile(!editProfile);
  };
  

  // 전체 업로드
  const handleSubmit = async (e) => {
    // POST to a test endpoint for demo purposes
    e.preventDefault();
    setUploading(true);
   
    let LogoUrl;
    let ProfileUrls;
    if(logo) {
      const formData = new FormData();
      formData.append("logo", logo);

      const {
        data: { location }
      } = await axios.post("https://weplay2921.cafe24.com/api/team/upload", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      });
      LogoUrl = location;
    }

    if(profiles && profiles.length > 0) {
      const formData = new FormData();
      profiles.forEach(file => {
        formData.append("profile", file);
      }); 

      const { data } = await axios.post("https://weplay2921.cafe24.com/api/team/profile/upload", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      });

      ProfileUrls = data;     
    }
   
    

    const { data : { editTeamByAdmin : team }} = await editTeamMutation({
      variables: {
        id: teamId,
        teamName: teamNameInput.value,
        level: parseInt(levelInput.value),
        contact: contactInput.value,
        bio: bioInput.value,
        placeId:place,
        profiles:ProfileUrls,
        logo:LogoUrl,
        action:"EDIT"
      }
    });
    console.log(team);
    if(team.id) {
      setLogoUrl(team.logo);
      setEditLogo(false);
      setOldProfiles(team.profile);
      setEditProfile(false);
      refetch();
      setUploading(false);
      toast.success("수정 완료!");

    }
  }
  
  
  return (
    <TeamProfilePresenter
      loading={loading}
      data={data}
      uploading={uploading}
      oldProfiles={oldProfiles}
      setOldProfiles={setOldProfiles}
      logoUrl={logoUrl}
      setLogoUrl={setLogoUrl}
      handleProfileDelete={handleOldProfileDelete}
      handleRemoveProfileOpen={handleRemoveProfileOpen}
      handleRemoveProfileClose={handleRemoveProfileClose}

      editLogo={editLogo}
      editProfile={editProfile}
      handleEditPhotoOpen={handleEditPhotoOpen}
      onDropProfles={onDropProfles}
      onDropLogo={onDropLogo}
      onRemoveProfile={onRemoveProfile}
      onRemoveLogo={onRemoveLogo}
      handleSubmit={handleSubmit}
      teamNameInput={teamNameInput}
      levelInput={levelInput}
      contactInput={contactInput}
      bioInput={bioInput}
      onChangePlace={onChangePlace}

      addressS={addressS}
      member={member}
      setMember={setMember}

      searchData={searchData}
      search={search}
      searching={searching}
      open={open}
      openRemoveProfile={openRemoveProfile}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}

      handleUserUpdate={handleUserUpdate}
      handleUserDelete={handleUserDelete}
    />
  );
};

export default withRouter(TeamProfileContainer);
