import { gql } from "apollo-boost";

// export const LOG_IN = gql`
//   mutation requestSecret($email: String!) {
//     requestSecret(email: $email)
//   }
// `;

export const CREATE_ADMIN = gql`
  mutation createAdmin(
    $username: String!
    $loginID: String!
    $loginPW: String!
    $authority: String
  ) {
    createAdmin(
      username: $username
      loginID: $loginID
      loginPW: $loginPW
      authority: $authority
    )
  }
`;

export const LOG_IN = gql`
    mutation logIn(
        $loginID: String!
        $loginPW: String!
    ) {
      logIn(
        loginID: $loginID
        loginPW: $loginPW
      )
    }
`;

export const LOCAL_LOG_IN = gql`
    mutation logAdminIn($token: String!) {
      logAdminIn(token :$token) @client
    }
`;

