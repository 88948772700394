import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { SEND_NOTIFICATION, SEE_LOGS, SEARCH_USER, SEARCH_TEAM } from "./SendNotificationQueries";
import CupEditPresenter from "./SendNotificationPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import { SEE_CUPS } from "../CupList/CupListQueries";

const SendNotificationContainer = ({ match: { params }, history }) => {
  const action = useInput("user");

  const title = useInput("");
  const message = useInput("");
  const [userInfo, setUser] = useState();
  const [teamInfo, setTeam] = useState();

  const [uploading, setUploading] = useState(false);

  const { loading, data } = useQuery(SEE_LOGS);

  const sendNotificationMutation = useMutation(SEND_NOTIFICATION, { 
    refetchQueries:[{query:SEE_LOGS}]
  });

  const setTeamInfo = (team) => () => {
    setTeam(team);
    setOpenDialog({...openDialog, team:false});
  }

  const setUserInfo = (user) => () => {
    setUser(user);
    setOpenDialog({...openDialog, user:false});
  }

  // 경기장 검색 관련
  const searchTeamInput = useInput("");
  const searchUserInput = useInput("");

  const [searchingTeam, setSearchingTeam] = useState(false);
  const [searchingUser, setSearchingUser] = useState(false);

  const [openDialog, setOpenDialog] = useState({
    team:false,
    user:false
  });

  const { loading: loadingUser, data: searchUserData } = useQuery(
    SEARCH_USER,
    {
      skip: searchUserInput.value === "" && searchUserInput.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchUserInput.value
      }
    }
  );

  const { loading: loadingTeam, data: searchTeamData } = useQuery(
    SEARCH_TEAM,
    {
      skip: searchTeamInput.value === "" && searchTeamInput.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchTeamInput.value
      }
    }
  );


  // 검색창 오픈 이벤트
  const handleDialogOpen = type => () => {
    setOpenDialog({...openDialog, [type]:true});
  };

  // 검색창 닫기 이벤트
  const handleDialogClose = type => () => {
    setOpenDialog({...openDialog, [type]:false});
  };

  // 경기장 검색 값 변할 경우 리렌더링
  useEffect(() => {
    if (searchTeamInput.value.length > 1) {
      setSearchingTeam(true);
    } else {
      setSearchingTeam(false);
    }

    if (searchUserInput.value.length > 1) {
      setSearchingUser(true);
    } else {
      setSearchingUser(false);
    }

    if (!loadingUser && searchUserData) {
      setSearchingUser(false);
    }

    if (!loadingTeam && searchTeamData) {
      setSearchingTeam(false);
    }

    console.log(searchTeamData)
  }, [searchUserInput.value, searchTeamInput.value, loadingTeam, loadingUser]);



  // 전체 업로드
  const handleSubmit = async () => {
    if (uploading) return false;

    try {
      if (title.value === "") {
        toast.error("알림 제목을 입력해주세요");
      } else if (message.value === "") {
        toast.error("알림 내용을 입력해주세요");
      } else {
        setUploading(true);
        let datas;
        if(action.value === 'team') {
          if(teamInfo) {
            datas = {
              title: title.value,
              message: message.value,
              action:action.value,
              teamId: teamInfo.id
            }
          }
          else {
            toast.error("타겟을 검색해주세요");
            return;
          }
        } else if(action.value === 'user') {
          if(userInfo) {
            datas = {
              title: title.value,
              message: message.value,
              action:action.value,
              userId: userInfo.id
            }
          } else {
            toast.error("타겟을 검색해주세요");
            return;
          }
        } else if(action.value === 'all') {
          datas = {
            title: title.value,
            message: message.value,
            action:action.value
          }
        }

        const {
          data: { createNotification }
        } = await sendNotificationMutation({
          variables: datas
        });

        if (createNotification) {
          setUser(null);
          setTeam(null);
          title.setValue("");
          message.setValue("");
          toast.success("전송 완료!");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("오류가 발생했습니다. 새로고침 후 재시도해주세요.");
    } finally {
      setUploading(false);
    }
  };



  return (
    <CupEditPresenter
      uploading={uploading}
      action={action}
      title={title}
      message={message}

      searchTeamInput={searchTeamInput}
      searchUserInput={searchUserInput}
      loadingUser={loadingUser}
      searchUserData={searchUserData}
      loadingTeam={loadingTeam}
      searchTeamData={searchTeamData}

      searchingTeam={searchingTeam}
      searchingUser={searchingUser}

      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}

      loading={loading}
      data={data}

      handleSubmit={handleSubmit}
      openDialog={openDialog}
      userInfo={userInfo}
      teamInfo={teamInfo}
      setTeamInfo={setTeamInfo}
      setUserInfo={setUserInfo}
      
    />
  );
};

export default withRouter(SendNotificationContainer);
