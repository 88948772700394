import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "rl-react-helmet";
import DropzoneComponent from "react-dropzone-component";
import Loader from "../../Components/Loader";
import Progress from "../../Components/Progress";
import Avatar from "../../Components/Avatar";
import AdminHeader from "../../Components/Header/AdminHeader";
import MultipleSelect from "../../Components/MultiSelect";
import DefaultSelect from "../../Components/Select";
import NativeSelect from '@material-ui/core/NativeSelect';

import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, Button } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";


const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px",
    marginRight:"15px"
  },
  dialog: {},
  appBar: {
    width: "360px",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop: "20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    "& > button": {
      marginLeft: "15px;"
    }
  }
}));

const basketPositions = [
  {
    value: "PG",
    text: "PG"
  },
  {
    value: "SG",
    text: "SG"
  },
  {
    value: "SF",
    text: "SF"
  },
  {
    value: "PF",
    text: "PF"
  },
  {
    value: "C",
    text: "C"
  }
];

const LevelItems = [
  {
    value: 1,
    text: "Lv.1"
  },
  {
    value: 2,
    text: "Lv.2"
  },
  {
    value: 3,
    text: "Lv.3"
  },
  {
    value: 4,
    text: "Lv.4"
  },
  {
    value: 5,
    text: "Lv.5"
  }
];

const GradeItems = [
  {
    value: "S",
    text: "슈퍼스타"
  },
  {
    value: "A",
    text: "올스타"
  },
  {
    value: "B3",
    text: "스타3"
  },
  {
    value: "B2",
    text: "스타2"
  },
  {
    value: "B1",
    text: "스타1"
  },
  {
    value: "C5",
    text: "플레이어5"
  },
  {
    value: "C4",
    text: "플레이어4"
  },
  {
    value: "C3",
    text: "플레이어3"
  },
  {
    value: "C2",
    text: "플레이어2"
  },
  {
    value: "C1",
    text: "플레이어1"
  },
  {
    value: "D",
    text: "D비기너"
  }
];


const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

export default ({
  loading,
  data,
  uploading,
  handleEditPhotoOpen,
  editAvatar,
  avatarUrl,
  setAvatarUrl,
  onDropAvatar,
  onRemoveAvatar,
  handleSubmit,
  usernameInput,
  nicknameInput,
  emailInput,
  contactInput,
  region1Input,
  region2Input,
  pointInput,
  setBasketId,
  positionInput,
  heightInput,
  weightInput,
  backNoInput,
  levelInput,
  gradeInput,
  basketRegion1Input,
  basketRegion2Input,
  authorityInput
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeUser) {
    const classes = useStyles();

    const componentConfig = {
      postUrl: "/"
    };

    const djsConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 1
    };

    const eventHandlers = {
      addedfile: file => onDropAvatar(file),
      removedfile: file => onRemoveAvatar(),
      init: () => {
        if (document.getElementsByClassName("dz-message")[0])
          document.getElementsByClassName("dz-message")[0].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
      }
    };

    const {
      seeUser: {
        avatar,
        contact,
        username,
        nickname,
        email,
        authority,
        regions,
        point,
        basket
      }
    } = data;

    console.log(data);

    useEffect(() => {
      usernameInput.setValue(username);
      nicknameInput.setValue(nickname);
      emailInput.setValue(email);
      if (regions && regions.length > 0) region1Input.setValue(regions[0]);
      if (regions && regions.length > 1) region2Input.setValue(regions[1]);
      pointInput.setValue(point);
      contactInput.setValue(contact);
      setAvatarUrl(avatar);

      if(authority) {
        authorityInput.setValue(authority.code);
      }

      if(basket) {
        const {  
          id: basketId,
          level,
          grade,
          position,
          height,
          weight,
          backNo,
          regions: basketRegions
        } = basket;
        if (basketRegions && basketRegions.length > 0)
          basketRegion1Input.setValue(basketRegions[0]);
        if (basketRegions && basketRegions.length > 1)
          basketRegion2Input.setValue(basketRegions[1]);

        if (level) levelInput.setValue(level);
        if (grade) gradeInput.setValue(grade);
        if (position) positionInput.setValue(position);
        heightInput.setValue(height);
        weightInput.setValue(weight);
        backNoInput.setValue(backNo);

        setBasketId(basketId);
      }
    }, []);



    return (
      <Wrapper>
        <Helmet>
          <title>회원관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              회원 정보 관리
              <Link to={"/users"}>
                <Button
                  variant="contained"
                  color="primary"
              
                >
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      회원 공통 정보
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <FormLabel className={classes.label}>회원권한</FormLabel>
                        <NativeSelect
                          id="authorityInput"
                          value={authorityInput.value}
                          onChange={authorityInput.onChange}
                        >
                          <option value={"2004"}>일반 회원</option>
                          <option value={"2003"}>오픈방 매니저</option>
                          <option value={"2006"}>대회 회원</option>
                        </NativeSelect>
                        {/* <NativeSelect
                          items={AuthorityItems}
                          onChange={authorityInput.onChange}
                          value={authorityInput.value}
                        /> */}
                      </Grid>
                    </Grid>

                    <TextField
                      required
                      id="usernameInput"
                      label="사용자 이름"
                      value={usernameInput.value}
                      onChange={usernameInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                    <TextField
                      required
                      id="nicknameInput"
                      label="닉네임"
                      value={nicknameInput.value ? nicknameInput.value : ""}
                      onChange={nicknameInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                    <TextField
                      required
                      id="emailInput"
                      label="이메일"
                      value={emailInput.value ? emailInput.value : ""}
                      onChange={emailInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      required
                      id="contactInput"
                      label="연락처"
                      value={contactInput.value}
                      onChange={contactInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      required
                      id="pointInput"
                      label="포인트"
                      value={pointInput.value}
                      onChange={pointInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      id="region1Input"
                      label="활동지역(1순위)"
                      value={region1Input.value ? region1Input.value : ""}
                      onChange={region1Input.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      id="region2Input"
                      label="활동지역(2순위)"
                      value={region2Input.value ? region2Input.value : ""}
                      onChange={region2Input.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <DropZoneWrapper>
                      <FormLabel className={classes.editLabel}>
                        프로필 사진
                        <Button
                          variant="contained"
                          onClick={handleEditPhotoOpen("logo")}
                        >
                          사진 변경
                        </Button>
                      </FormLabel>

                      {avatarUrl && (
                        <GridList className={classes.gridList} cols={2}>
                          <GridListTile>
                            <Avatar
                              size={"lg"}
                              url={avatarUrl}
                              alt={`위플레이 | ${username}`}
                            />
                          </GridListTile>
                        </GridList>
                      )}

                      {editAvatar && (
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlers}
                          djsConfig={djsConfig}
                        />
                      )}
                    </DropZoneWrapper>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h5"
                      className={classes.headerText}
                      gutterBottom
                    >
                      세부 정보(농구)
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="heightInput"
                          label="신장"
                          value={heightInput.value}
                          onChange={heightInput.onChange}
                          className={classes.textField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">cm</InputAdornment>
                            )
                          }}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="weightInput"
                          label="체중"
                          value={weightInput.value}
                          onChange={weightInput.onChange}
                          className={classes.textField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">kg</InputAdornment>
                            )
                          }}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          required
                          id="backNoInput"
                          label="등번호"
                          value={backNoInput.value}
                          onChange={backNoInput.onChange}
                          className={classes.textField}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <FormLabel className={classes.label}>레벨</FormLabel>
                        <DefaultSelect
                          items={LevelItems}
                          onChange={levelInput.onChange}
                          value={levelInput.value}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <FormLabel className={classes.label}>클래스</FormLabel>
                        <DefaultSelect
                          items={GradeItems}
                          value={gradeInput.value}
                          onChange={gradeInput.onChange}
                        />
                      </Grid>
                    </Grid>

                    <FormLabel className={classes.label}>포지션</FormLabel>
                    <MultipleSelect
                      value={
                        positionInput.value.length > 0
                          ? positionInput.value.split(",")
                          : []
                      }
                      onChange={positionInput.setValue}
                      datas={basketPositions}
                    />

                    <TextField
                      id="basketRegion1Input"
                      label="농구 활동지역(1순위)"
                      value={
                        basketRegion1Input.value
                          ? basketRegion1Input.value
                          : region1Input.value
                      }
                      onChange={basketRegion1Input.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      id="basketRegion2Input"
                      label="농구 활동지역(2순위)"
                      value={
                        basketRegion2Input.value
                          ? basketRegion2Input.value
                          : region2Input.value
                      }
                      onChange={basketRegion2Input.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                  </Paper>
                </Grid>
              </Grid>

              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    );
  }
};
