import React from "react";
import styled, { keyframes } from "styled-components";

const Dot1Move = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(16px, 12px) scale(.45)}
  60% {transform: translate(80px, 60px) scale(.45)}
  80% {transform: translate(80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const Dot2Move = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(-16px, 12px) scale(.45)}
  60% {transform: translate(-80px, 60px) scale(.45)}
  80% {transform: translate(-80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const Dot3Move = keyframes`
  20% {transform: scale(1)}
  45% {transform: translateY(-18px) scale(.45)}
  60% {transform: translateY(-90px) scale(.45)}
  80% {transform: translateY(-90px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const RotateMove = keyframes`
  55% {transform: translate(-50%, -50%) rotate(0deg)}
  80% {transform: translate(-50%, -50%) rotate(360deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
`;

const Index = keyframes`
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
`;

const Container = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url("#goo");
  animation: ${RotateMove} 2s ease-in-out infinite;
`;

const Dot = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  &:first-child {
    background-color: #1ea9e1;
    animation: ${Dot1Move} 2s ease infinite, ${Index} 6s -2s ease infinite;
  }
  &:nth-child(2) {
    background-color: #a6ce39;
    animation: ${Dot2Move} 2s ease infinite, ${Index} 6s -4s ease infinite;
  }
  &:last-child {
    background-color: #4c4d4f;
    animation: ${Dot3Move} 2s ease infinite, ${Index} 6s ease infinite;
  }
`;

const Progress = styled.div`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props=>props.background?"radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8))":"none"};
  }
`;

export default ({ background = true }) => (
  <Progress background={background}>
    <Container>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </Container>
  </Progress>
);
