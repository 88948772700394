import { gql } from "apollo-boost";

export const GET_TEAM = gql`
  query seeFullTeam($id: String!) {
    seeFullTeam(id: $id) {
      id
      teamName
      profile {
        id
        url
      }
      place {
        id
        placeName
        address
      }
      logo 
      location {
        id
        latitude
        longitude
      }
      contact
      bio
      level
      userCount
      host {
        id
        avatar
        username
        email
      }
      managers {
        id
        avatar
        username
        email
      }
      users {
        id
        avatar
        username
        email
      }
    }
  }
`;

export const SEARCH_PLACE = gql`
  query seePlaces($skip:Int, $first:Int, $term:String) {
    seePlaces(skip:$skip, first:$first, term:$term) {
      id
      placeName
      address
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation deleteProfile(
    $id:String!
    ) {
      deleteProfile(
        id:$id
      ) 
    }
`;

export const EDIT_TEAM = gql`
  mutation editTeamByAdmin(
    $id:String!
    $teamName:String
    $level:Int
    $contact:String
    $action:String!
    $bio:String
    $logo:String
    $profiles:[String]
    $placeId:String
    ) {
      editTeamByAdmin(
        id:$id
        teamName:$teamName
        level:$level
        contact:$contact
        action:$action
        bio:$bio
        logo:$logo
        profiles:$profiles
        placeId:$placeId
      ) {
        id
        profile {
          id
          url
        }
        logo
      }
    }
`;


export const EDIT_USER = gql`
  mutation editMemberByAdmin(
    $id:String!
    $oppId:String!
    $oldData:String!
    $newData:String!
    ) {
      editMemberByAdmin(
        id:$id
        oppId:$oppId
        oldData:$oldData
        newData:$newData
      ) 
    }
`;


export const REMOVE_USER = gql`
  mutation removeUser(
    $id:String!
    ) {
      removeUser(
        id:$id
      ) 
    }
`;