import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { CircularProgress } from "@material-ui/core";

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    height: "100px"
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
}));

const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
  padding: 0px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
  /* padding-bottom:100px; */
`;

const LoaderWapeer = styled.div`
  width: 100%;
  min-height: 170px;
  background-color: #fff;
  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 30px;
  }
`;


export default function MaterialTableDemo({
  loading,
  data,
  matches,
  onCreatePage,
  onUpdatePage,
  handleDelete,
  onScroll,
  places,
  loadMore,
  setPlaces,
  canMore,
  setCanMore,
  searchInput
}) {
    const classes = useStyles();
    useEffect(() => {
      if(!loading && data && data.seePlaces) {
        setPlaces({ ...places, data: data.seePlaces });
        setCanMore(data.seePlaces.length >= 10);
      }
    }, []);

    const superAdminEditable = {
      onRowDelete: oldData => handleDelete(oldData)
    };

    return (
      <Wrapper>
        <Helmet>
          <title>경기장관리 | WePlay</title>
        </Helmet>
        {loading && <Loader background={false}/> }
        <AdminHeader searchInput={searchInput} />
        <TableWrapper onScroll={e => onScroll(e)}>
        <MaterialTable
          title="경기장 테이블"
          columns={places.columns}
          data={places.data}
          className={classes.table}
          style={{
            margin: "0 auto",
            minHeight: "calc(100vh - 234px)",
            position: "unset",
            boxShadow: "none"
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: '수정',
              onClick: (event, rowData) => {
                console.log(rowData);
                //handleEditDialog(rowData);
                onUpdatePage(rowData.id)
              }
            }
          ]}
          options={{
            addRowPosition:"first",
            actionsColumnIndex: -1,
            detailPanelType: "single",
            draggable: false,
            sorting: false,
            search:false,
            paging:false
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "총 {count}개 중 {from}-{to} "
            },
            toolbar: {
              nRowsSelected: "{0}개 선택",
              searchPlaceholder: "경기장검색"
            },
            header: {
              actions: ""
            },
            body: {
              emptyDataSourceMessage: "",
              filterRow: {
                filterTooltip: "필터"
              },
              editRow: {
                deleteText: "정말로 삭제하시겠습니까?"
              }
            }
          }}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <Button
                  variant="contained"
                  color="default"
                  onClick={onCreatePage}
                  className={classes.button}
                >
                  경기장 등록
                  <AddIcon className={classes.rightIcon} />
                </Button>
              </div>
            )
          }}
          //onSearchChange={props=> setSearch}
          editable={matches?null:superAdminEditable}
        />
        <LoaderWapeer>{canMore && loadMore && <CircularProgress />}</LoaderWapeer>
        </TableWrapper>
        

      

      </Wrapper>
    );
}


