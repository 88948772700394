import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { GET_TEAM, EDIT_TEAM, DELETE_PROFILE, REMOVE_USER, UPSERT_BASKET, CREATE_CUP_USER, EDIT_USER } from "./CupEditTeamQueries";
import CreateCupTeamPresenter from "./CupEditTeamPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";
import { SEE_TEAMS } from "../CupTeamList/CupTeamListQueries";

const CupEditTeamContainer = ({ match: { params } , history }) => {
  const cupId = params.cup;
  const teamId = params.team;
  const { data, loading, refetch } = useQuery(GET_TEAM, {
    variables: {
      id: teamId.toString(),
      cupId
    }
  });

  const [logo, setLogo] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [profiles, setProfiles] = useState([]);
  const [oldProfiles, setOldProfiles] = useState([]);

  const sportInput = useInput("1001");
  const teamNameInput = useInput("");
  const agentNameInput = useInput("");
  const contactInput = useInput("", newValue => !isNaN(newValue));
  const bioInput = useInput("");

  const positionInput = useInput([]);

  const email = useInput("");
  const username = useInput("");
  const contact = useInput("", newValue => !isNaN(newValue));
  const backNo =  useInput("", newValue => !isNaN(newValue));
  const age = useInput("", newValue => !isNaN(newValue));
  const height = useInput("", newValue => !isNaN(newValue));
  const weight = useInput("", newValue => !isNaN(newValue));
  const position = useInput("");

  const [IsDialogHost, setIsDialogHost] = useState(false);
  const [host, setHost] = useState();
  const [users, setUsers] = useState([]);

  const [editLogo, setEditLogo] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemoveProfile, setOpenRemoveProfile] = useState(false);
  const [removeProfile, setRemoveProfile] = useState();

  const editTeamMutation = useMutation(EDIT_TEAM, {
    refetchQueries: [
      { query: SEE_TEAMS, variables: { cupId } },
      { query: GET_TEAM, variables: { id: teamId.toString(), cupId } },
    ],
  });
  const deleteProfileMutation = useMutation(DELETE_PROFILE);
  const deleteUserMutation = useMutation(REMOVE_USER);
  const upsertBasketMutation = useMutation(UPSERT_BASKET, {
    refetchQueries:[ { query: GET_TEAM, variables: { id: teamId.toString(), cupId } } ]
  });
  const editUserMutation = useMutation(EDIT_USER);

  const createUserMutation = useMutation(CREATE_CUP_USER);
  
  
  const onUserAdd = async () => {

    if(email.value === "") {
      email.setValue(contact.value);
      toast.info(<div>이메일이 없을 경우 <br/>이메일 대신 연락처가 등록됩니다.</div>);
    }
    
    if(
      username.value === "" ||
      backNo === "" ||
      height.value === "" ||
      weight.value === "" ||
      position.value === ""
    ) {
      toast.error(<div>모든 정보를 입력해주세요.</div>);
      return;
    }

    try {
      setUploading(true);
      const { data : { createCupUser }} = await createUserMutation({
        variables: {
          teamId: teamId,
          email: email.value,
          contact: contact.value,
          username: username.value,
          backNo: parseInt(backNo.value),
          age:age.value,
          height:parseInt(height.value),
          weight:parseInt(weight.value),
          position:position.value,
          authority:IsDialogHost?"2005":"2006"
        }
      });

      console.log(createCupUser);

      if(createCupUser.id) {
        toast.success("추가되었습니다.");

        if(IsDialogHost) {
          let newUser = createCupUser;
          newUser.thisUserStat = {
            score: 0,
            rebound: 0,
            assist: 0,
            blocking: 0,
            steal: 0,
            turnOver: 0,
            foul: 0,
            oneScore:0,
            twoScore:0,
            threeScore: 0,
            freeShot: 0,
            freeShotTotal: 0
          };
          setHost(newUser);
        }
        else {
          const _users = [...users];

          let newUser = createCupUser;
          newUser.thisUserStat = {
            score: 0,
            rebound: 0,
            assist: 0,
            blocking: 0,
            steal: 0,
            turnOver: 0,
            foul: 0,
            oneScore:0,
            twoScore:0,
            threeScore: 0,
            freeShot: 0,
            freeShotTotal: 0
          };
          
          _users.push(newUser);
          setUsers(_users);
        }
      }
    }
    catch(e) {
      console.log(e);
      if(e.toString().indexOf("already") !== -1) {
        toast.error("해당 이메일은 이미 가입되어 있습니다.");
      } else {
        toast.error("올바른 정보를 입력해주세요.");
      }
    } finally {
      email.setValue("");
      username.setValue("");
      backNo.setValue("");
      contact.setValue("");
      age.setValue("");
      height.setValue("");
      weight.setValue("");
      position.setValue("");
      handleDialogClose();
      setUploading(false);
    }
  }

  // 호스트 선택 시 변경값 설정
  const onChangeHost = (id,avatar,username,email) => {
    setHost({
      id,
      avatar,
      username,
      email
    });
  };


  const handleEditPhotoOpen = type => () => {
    if (type === "logo") setEditLogo(!editLogo);
    else if (type === "profile") setEditProfile(!editProfile);
  };

  // 프로필 사진 드롭 시 이벤트
  const onDropProfles = acceptedFiles => {
    const data = profiles;
    data.push(acceptedFiles);
    setProfiles(data);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveProfile = removeFile => {
    const data = profiles;
    data.splice(data.findIndex(i => i.upload.uuid === removeFile.upload.uuid), 1);
    setProfiles( data );
  }

  // 기존 프로필 삭제 이벤트
  const handleOldProfileDelete = async () => {
    await deleteProfileMutation({
      variables: { id: removeProfile }
    });
    
    const data = oldProfiles;
    data.splice(data.findIndex(i => i.id === removeProfile), 1);
    setOldProfiles(data);
    handleRemoveProfileClose();
  }

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemoveProfileOpen = (id) => () => {
    setOpenRemoveProfile(true);
    setRemoveProfile(id);
  };

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemoveProfileClose = () => {
    setOpenRemoveProfile(false);
    setRemoveProfile(null);
  };

  // 유저 테이블 삭제 이벤트
  const handleUserDelete = async oldData => {
    await deleteUserMutation({
      variables: { id: oldData.id }
    });

    const data = [...users];
    data.splice(data.findIndex(i => i.id === oldData.id), 1);
    setUsers(data);
  }

  // 로고파일 드롭 이벤트
  const onDropLogo = acceptedFile => {
    setLogo(acceptedFile);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveLogo = () => {
    setLogo(null);
  };

 
  // 회원창 오픈 이벤트
  const handleDialogOpen = (type)=> () => {
    if(type === 'host' && host) {
      email.setValue(host.email);
      contact.setValue(host.contact);
      username.setValue(host.username);
      backNo.setValue(host.backNo);
      age.setValue(host.age);
      height.setValue(host.height);
      weight.setValue(host.weight);
      position.setValue(host.position);
    } 
    setIsDialogHost(type === 'host'? true : false);
    setOpenDialog(true);
  };

  // 회원 닫기 이벤트
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdate = async (newData, oldData) => {
    try {
      const {
        data : {
          editUserByAdmin
        }
      } = await editUserMutation({
        variables: {
          id:oldData.id,
          username:newData.username,
          email:oldData.email,
          contact:oldData.contact,
          point:oldData.point
        }
      });


      const {
        data: { upsertBasket }
      } = await upsertBasketMutation({
        variables: {
          id:oldData.basket.id,
          userId:oldData.id,
          position: newData.basket.position,
          height:parseInt(newData.basket.height),
          weight:parseInt(newData.basket.weight),
          backNo:parseInt(newData.basket.backNo),
        }
      });
      console.log(upsertBasket, editUserByAdmin);
      if(upsertBasket.id) {

        if(host && host.id === oldData.id) {
          setHost(newData);
        } else {


          const prevUsers = [...users];
          prevUsers[
            users.findIndex(i => i.id === oldData.id)
          ] = newData;
          // const basketObj = {
          //   id:oldData.basket.id,
          //   userId:oldData.id,
          //   position: newData.basket.position,
          //   height:parseInt(newData.basket.height),
          //   weight:parseInt(newData.basket.weight),
          //   backNo:parseInt(newData.basket.backNo)
          // }
          // prevUsers[
          //   users.findIndex(i => i.id === oldData.id)
          // ].basket = basketObj;
          
          setUsers(prevUsers);
        }
        
      }
    } catch (e) {
      console.log(e);
      toast.error("올바른 정보를 입력해주세요.");
    }
  };


  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes
    console.log({
      cupId,
      sportId:sportInput.value,
      teamName: teamNameInput.value,
      contact: contactInput.value,
      bio: bioInput.value,
      host:JSON.stringify(host),
      users:JSON.stringify(users)
    });

    if(uploading)
      return false;
 
    try {

      if(teamNameInput.value === "") {
        toast.error("팀 이름은 필수값입니다.");
      }
      else {
        setUploading(true);

        let LogoUrl;
        let ProfileUrls;
        if (logo) {
          const formData = new FormData();
          formData.append("logo", logo);

          const {
            data: { location }
          } = await axios.post(
            "https://weplay2921.cafe24.com/api/team/upload",
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          LogoUrl = location;
        } 

        if(profiles && profiles.length > 0) {
          const formData = new FormData();
          profiles.forEach(file => {
            formData.append("profile", file);
          }); 
    
          const { data } = await axios.post("https://weplay2921.cafe24.com/api/team/profile/upload", formData, {
            headers: {
              "content-type": "multipart/form-data"
            }
          });
    
          ProfileUrls = data;     
        }

        const { data : { editTeamByAdmin }} = await editTeamMutation({
          variables: {
            id: teamId,
            agent: agentNameInput.value,
            teamName: teamNameInput.value,
            contact: contactInput.value,
            bio: bioInput.value,
            profiles:ProfileUrls,
            logo:LogoUrl,
            action:"EDIT"
          }
        });

        console.log(editTeamByAdmin);

        if (editTeamByAdmin.id) {
          setLogoUrl(editTeamByAdmin.logo);
          refetch();
          toast.success("수정 완료!");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <CreateCupTeamPresenter
      data={data}
      loading={loading}
      cupId={cupId}
      uploading={uploading}
      logoUrl={logoUrl}
      setLogoUrl={setLogoUrl}
      editLogo={editLogo}
      handleEditPhotoOpen={handleEditPhotoOpen}
      onDropLogo={onDropLogo}
      onRemoveLogo={onRemoveLogo}
      handleSubmit={handleSubmit}
      handleUpdate={handleUpdate}

      positionInput={positionInput}
      sportInput={sportInput}
      teamNameInput={teamNameInput}
      agentNameInput={agentNameInput}
      contactInput={contactInput}
      bioInput={bioInput}

      email={email}
      userContact={contact}
      username={username}
      backNo={backNo}
      age={age}
      height={height}
      weight={weight}
      position={position}
      openDialog={openDialog}
      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}
      host={host}
      users={users}
      setHost={setHost}
      setUsers={setUsers}
      IsDialogHost={IsDialogHost}
      onUserAdd={onUserAdd}
      onChangeHost={onChangeHost}

      handleUserDelete={handleUserDelete}
      onDropProfles={onDropProfles}
      onRemoveProfile={onRemoveProfile}
      openRemoveProfile={openRemoveProfile}
      editProfile={editProfile}
      handleProfileDelete={handleOldProfileDelete}
      handleRemoveProfileOpen={handleRemoveProfileOpen}
      handleRemoveProfileClose={handleRemoveProfileClose}
      oldProfiles={oldProfiles}
      setOldProfiles={setOldProfiles}
    />
  );
};

export default withRouter(CupEditTeamContainer);
