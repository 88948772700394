import { gql } from "apollo-boost";

export const GET_GAME = gql`
  query seeFullGame($id: String!, $basket:Boolean!) {
    seeFullGame(id: $id) {
      id
      homeTeam {
        id
        teamName
        level
        win
        lose
      }
      awayTeam {
        id
        teamName
        level
        win
        lose
      }
      votes {
        id
        user {
          id
          avatar
          username
          basket @include(if: $basket) {
            id
            level
          }
        }
        team {
          id
        }
        answer
      }
      place {
        id
        placeName
        address
        location {
          id
          latitude
          longitude
        }
      }
      result {
        id
        homeTeam
        awayTeam
      }
      playerCnt
      gameStatus
      gameDate
      startTime
      endTime
    }
  }
`;

export const DELETE_PICTURE = gql`
  mutation deletePicture(
    $id:String!
    ) {
      deletePicture(
        id:$id
      ) 
    }
`;

export const EDIT_PLACE = gql`
  mutation editPlaceByAdmin(
    $id:String!
    $manager:String
    $contact:String
    $placeName:String!
    $isAuthenticated:Boolean!
    $isFree:Boolean!
    $pictures:[String]
    ) {
      editPlaceByAdmin(
        id:$id
        manager:$manager
        contact:$contact
        placeName:$placeName
        isAuthenticated:$isAuthenticated
        isFree:$isFree
        pictures:$pictures
      ) {
        id
        picture {
          id
          url
        }
      }
    }
`;



export const EDIT_BASKET_PLACE = gql`
  mutation editBasketPlace(
    $placeId:String!
    $size:String
    $floorMaterial:String
    $groundLine:Boolean!
    $groundLight:Boolean!
    $net:Boolean!
    $showerRoom:Boolean!
    $parkingLot:Boolean!
    ) {
      editBasketPlace(
        placeId:$placeId
        size:$size
        floorMaterial:$floorMaterial
        groundLine:$groundLine
        groundLight:$groundLight
        net:$net
        showerRoom:$showerRoom
        parkingLot:$parkingLot
      ) {
        id
        size
        floorMaterial
        groundLine
        groundLight
        net
        showerRoom
        parkingLot
      }
    }
`;

export const EDIT_USER = gql`
  mutation editMemberByAdmin(
    $id:String!
    $oppId:String!
    $oldData:String!
    $newData:String!
    ) {
      editMemberByAdmin(
        id:$id
        oppId:$oppId
        oldData:$oldData
        newData:$newData
      ) 
    }
`;


export const REMOVE_USER = gql`
  mutation removeUser(
    $id:String!
    ) {
      removeUser(
        id:$id
      ) 
    }
`;