import { gql } from "apollo-boost";

export const UPSERT_USER_STAT = gql`
  mutation upsertUserStat(
    $cupId: String
    $userId: String!
    $gameId: String!
    $score: Int
    $rebound: Int
    $assist: Int
    $blocking: Int
    $steal: Int
    $turnOver: Int
    $foul: Int
    $oneScore: Int
    $twoScore:Int
    $threeScore: Int
    $freeShot:Int
    $freeShotTotal:Int
  ) {
    upsertUserStat(
      cupId: $cupId
      userId: $userId
      gameId: $gameId
      score: $score
      rebound: $rebound
      assist: $assist
      blocking: $blocking
      steal: $steal
      turnOver: $turnOver
      foul: $foul
      oneScore:$oneScore
      twoScore:$twoScore
      threeScore:$threeScore
      freeShot:$freeShot
      freeShotTotal:$freeShotTotal
    ) {
      id
      user {
        id
      }
      game {
        id
      }
      score
      rebound
      assist
      blocking
      steal
      turnOver
      foul
      oneScore
      twoScore
      threeScore
      freeShot
      freeShotTotal
    }
  }
`;

export const EDIT_GAME = gql`
  mutation editGame(
    $id: String!
    $gameResultId:String
    $homeId:String
    $awayId:String
    $action: String!
    $homeScore: [Int!]
    $awayScore: [Int!]
    $homeTeamFoul:Int
    $awayTeamFoul:Int
    $playTime:String
  ) {
    editGame(
      id: $id
      gameResultId:$gameResultId
      homeId:$homeId
      awayId:$awayId
      action: $action
      homeScore: $homeScore
      awayScore: $awayScore
      homeTeamFoul: $homeTeamFoul
      awayTeamFoul: $awayTeamFoul
      playTime: $playTime
    ) {
      id
      gameName
      result {
        id
        homeTeam
        awayTeam
      }
      homeTeam {
        id
      }
      awayTeam {
        id
      }
    }
  }
`;

export const GET_GAME = gql`
  query seeFullGame($id: String!) {
    seeFullGame(id: $id) {
      id
      playerCnt
      gameName
      result {
        id
        homeTeam
        awayTeam
        homeTeamFoul
        awayTeamFoul
        playingTime
      }
      homeTeam {
        id
        teamName
        logo
        host {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
        users {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
      }
      awayTeam {
        id
        teamName

        logo
        host {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
        users {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
      }
    }
  }
`;
