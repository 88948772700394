import { gql } from "apollo-boost";

export const GET_USER = gql`
  query seeUser($id: String!) {
    seeUser(id: $id) {
      id
      avatar
      authority {
        id
        code
      }
      contact
      username
      nickname    
      email
      regions
      point
      basket {
        id
        level
        grade
        position
        height
        weight
        backNo
        regions
      }
    }
  }
`;

export const SEARCH_PLACE = gql`
  query seePlaces($skip:Int, $first:Int, $term:String) {
    seePlaces(skip:$skip, first:$first, term:$term) {
      id
      placeName
      address
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation deleteProfile(
    $id:String!
    ) {
      deleteProfile(
        id:$id
      ) 
    }
`;

export const EDIT_USER = gql`
  mutation editUserByAdmin(
    $id:String!
    $avatar:String
    $authorityCode:String
    $username:String!
    $nickname:String
    $email:String!
    $contact:String!
    $point:Int!
    $regions:[String]
    ) {
      editUserByAdmin(
        id:$id
        avatar:$avatar
        authorityCode:$authorityCode
        username:$username
        nickname:$nickname
        email:$email
        point:$point
        contact:$contact
        regions:$regions
      ) {
        id
        avatar
      }
    }
`;


export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id:String!
    $userId:String!
    $position:String
    $height:Int
    $weight:Int
    $backNo:Int
    $level:Int
    $grade:String
    $regions:[String]
    ) {
      upsertBasket(
        id:$id
        userId:$userId
        position:$position
        height:$height
        weight:$weight
        backNo:$backNo
        level:$level
        grade:$grade
        regions:$regions
      ) {
        id
      }
    }
`;
