import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";

import AdminHeader from "../../Components/Header/AdminHeader";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from '@material-ui/core/Dialog';
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Progress from "../../Components/Progress";
import Loader from "../../Components/Loader";
import BannerForm from "../../Components/BannerForm";

const Wrapper = styled.div``;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)",
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000",
    "& > p": {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      color: "#ff0000",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  title: {
    color: theme.palette.primary.light,
  },
}));


const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 80px 0 40px;
`;

const ListSection = styled(Section)`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 0px 0px 60px;
`;

const Title = styled.h4`
  font-size: 24px;
  margin-bottom: 15px;
`;

const BannerListUl = styled.ul`

`;

const DialogContent = styled.div`
  padding: 15px 24px;
  font-size: 20px;
  text-align: center;
  & > span {
    font-weight:900;
  }
`;

const DialogButtons = styled.div`
  padding: 8px 24px;
    display: flex;
    justify-content: center;
`;

const DButton = styled(Button)`
  &:first-child > span {
    color:red;
  }
`;
const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

export default ({
  loading,
  data,
  uploading,
  banners,
  setBanners,
  onAddBanner,
  onRemoveBanner,
  handleSubmit,
  onDropFiles,
  handleRemovePopupOpen,
  handleRemovePopupClose,
  openRemoveDialog,
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <Helmet>
          <title>메인 배너관리 | WePlay</title>
        </Helmet>
      </Wrapper>
    );
  } else if (!loading && data && data.seeBanners) {

    useEffect(() => {
      console.log("useEffect:", data.seeBanners);
      if(data.seeBanners && data.seeBanners.length > 0) {
        const _initData = [];
        data.seeBanners.forEach(banner => {
          _initData.push({
            id:banner.id,
            file:banner.url,
            url:banner.url,
            link:banner.link
          });
        });
        setBanners(_initData);
      }
    }, [data]);

    console.log(banners);
    
    return (
      <Wrapper>
        <Helmet>
          <title>메인 배너관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}

        <Section>
          <Title> 메인 배너관리</Title>
          <Button variant="contained" color="primary" onClick={onAddBanner}>
            배너 추가
            <AddIcon />
          </Button>
        </Section>

        <ListSection>
          <BannerListUl>
            {banners &&
              banners.map((_, idx) => (
                <BannerForm
                  key={idx}
                  index={idx}
                  banners={banners}
                  setBanners={setBanners}
                  handleRemovePopupOpen={handleRemovePopupOpen}
                  onDropFiles={onDropFiles}
                />

                // <BannerList key={idx}>
                //   <BannerCard>
                //     <Button variant="contained" color="secondary" onClick={handleRemovePopupOpen(idx)}>
                //       삭제
                //     </Button>
                //     <DropZoneWrapper>
                //       <ImagesUploader
                //         withPreview={true}
                //         imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                //         onChange={(picture) => onDropFiles(idx, picture)}
                //         label="이미지를 업로드하세요."
                //         buttonText="사진 선택"
                //         singleImage={true}
                //         maxFileSize={524288000}
                //       />
                //     </DropZoneWrapper>

                //     <InputInfoColumn>
                //       <BoldText text={"연결 url"} />
                //       <LabelHint>*</LabelHint>
                //     </InputInfoColumn>
                //     <InputInfoColumn>
                //       <TextField value={banner.link.value} onChange={banner.link.onChange} />
                //     </InputInfoColumn>

                //   </BannerCard>
                // </BannerList>
              ))}
          </BannerListUl>
          <Grid container justify="center" spacing={2}>
            <UploadButton1 onClick={handleSubmit}>저장하기</UploadButton1>
          </Grid>
        </ListSection>

        <Dialog
          open={openRemoveDialog}
          onClose={handleRemovePopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            해당 배너를 삭제하시겠습니까?
          </DialogContent>
          <DialogButtons>
            <DButton onClick={onRemoveBanner}>삭제</DButton>
            <DButton onClick={handleRemovePopupClose}>취소</DButton>
          </DialogButtons>
        </Dialog>
      </Wrapper>
    );
  }
};
