import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import Select from "../../Components/Select";
import AdminHeader from "../../Components/Header/AdminHeader";

import { fade, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { TextField, Button, InputAdornment, NativeSelect } from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PostCode from "../../Components/PostCode";
import BoldText from "../../Components/BoldText";

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Chip from '@material-ui/core/Chip';
import Progress from "../../Components/Progress";

const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  userList: {
    border: "1px solid #f0f0f0"
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000",
    "& > p": {
      fontSize:14,
      fontWeight:500,
      marginTop: 10,
      color: "#ff0000"
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  appBar: {
    width: "500px",
    maxWidth: "100%",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop: "20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "20px",
    "& > button": {
      marginLeft: "15px;"
    }
  },
  textField: {
    marginTop: "10px",
    marginBottom: "20px"
  },
  userField: {
    margin: "10px 10px 10px 0px",
    width: "50%"
  },
  joinPriceUnit: {
    width:120,
    marginRight:20
  },
  textRight: {
    "& input" : {
      textAlign:"right!important"
    }
  }
}));



const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;


const LabelHint = styled.span`
  font-size:13px;
  font-weight:500;
  padding-left:7px;
  margin-right:10px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding: 15px 0px;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

const GroupChips = styled.div`
  margin:10px 0px;
  & > .MuiChip-outlinedPrimary {
    font-size: 16px;
    height: 32px;
    margin-right: 7px;
    margin-bottom:7px;
  }
`;

const HalfColumn = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  uploading,
  editLogo,
  handleEditPhotoOpen,
  handleSubmit,
  addressS,
  AddGroup,
  DeleteGroup,
  onDropPicture,
  onRemovePicture,
  cupName,
  target,
  cupProcess,
  playerCnt,
  cupStartDate,
  cupEndDate,
  cupDateInfo,
  host,
  organizer,
  support,
  sponsor,
  group,
  url,
  youtubeUrl,
  joinPriceUnit,
  joinPrice,
  contact,
  groupInput,
  DepositAccount,
  placeNameInput,
  setAddressInfo,
  onChangePlace,
  searchPlaceData,
  searchPlace,
  searchingPlace,
  openPlaceDialog,
  handlePlaceDialogOpen,
  handlePlaceDialogClose,
}) => {

  const classes = useStyles();

  const componentConfig = {
    postUrl: "/"
  };

  const djsProfileConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif"
  };

  const eventHandlersHost = {
    addedfile: file => {
      console.log(file);
      onDropPicture('host',file)
    },
    removedfile: file => onRemovePicture('host',file),
    init: () => {
      if(document.getElementsByClassName("dz-message").length > 0)
      {
        for (let item of document.getElementsByClassName("dz-message")) {
          item.innerHTML="업로드하려면 클릭하거나 파일을 올려주세요.";
        }
      }
    }
  };

  const eventHandlersSponsor = {
    addedfile: file => {
      console.log(file);
      onDropPicture('sponsor',file)
    },
    removedfile: file => onRemovePicture('sponsor',file),
    init: () => {
      if(document.getElementsByClassName("dz-message").length > 0)
      {
        for (let item of document.getElementsByClassName("dz-message")) {
          item.innerHTML="업로드하려면 클릭하거나 파일을 올려주세요.";
        }
      }
    }
  };

  const eventHandlersSupport = {
    addedfile: file => {
      console.log(file);
      onDropPicture('support',file)
    },
    removedfile: file => onRemovePicture('support',file),

    init: () => {
      if(document.getElementsByClassName("dz-message").length > 0)
      {
        for (let item of document.getElementsByClassName("dz-message")) {
          item.innerHTML="업로드하려면 클릭하거나 파일을 올려주세요.";
        }
      }
    }
  };

  const eventHandlersOrganizer = {
    addedfile: file => {
      console.log(file);
      onDropPicture('organizer',file)
    },
    removedfile: file => onRemovePicture('organizer',file),
    init: () => {
      if(document.getElementsByClassName("dz-message").length > 0)
      {
        for (let item of document.getElementsByClassName("dz-message")) {
          item.innerHTML="업로드하려면 클릭하거나 파일을 올려주세요.";
        }
      }
    }
  };

  useEffect(() => {
    //setEditorState(EditorState.createEmpty());
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>대회생성 | WePlay</title>
      </Helmet>
      <AdminHeader />
      {uploading && <Progress />} 
      <Container className={classes.container}>
        <Grid item>
          <Typography variant="h3" className={classes.headerText} gutterBottom>
            대회 생성
            <Link to={`/cups`}>
              <Button variant="contained" color="primary">
                목록보기
              </Button>
            </Link>
          </Typography>
        </Grid>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headerText}
                    gutterBottom
                  >
                    대회 정보
                  </Typography>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"대회 이름"} />
                      <LabelHint>*</LabelHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        autoFocus
                        required
                        rowsMax="4"
                        value={cupName.value}
                        onChange={cupName.onChange}
                        margin="dense"
                        id="name"
                        type="email"
                        fullWidth
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"참가대상"} />
                      <LabelHint>*</LabelHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        required
                        fullWidth
                        rowsMax="4"
                        id="target"
                        value={target.value}
                        onChange={target.onChange}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  {/* <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"대회 그룹"} />
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        required
                        fullWidth
                        rowsMax="4"
                        id="group"
                        placeholder={"그룹이 필요하다면 입력해주세요."}
                        value={groupInput.value}
                        onChange={groupInput.onChange}
                      />
                      <GroupChips>
                        {group.length > 0 && group.map(g => (
                          <Chip
                            key={g}
                            variant="outlined"
                            size="small"
                            label={g}
                            onDelete={DeleteGroup(g)}
                            color="primary"
                          />
                        ))}
                      </GroupChips>
                      <Button variant="contained" color="primary" onClick={AddGroup}>
                        그룹 추가
                        <AddIcon />
                      </Button>
                    </InputInfoColumn>
                  </UserInfoRow> */}

            
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"리그 진행 방식"} />
                      <LabelHint>*</LabelHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        id="cupProcess"
                        multiline
                        fullWidth
                        rowsMax="4"
                        value={cupProcess.value}
                        onChange={cupProcess.onChange}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"플레이어 수"} />
                      <LabelHint>*</LabelHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <Select
                        items={[
                          { text: "3 vs 3", value: 3 },
                          { text: "5 vs 5", value: 5 }
                        ]}
                        value={playerCnt.value}
                        onChange={playerCnt.onChange}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"대회 일정"} />
                      <LabelHint>*</LabelHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        id="cupDateInfo"
                        multiline
                        fullWidth
                        rowsMax="4"
                        placeholder="일정 관련 비고(예> 예선 xx시 / 결선 xx시 등)"
                        value={cupDateInfo.value}
                        onChange={cupDateInfo.onChange}
                      />
                    

                      <TextField
                        id="datetime-local"
                        label="시작 일정"
                        type="datetime-local"
                        value={cupStartDate.value}
                        onChange={cupStartDate.onChange}
                        className={classes.textField}
                        inputProps={{
                          step: 600, // 5 min
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="datetime-local"
                        label="종료 일정"
                        type="datetime-local"
                        value={cupEndDate.value}
                        onChange={cupEndDate.onChange}
                        className={classes.textField}
                        inputProps={{
                          step: 600, // 5 min
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"대회 주소"} />
                      <LabelHint>*</LabelHint>
                      <Button
                        variant="contained"
                        onClick={handlePlaceDialogOpen}
                      >
                        기존 경기장 검색
                      </Button>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        required
                        id="placeNameInput"
                        label="장소명"
                        value={placeNameInput.value}
                        onChange={placeNameInput.onChange}
                        className={classes.textField}
                        margin="normal"
                      />
                      <PostCode
                        setAddressInfo={setAddressInfo}
                        text={"주소 검색"}
                        search={true}
                        defaultValue={addressS}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                 
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headerText}
                    gutterBottom
                  >
                    기타 정보
                    <p>※아래 내용들은 입력 하지 않을 시 표시되지 않습니다.</p>
                  </Typography>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"유튜브 링크 URL(http:// 또는 https:// 포함)"} />
                      
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        id="youtubeUrl"
                        fullWidth
                        value={youtubeUrl.value}
                        onChange={youtubeUrl.onChange}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"참가비"} />
                      
                    </InputInfoColumn>
                    <HalfColumn>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={joinPriceUnit.value}
                      onChange={joinPriceUnit.onChange}
                      input={<BootstrapInput />}
                    >
                      <option value={"인당 "}>인당</option>
                      <option value={"팀당 "}>팀당</option>
                    </NativeSelect>
                        <TextField
                          id="standard-required"
                          value={joinPrice.value}
                          onChange={joinPrice.onChange}
                          className={classes.textRight}
                          InputProps={{
                            style: { textAlign: "right" },
                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                          }}
                        />          
                    </HalfColumn>
                  </UserInfoRow>

                 

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"주최"} />

                    </InputInfoColumn>
                    <InputInfoColumn>
                      <DropZoneWrapper>
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlersHost}
                          djsConfig={djsProfileConfig}
                        />
                      </DropZoneWrapper>
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"주관"} />
  
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <DropZoneWrapper>
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlersOrganizer}
                          djsConfig={djsProfileConfig}
                        />
                      </DropZoneWrapper>
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"후원"} />

                    </InputInfoColumn>
                    <InputInfoColumn>
                      <DropZoneWrapper>
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlersSupport}
                          djsConfig={djsProfileConfig}
                        />
                      </DropZoneWrapper>
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"협찬"} />

                    </InputInfoColumn>
                    <InputInfoColumn>
                      <DropZoneWrapper>
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlersSponsor}
                          djsConfig={djsProfileConfig}
                        />
                      </DropZoneWrapper>
                    </InputInfoColumn>
                  </UserInfoRow>
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"문의전화 정보"} />

                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        id="contact"
                        multiline
                        rows="4"
                        value={contact.value}
                        onChange={contact.onChange}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>

                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"입금 정보"} />
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <TextField
                        id="deposit"
                        multiline
                        rows="8"
                        value={DepositAccount.value}
                        onChange={DepositAccount.onChange}
                      />
                    </InputInfoColumn>
                  </UserInfoRow>
                </Paper>
              </Grid>

            </Grid>



            <Grid container justify="center" spacing={2}>
              <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        className={classes.dialog}
        open={openPlaceDialog}
        onClose={handlePlaceDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlePlaceDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="경기장 이름/주소로 검색해주세요."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchPlace.value}
                onChange={searchPlace.onChange}
              />
            </div>
          </Toolbar>
        </AppBar>
        <Container className={classes.searchContainer}>
          <List dense>
            {searchingPlace && <CircularProgress />}
            {searchPlaceData &&
              searchPlaceData.seePlaces &&
              searchPlaceData.seePlaces.length > 0 &&
              searchPlaceData.seePlaces.map(place => (
                <ListItem
                  button
                  key={place.id}
                  onClick={onChangePlace(place)}
                >
                  <ListItemText
                    primary={place.placeName}
                    secondary={place.address}
                  />
                </ListItem>
              ))}
          </List>
        </Container>
      </Dialog>
    </Wrapper>
  );
};



const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);