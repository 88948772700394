import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
//import Button from "../../Components/AdminButton";

import MaterialTable, { MTableToolbar } from "material-table";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CupHeader from "../../Components/CupHeader";

const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  overflow-x: scroll; */
 
  min-height: calc(100vh - 76px);
`;

const ContentWrapper = styled.div`
  max-width:1024px;
  margin:0 auto;
  min-height: calc(100vh - 64px);;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h6`
  font-size: 1.25rem;
  font-family: "NanumSqure";
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  & > span {
    font-weight:800;
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  container: {},
  more:{
    backgroundColor:"#fff",
    paddingBottom:100
  },
  paper: {
    height: 100,
    width: 100
  }
}));


export default function MaterialTableDemo({
  loading,
  data,
  cupId,
  handleDelete,
  teams,
  setTeams,
  onCreatePage,
  onUpdatePage,
  loadMore,
  matches,
  onChangePage
}) {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>팀관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <CupHeader id={cupId} active={"teams"} />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCupTeams) {
    const classes = useStyles();

    useEffect(() => {
      const teamData = data.seeCupTeams;
      setTeams({ ...teams, data:teamData });
    }, [])


    //console.log(data);

    const superAdminEditable = {
      onRowDelete: oldData =>
        new Promise(resolve => {
          setTimeout(() => {
            console.log(oldData);
            resolve();
            handleDelete(oldData);
            const data = [...teams.data];
            data.splice(data.findIndex(i => i.id === oldData.id), 1);
            setTeams({ ...teams, data });
          }, 600);
        })
    };

    return (
      <Wrapper>
        <Helmet>
          <title>대회 팀관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <CupHeader id={data.seeCup.id} active={"teams"} cupName={data.seeCup.cupName} />
        <ContentWrapper>
        <MaterialTable
          title={<Title><span>{data.seeCup.cupName}</span> {'팀관리'}</Title>}
          style={{minHeight:"calc(100vh - 156px)", boxShadow:"none", height:"100%", flex:1}}
          columns={teams.columns}
          data={teams.data}
          options={{
            search: false,
            paging: false,
            draggable:false,
            actionsColumnIndex: -1,
            detailPanelType: "single"
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: '수정',
              onClick: (event, rowData) => {
                console.log(rowData);
                onUpdatePage(rowData.id)
              }
            }
          ]}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <Button
                  variant="contained"
                  color="default"
                  onClick={onCreatePage}
                  className={classes.button}
                >
                  대회팀 생성
                  <AddIcon className={classes.rightIcon} />
                </Button>
              </div>
            )
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "총 {count}개 중 {from}-{to} "
            },
            toolbar: {
              nRowsSelected: "{0}개 선택",
              searchPlaceholder: "팀검색"
            },
            header: {
              actions: ""
            },
            body: {
              emptyDataSourceMessage: "참가팀이 없습니다.",
              filterRow: {
                filterTooltip: "필터"
              },
              editRow: {
                deleteText: "정말로 삭제하시겠습니까?"
              }
            }
          }}
          editable={matches ? null : superAdminEditable}
        />
        <Grid
          container
          className={classes.more}
          alignItems="center"
          justify="center"
        >

        </Grid>
        </ContentWrapper>
        
      </Wrapper>
    );
  }
  else {
    return (
      <Wrapper>
        <Helmet>
          <title>회원관리 | WePlay</title>
        </Helmet>
        <AdminHeader />
      </Wrapper>
    )
  }
  
}
