import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Round = styled.span`
  position: relative;
`;

const Input = styled.input`
  visibility: hidden;
  &:checked + label {
    background-color: ${props => props.theme.yellowColor};
    border-color: ${props => props.theme.yellowColor};
  }
  &:checked + label:after {
    opacity: 1;
  }
`;

const Label = styled.label`
  background-color: #d5d5d5;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  left: 0;
  position: absolute;
  /* top: 4px; */
  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 4px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 8px;
  }
`;

const RoundCheckBox = ({ checked = true, disabled = false, onChange }) => (
  <Round>
    <Input
      id="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
    />
    <Label />
  </Round>
);

RoundCheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RoundCheckBox;
