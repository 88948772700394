import {gql} from "apollo-boost";

export const SEARCH = gql`
  query search($term:String!) {
    searchTeam(term:$term) {
      id
      logo
      teamName
      level
      userCount
      profile {
        id
        url
      }
      place {
        id
        shortAddress
      }
    }
    searchUser(term:$term) {
      id
      avatar
      username
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($term:String!) {
    searchUser(term:$term) {
      avatar
      username
      fullName
      isFollowing
      itsMe
    }
  }
`;