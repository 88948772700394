import React from "react";
import { withRouter } from "react-router-dom";
import {
  GET_GAME
} from "./DetailGameQueries";
import DetailGamePresenter from "./DetailGamePresenter";
import { useQuery } from "react-apollo-hooks";
import "react-toastify/dist/ReactToastify.css";


const DetailGameContainer = ({ match: { params } }) => {
  const gameId = params.game;
  const sportId = params.code;

  const { data, loading } = useQuery(GET_GAME, {
    variables: {
      id: gameId.toString(),
      basket: "1001" === sportId
    }
  });

  return (
    <DetailGamePresenter
      loading={loading}
      data={data}
    />
  );
};

export default withRouter(DetailGameContainer);
