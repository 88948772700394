import { gql } from "apollo-boost";

export const SEE_PLACES = gql`
  query seePlaces($first: Int!, $skip: Int!, $term:String) {
    seePlaces(first: $first, skip: $skip, term:$term) {
      id
      placeName
      address
      isAuthenticated
      manager
      contact
    }
  }
`;

export const SEARCH_PLACE = gql`
  mutation searchPlace($term: String!) {
    searchPlace(term: $term) {
      id
      placeName
      address
      isAuthenticated
      manager
      contact
    }
  }
`;


export const DELETE_PLACE = gql`
  mutation deletePlace($id: String!) {
    deletePlace(id: $id)
  }
`;
