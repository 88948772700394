import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_CUPS,
  DELETE_CUP,
  TOGGLE_CUP
} from "./CupListQueries";
import CupListPresenter from "./CupListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




export default withRouter(({ history }) => {
  const [selectedCup, setCup] = useState();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { data, loading } = useQuery(SEE_CUPS, {
    variables : {
      isAll:true
    }
  });
  const deleteCupMutation = useMutation(DELETE_CUP, {
    refetchQueries:[{query:SEE_CUPS, variables:{ isAll: true }}]
  });
  const toggleActiveMutation = useMutation(TOGGLE_CUP, {
    refetchQueries:[{query:SEE_CUPS, variables:{ isAll: true }}]
  });



  const handleToggleActive = async () => {
    try {
      if(!uploading) {
        setOpen(false);
        setUploading(true);
        const { data : { toggleActive } } = await toggleActiveMutation({
          variables: { id:selectedCup.id }
        });
    
        if(toggleActive) {
          toast.info("숨김 해제되었습니다.");
        } else {
          toast.success("숨김 처리되었습니다.");
        }
      }
    } catch(e) {
      toast.error("오류가 발생했습니다.");
      //toast.info(e.message);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    try {
      if(!uploading) {
        setOpen(false);
        setUploading(true);
        const { data : { deleteCup } } = await deleteCupMutation({
          variables: { id:selectedCup.id }
        });
    
        if(deleteCup.id) {
          toast.success("삭제되었습니다.");
        }
      }
    } catch(e) {
      toast.error("오류가 발생했습니다.");
      //toast.info(e.message);
    } finally {
      setUploading(false);
    }
  };


  const handleClickOpen = cup => () => {
    setCup(cup);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCreatePage = () => {
    history.push(`/cup/create`);
  };

  const onUpdatePage = id => ()=> {
    history.push(`/cup/${id}`);
  };

  return (
    <CupListPresenter
    uploading={uploading}
      loading={loading}
      data={data}
      onCreatePage={onCreatePage}
      onUpdatePage={onUpdatePage}
      handleDelete={handleDelete}
      handleToggleActive={handleToggleActive}
      selectedCup={selectedCup}
      open={open}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
    />
  );
});

