import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import Progress from "../../Components/Progress";
import AdminHeader from "../../Components/Header/AdminHeader";
import PostCode from "../../Components/PostCode";
import Avatar from "../../Components/Avatar";

import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  userList: {
    border: "1px solid #f0f0f0"
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  appBar: {
    width: "500px",
    maxWidth: "100%",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop: "20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    "& > button": {
      marginLeft: "15px;"
    }
  },
  textField: {
    marginBottom:"20px"
  }
}));

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  onDropProfles,
  onDropLogo,
  onRemoveLogo,
  onRemoveProfile,
  handleSubmit,
  sportInput,
  teamNameInput,
  levelInput,
  contactInput,
  bioInput,
  addressS,
  uploading,
  host,
  editLogo,
  editProfile,
  handleEditPhotoOpen,
  onChangePlace,
  onChangeHost,
  searchPlaceData,
  searchUserData,
  searchPlace,
  searchUser,
  searchingPlace,
  searchingUser,
  openPlaceDialog,
  openUserDialog,
  handlePlaceDialogOpen,
  handlePlaceDialogClose,
  handleUserDialogOpen,
  handleUserDialogClose
}) => {
  const classes = useStyles();

  const componentConfig = {
    postUrl: "/"
  };

  const djsProfileConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif",
    maxFiles: 5
  };

  const djsLogoConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif",
    maxFiles: 1
  };

  const eventHandlers = {
    addedfile: file => {
      console.log(file);

      onDropProfles(file);
    },
    removedfile: file => onRemoveProfile(file),
    maxfilesexceeded: file => {
      console.log(file);
      alert("!!!");
    },
    init: () => {
      if (document.getElementsByClassName("dz-message")[0])
        document.getElementsByClassName("dz-message")[0].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
      if (document.getElementsByClassName("dz-message")[1])
        document.getElementsByClassName("dz-message")[1].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
    }
  };

  const eventHandlersLogo = {
    addedfile: file => onDropLogo(file),
    removedfile: file => onRemoveLogo(),
    maxfilesexceeded: file => {
      console.log(file);
      alert("!!!");
    },
    init: () => {
      if (document.getElementsByClassName("dz-message")[0])
        document.getElementsByClassName("dz-message")[0].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
      if (document.getElementsByClassName("dz-message")[1])
        document.getElementsByClassName("dz-message")[1].innerHTML =
          "업로드하려면 클릭하거나 파일을 올려주세요.";
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>팀생성 | WePlay</title>
      </Helmet>
      <AdminHeader />
      {uploading && <Progress />}
      <Container className={classes.container}>
        <Grid item>
          <Typography variant="h3" className={classes.headerText} gutterBottom>
            팀 생성
            <Link to={"/teams"}>
              <Button variant="contained" color="primary">
                목록보기
              </Button>
            </Link>
          </Typography>
        </Grid>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headerText}
                    gutterBottom
                  >
                    필수 정보
                  </Typography>

                  <FormLabel className={classes.label}>팀 종목</FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="isFreeInput"
                    value={sportInput.value}
                    onChange={sportInput.onChange}
                    row
                  >
                    <FormControlLabel
                      value={"1001"}
                      control={<Radio color="primary" />}
                      label="농구"
                    />
                    {/* 
                    <FormControlLabel
                      value={"1002"}
                      control={<Radio color="primary" />}
                      label="당구"
                      disabled
                    />
                    <FormControlLabel
                      value={"1003"}
                      disabled
                      control={<Radio color="primary" />}
                      label="볼링"
                    /> */}
                  </RadioGroup>
                  <TextField
                    required
                    id="standard-required"
                    label="팀 이름"
                    value={teamNameInput.value}
                    onChange={teamNameInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    required
                    id="standard-required"
                    label="팀 레벨"
                    type="number"
                    inputProps={{
                      max: 5,
                      min: 1
                    }}
                    value={levelInput.value}
                    onChange={levelInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />

                 

                  <FormLabel className={classes.editLabel}>
                    호스트(대표) 지정*
                    <Button variant="contained" onClick={handleUserDialogOpen}>
                      회원 검색
                    </Button>
                  </FormLabel>
                  {host && (
                    <List className={classes.userList}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar url={host.avatar} size={"sm"} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={host.username}
                          secondary={host.email}
                        />
                      </ListItem>
                    </List>
                  )}

                    <FormLabel className={classes.label}>경기장 주소</FormLabel>
                    <PostCode
                      setAddressInfo={() => {}}
                      defaultValue={addressS}
                      text={"주소 검색"}
                      search={false}
                    />
                    <Button variant="contained" onClick={handlePlaceDialogOpen}>
                      경기장 검색
                    </Button>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headerText}
                    gutterBottom
                  >
                    선택 정보
                  </Typography>

                  <TextField
                    id="standard-required"
                    label="대표 연락처"
                    value={contactInput.value}
                    onChange={contactInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />

                  <TextField
                    id="filled-multiline-static"
                    label="팀 소개"
                    multiline
                    rows="4"
                    value={bioInput.value}
                    onChange={bioInput.onChange}
                    className={classes.textField}
                    margin="normal"
                  />
                  <DropZoneWrapper>
                    <FormLabel className={classes.editLabel}>
                      팀 프로필(최대 5개)
                      <Button
                        variant="contained"
                        onClick={handleEditPhotoOpen("profile")}
                      >
                        프로필 추가
                      </Button>
                    </FormLabel>

                    {editProfile && (
                      <DropzoneComponent
                        className={classes.dropzone}
                        config={componentConfig}
                        eventHandlers={eventHandlers}
                        djsConfig={djsProfileConfig}
                      />
                    )}
                  </DropZoneWrapper>

                  <DropZoneWrapper>
                    <FormLabel className={classes.editLabel}>
                      팀 로고
                      <Button
                        variant="contained"
                        onClick={handleEditPhotoOpen("logo")}
                      >
                        로고 추가
                      </Button>
                    </FormLabel>

                    {editLogo && (
                      <DropzoneComponent
                        className={classes.dropzone}
                        config={componentConfig}
                        eventHandlers={eventHandlersLogo}
                        djsConfig={djsLogoConfig}
                      />
                    )}
                  </DropZoneWrapper>
                </Paper>
              </Grid>
            </Grid>

            <Grid container justify="center" spacing={2}>
              <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        className={classes.dialog}
        open={openPlaceDialog}
        onClose={handlePlaceDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlePlaceDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="경기장을 검색해주세요."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchPlace.value}
                onChange={searchPlace.onChange}
              />
            </div>
          </Toolbar>
        </AppBar>
        <Container className={classes.searchContainer}>
          <List dense>
            {searchingPlace && <CircularProgress />}
            {searchPlaceData &&
              searchPlaceData.seePlaces &&
              searchPlaceData.seePlaces.length > 0 &&
              searchPlaceData.seePlaces.map(place => (
                <ListItem
                  button
                  key={place.id}
                  onClick={() => {
                    onChangePlace(place.id, place.address);
                  }}
                >
                  <ListItemText
                    primary={place.placeName}
                    secondary={place.address}
                  />
                </ListItem>
              ))}
          </List>
        </Container>
      </Dialog>

      <Dialog
        className={classes.dialog}
        open={openUserDialog}
        onClose={handleUserDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUserDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="회원을 검색해주세요."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchUser.value}
                onChange={searchUser.onChange}
              />
            </div>
          </Toolbar>
        </AppBar>
        <Container className={classes.searchContainer}>
          <List dense>
            {searchingUser && <CircularProgress />}
            {searchUserData &&
              searchUserData.searchUserNoHost &&
              searchUserData.searchUserNoHost.length > 0 &&
              searchUserData.searchUserNoHost.map(user => (
                <ListItem
                  button
                  key={user.id}
                  onClick={() => {
                    onChangeHost(
                      user.id,
                      user.avatar,
                      user.username,
                      user.email
                    );
                  }}
                >
                  <ListItemText
                    primary={user.username}
                    secondary={user.email}
                  />
                </ListItem>
              ))}
          </List>
        </Container>
      </Dialog>
    </Wrapper>
  );
};
