import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import DropzoneComponent from "react-dropzone-component";

import Loader from "../../Components/Loader";
import Progress from "../../Components/Progress";
import AdminHeader from "../../Components/Header/AdminHeader";
import PostCode from "../../Components/PostCode";

import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)"
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    color: "#000"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px"
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  dialog: {},
  appBar: {
    width: "360px",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  gridList: {
    marginTop:"20px",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  editLabel : {
    display:"flex",
    alignItems:"center",
    marginBottom:"15px",
    "& > button": {
      marginLeft:"15px;"
    }
  }
}));

const DropZoneWrapper = styled.div`
  margin-bottom: 20px;
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;



export default ({
  loading,
  data,
  uploading,
  oldPictures,
  setOldPictures,
  handleOldPictureDelete,
  handleRemovePictureOpen,
  handleRemovePictureClose,
  editPicture,
  handleEditPhotoOpen,
  onDropPictures,
  onRemovePictures,
  handleSubmit,
  placeNameInput,
  contactInput,
  managerInput,
  addressS,
  setAddress,
  openRemovePicture,
  isFreeInput,
  isAuthenticatedInput,
  onReadyMenu,
  sizeInput,
  floorMaterialInput,
  isGroundLine,
  isGroundLight,
  isNet,
  isShowerRoom,
  isParkingLot
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullPlace) {
   



    const {
      seeFullPlace: {
        placeName,
        address,
        picture,
        //location,
        contact,
        manager,
        isAuthenticated,
        isFree,
        basket
      }
    } = data;

    useEffect(() => {
      placeNameInput.setValue(placeName? placeName:"");
      managerInput.setValue(manager? manager:"");
      contactInput.setValue(contact? contact:"");
      isFreeInput.setValue(isFree.toString());
      isAuthenticatedInput.setValue(isAuthenticated.toString());
      if(picture)
        setOldPictures(picture);
      setAddress(address);

      if(basket) {
        sizeInput.setValue(basket.size);
        floorMaterialInput.setValue(basket.floorMaterial);
        isGroundLine.setValue(basket.groundLine.toString());
        isGroundLight.setValue(basket.groundLight.toString());
        isNet.setValue(basket.net.toString());
        isShowerRoom.setValue(basket.showerRoom.toString());
        isParkingLot.setValue(basket.parkingLot.toString());
      }

    }, []);

    const classes = useStyles();
    

    const componentConfig = {
      postUrl: "/"
    };

    let djsProfileConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFiles: 5 - oldPictures.length
    };

    const eventHandlers = {
      maxfilesexceeded: (file) => {
        onRemovePictures(file)
      },
      addedfile: file => {
        console.log(file);
        onDropPictures(file)
      },
      removedfile: file => onRemovePictures(file),

      init: () => {
        if (document.getElementsByClassName("dz-message")[0])
          document.getElementsByClassName("dz-message")[0].innerHTML =
            "업로드하려면 클릭하거나 파일을 올려주세요.";
      }
    };

    console.log(oldPictures);
    return (
      <Wrapper>
        <Helmet>
          <title>경기장 수정 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              경기장 정보 수정
              <Link to={"/places"}>
                <Button
                  variant="contained"
                  color="primary"
              
                >
                  목록보기
                </Button>
              </Link>
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                      <Typography
                        variant="h5"
                        className={classes.headerText}
                        gutterBottom
                      >
                        공통 정보
                      </Typography>

                    <TextField
                      required
                      id="standard-required"
                      label="경기장 이름"
                      value={placeNameInput.value}
                      onChange={placeNameInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                    <TextField
                      required
                      id="standard-required"
                      label="담당자"
                      value={managerInput.value}
                      onChange={managerInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <TextField
                      required
                      id="standard-required"
                      label="연락처"
                      placeholder="-를 제외한 연락처를 입력해주세요."
                      value={contactInput.value}
                      onChange={contactInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <FormLabel className={classes.label}>경기장 주소</FormLabel>
                    <PostCode
                      setAddressInfo={() => {}}
                      defaultValue={addressS}
                      text={"주소 검색"}
                      search={false}
                    />
                    {/* <MapContainer 
                    isInit={true} 
                    lat={latitude} 
                    lng={longitude}
                    placeName={placeNameInput.value}
                    showDetailText={false}></MapContainer> */}

                    <FormLabel className={classes.label}>경기장 유무료 여부</FormLabel>
                    <RadioGroup aria-label="position" name="isFreeInput" value={isFreeInput.value} onChange={isFreeInput.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="무료"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="유료"
                      />
                    </RadioGroup>

                    <FormLabel className={classes.label}>관리자 인증 여부</FormLabel>
                    <RadioGroup aria-label="position" name="isAuthenticatedInput" value={isAuthenticatedInput.value} onChange={isAuthenticatedInput.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="인증"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="미인증"
                      />
                    </RadioGroup>

                    <DropZoneWrapper>
                      <FormLabel  className={classes.editLabel}>
                        경기장 사진(최대 5개)
                        <Button
                          variant="contained"
                          onClick={handleEditPhotoOpen}
                        >
                          사진 추가
                        </Button>
                      </FormLabel>
                      
                      <GridList className={classes.gridList} cols={1.5}>
                        {oldPictures && oldPictures.length > 0 && oldPictures.map(tile => (
                          <GridListTile key={tile.url}>
                            <img
                              src={tile.url}
                              alt={`위플레이 | ${placeNameInput.value}`}
                            />
                            <GridListTileBar
                              classes={{
                                root: classes.titleBar,
                                title: classes.title
                              }}
                              actionIcon={
                                <IconButton aria-label={`Delete`} onClick={handleRemovePictureOpen(tile.id)}>
                                  <DeleteIcon className={classes.title} />
                                </IconButton>
                              }
                            />
                          </GridListTile>
                        ))}
                      </GridList>

                      {editPicture && (
                        <DropzoneComponent
                          className={classes.dropzone}
                          config={componentConfig}
                          eventHandlers={eventHandlers}
                          djsConfig={djsProfileConfig}
                        />
                      )}
                    </DropZoneWrapper>  

                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    <Typography
                        variant="h5"
                        className={classes.headerText}
                        gutterBottom
                      >
                        세부 정보(농구)
                    </Typography>
                    {/* <SportsWrapper>
                      <Button
                        variant="contained"
                        onClick={handleOpen}
                      >
                        농구
                      </Button>
                    </SportsWrapper> */}

                    <TextField
                      required
                      id="standard-required"
                      label="사이즈"
                      value={sizeInput.value}
                      onChange={sizeInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />
                    <TextField
                      required
                      id="standard-required"
                      label="바닥 재질"
                      value={floorMaterialInput.value}
                      onChange={floorMaterialInput.onChange}
                      className={classes.textField}
                      margin="normal"
                    />

                    <FormLabel className={classes.label}>그물</FormLabel>
                    <RadioGroup aria-label="그물" name="isNet" value={isNet.value} onChange={isNet.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="유"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="무"
                      />
                    </RadioGroup>

                    <FormLabel className={classes.label}>라인</FormLabel>
                    <RadioGroup aria-label="라인" name="isGroundLine" value={isGroundLine.value} onChange={isGroundLine.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="유"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="무"
                      />
                    </RadioGroup>

                    <FormLabel className={classes.label}>조명</FormLabel>
                    <RadioGroup aria-label="조명" name="isGroundLight" value={isGroundLight.value} onChange={isGroundLight.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="유"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="무"
                      />
                    </RadioGroup>

                    <FormLabel className={classes.label}>샤워시설</FormLabel>
                    <RadioGroup aria-label="샤워시설" name="isShowerRoom" value={isShowerRoom.value} onChange={isShowerRoom.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="유"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="무"
                      />
                    </RadioGroup>

                    <FormLabel className={classes.label}>주차장</FormLabel>
                    <RadioGroup aria-label="주차장" name="isParkingLot" value={isParkingLot.value} onChange={isParkingLot.onChange} row>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio color="primary" />}
                        label="유"
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio color="primary" />}
                        label="무"
                      />
                    </RadioGroup>
                  </Paper>
                </Grid>
              </Grid>



              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
              </Grid>
            </Grid>
          </Grid>
        </Container>


        <Dialog
          open={openRemovePicture}
          onClose={handleRemovePictureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"해당 사진을 삭제하시겠습니까?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleRemovePictureClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleOldPictureDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
};
