import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import BoldText from "./BoldText";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";


const Card = styled.div`
  ${props => props.theme.whiteBox}
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #000;
`;

const EAvatar = styled(Avatar)`
  margin-bottom: 15px;
`;

const ELink = styled(Link)`
  margin-top: 10px;
`;

const SearchCard = ({ id, url, type, name, userCount, level, size="md" }) => {
  if (type === "user") {
    return (
      <Card>
        <EAvatar url={url} size={size} />
        <BoldText text={name} />
        <ELink to={`/user/${id}`}>
          <Button variant="contained" color="primary">
            프로필
          </Button>
        </ELink>
      </Card>
    );
  } else if (type === "team") {
    return (
      <Card>
        <EAvatar url={url} size={size} />
        <BoldText text={name} />
        <BoldText text={`Lv.${level}`} />
        <ELink to={`/team/${id}`}>
          <Button variant="contained" color="primary">
            팀정보
          </Button>
        </ELink>
      </Card>
    );
  } else if (type === "place") {
    return (
      <Card>
        <EAvatar url={url} size={"md"} />
        <BoldText text={name} />
        <ELink to={`/place/${id}`}>
          <Button variant="contained" color="primary">
            경기장정보
          </Button>
        </ELink>
      </Card>
    );
  }
  return null;
};

SearchCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["user", "team", "place"]).isRequired,
  url: PropTypes.string.isRequired,
  userCount:PropTypes.number,
  level:PropTypes.number
};

export default SearchCard;
