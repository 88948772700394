export const defaults = {
    isLoggedIn : Boolean(localStorage.getItem("token")) || false,
    isAdminIn : Boolean(localStorage.getItem("adminToken")) || false,
    curSports : Boolean(localStorage.getItem("sport")) || "1001"
}

export const resolvers = {
    Mutation: {
        logAdminIn: (_,{token},{cache})=> {
          localStorage.setItem("adminToken", token);
          localStorage.setItem("admin", true);
          cache.writeData({
              data:{
                isLoggedIn: true
              }
          });
          window.location = "/";
          return null;
        },
        logAdminOut:(_,__,{cache}) => {
          localStorage.removeItem("adminToken");
          window.location = "/";
          return null;
        },
    }
}