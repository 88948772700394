import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import AdminAuth from "../Routes/Auth";
import AdminUserList from "../Routes/UserList";
import AdminGameList from "../Routes/GameList";
import AdminTeamList from "../Routes/TeamList";

import MainBanner from "../Routes/MainBanner";

import CupCreate from "../Routes/CreateCup";
import CupEdit from "../Routes/CupEdit";
import CupList from "../Routes/CupList";
import CupTeamList from "../Routes/CupTeamList";
import CupScheduleList from "../Routes/CupScheduleList";
import CupCreateTeam from "../Routes/CreateCupTeam";
import CupCreateGame from "../Routes/CreateCupGame";
import CupGame from "../Routes/CupGame";
import CupEditTeam from "../Routes/CupEditTeam";
import CupMain from "../Routes/CupMain";
import CupTeamRanking from "../Routes/CupTeamRanking";
import CupBoard from "../Routes/CreateCupBoard";
import CupCreateBoard from "../Routes/CreateCupBoard";
import CupNoticeList from "../Routes/CupNoticeList";
import CupEditGame from "../Routes/CupEditGame";
import CupBanner from "../Routes/CupBanner";

import AdminPlaceList from "../Routes/PlaceList";
import AdminSearch from "../Routes/Search";
import AdminTeamProfile from "../Routes/TeamProfile";
import AdminCreateTeam from "../Routes/CreateTeam";

import AdminCreatePlace from "../Routes/CreatePlace";
import AdminEditPlace from "../Routes/EditPlace";
import AdminUserProfile from "../Routes/EditProfile";
import AdminDetailGame from "../Routes/DetailGame";
import SendNotification from "../Routes/SendNotification";


//import Dashboard from "../Routes/Admin/Dashboard";

const AdminLoggedOutRoutes = () => (
  <Switch>
      <Route path="/" component={AdminAuth} />
      <Redirect from="*" to="/" />
  </Switch>
);

const AdminLoggedInRoutes = () => (
  <Switch>
      <Route exact path="/" component={AdminUserList} />
      <Route path="/users" component={AdminUserList} />
      <Route path="/games" component={AdminGameList} />
      <Route path="/teams" component={AdminTeamList} />
      <Route path="/banners" component={MainBanner} />
      <Route path="/notification" component={SendNotification} />

      <Route path="/cups" component={CupList} />
      <Route path="/cup/create" component={CupCreate} />
      <Route path="/cup/:cup/edit" component={CupEdit} />
      <Route path="/cup/:cup/banner" component={CupBanner} />
      <Route path="/cup/:cup/notices" component={CupNoticeList} />
      <Route path="/cup/:cup/notice/create" component={CupCreateBoard} />
      <Route path="/cup/:cup/notice/:notice" component={CupBoard} />
      <Route path="/cup/:cup/teams" component={CupTeamList} />
      <Route path="/cup/:cup/games" component={CupScheduleList} />
      <Route path="/cup/:cup/rank" component={CupTeamRanking} />
      <Route path="/cup/:cup/team/create" component={CupCreateTeam} />
      <Route path="/cup/:cup/team/:team" component={CupEditTeam} />
      <Route path="/cup/:cup/game/create" component={CupCreateGame} />
      <Route path="/cup/:cup/game/:game/info" component={CupEditGame} />
      <Route path="/cup/:cup/game/:game" component={CupGame} />
      <Route path="/cup/:cup" component={CupMain} />
      
      
      
      <Route path="/places" component={AdminPlaceList} />
      <Route path="/user/:user" component={AdminUserProfile} />
      <Route path="/team/create" component={AdminCreateTeam} />
      
      <Route path="/team/:team" component={AdminTeamProfile} />
      <Route path="/place/create" component={AdminCreatePlace} />
      <Route path="/place/:place" component={AdminEditPlace} />
      <Route path="/game/:code/:game" component={AdminDetailGame} />
      <Route path="/search" component={AdminSearch} />
      <Redirect from="*" to="/" />
  </Switch>
);

const AppRouter = ({ isAdminIn }) =>  {
  if(isAdminIn) {
    return <AdminLoggedInRoutes />;
  } else {
    return <AdminLoggedOutRoutes />;
  }
}
 // isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />;

    

AppRouter.propTypes = {
  isAdminIn: PropTypes.bool.isRequired
};

export default AppRouter;