import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";
import CupHeader from "../../Components/CupHeader";
import AdminHeader from "../../Components/Header/AdminHeader";
import {Link} from "react-router-dom";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  color: #000;
`;


// const CrownImg = styled.img`
//   width: 60px;
// `;

const CrownImg = styled.img`
  width: 30px;
  position: absolute;
  top: -10px;
  left: -3px;
  /* @media (max-width: 600px) {
    top: -60px;
    left: 27px;
  } */
`;

const HistoryWrap = styled.ul`
  max-width: 924px;
  margin: 0 auto;
  width: 95%;
  padding: 50px 30px 30px;
  background-color: #fff;
  min-height: calc(100vh - 360px);
  @media (max-width: 600px) {
    padding: 50px 10px 30px;
  }
`;


const ListTitle = styled.div`
  background-color:#25374d;
  color:#fff;
  font-size:25px;
  padding:10px 20px;
  width:100%;
  margin-bottom:20px;
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #000;
  padding: 15px 15px;
  cursor: pointer;
  overflow: auto;
  display: flex;
  align-self:center;
  justify-content: space-between;
  & > .MuiDivider-vertical {
    height: 25px!important;
    margin: 0px 25px!important;
    background-color: rgba(0, 0, 0, 0.92)!important;
  }
`;

const ListHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  margin-left: 10px;
  display: flex;
    align-items: center;
    @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const TeamInfo = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
    align-items: center;
    @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const AllGrade = styled(TeamInfo)`
`

const TeamOrder = styled.div`
  width: 30px;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 500;
`;


export default ({ 
  loading, 
  data, 
  cupId,
  ranks,
  setRanks,
  handleInfiniteScroll 
  }) => {
  if (loading) {
    return (
      <Wrapper>
        <AdminHeader />
        <CupHeader id={cupId} active={"rank"} />

        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCupRanks) {

    const {
      seeCup: {
        id,
        cupName,
        games
      },
      seeCupRanks
    } = data;

    useEffect(() => {
      if(data.seeCupRanks.length > 0) {
        const ranks = seeCupRanks;
        if(games.length > 0 && games[games.length-1].winner) {
          const lastWinner = ranks.splice(
            ranks.findIndex(i => i.team.id === games[games.length-1].winner),
            1
          );
          ranks.unshift(lastWinner[0]);
        }
        
        setRanks(ranks);
      }

      setRanks(seeCupRanks);
    }, [])

    return (
      <Wrapper>
        <Helmet>
          <title>랭킹 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <CupHeader id={id} active={"rank"} cupName={cupName} />
        <ContentWrapper>
          
          <HistoryWrap>
            <ListTitle>랭킹</ListTitle>

            {ranks &&
              ranks.length > 0 &&
              ranks.map((rank, index) => (
                <Link key={rank.team.id} to={`/cup/${id}/team/${rank.team.id}`}>
                  <HistoryList key={rank.team.id}>
                    <ListHeader>
                      {(rank.win+rank.draw+rank.lose)!==0 && index === 0 && <CrownImg src="/imgs/crown.png"/>}
                      <TeamOrder>{(rank.win+rank.draw+rank.lose)===0?"-":index + 1}</TeamOrder>
                      <Avatar url={rank.team.logo} size={"sm"} />
                      <TeamName>{rank.team.teamName}</TeamName>
                    </ListHeader>


                    <AllGrade>
                      {rank.win}승 {rank.draw}무 {rank.lose}패
                    </AllGrade>
                  </HistoryList>
                </Link>
              ))}
          </HistoryWrap>
        </ContentWrapper>

        
      </Wrapper>
    );
  }
  return null;
};
