import { gql } from "apollo-boost";

export const SEARCH_PLACE = gql`
  query seePlaces($skip:Int, $first:Int, $term:String) {
    seePlaces(skip:$skip, first:$first, term:$term) {
      id
      placeName
      address
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUserNoHost($skip:Int, $first:Int, $term:String!) {
    searchUserNoHost(skip:$skip, first:$first, term:$term) {
      id
      avatar
      email
      username
      contact
    }
  }
`;

export const SEE_TEAMS = gql`
  query seeCupTeams($first: Int, $skip: Int, $cupId: String!) {
    seeCupTeams(first: $first, skip: $skip, cupId: $cupId) {
      id
      teamName
      host {
        id
        username
        email
      }
      logo
    },
    seeCup(id:$cupId) {
      id
      cupName
      playerCnt
      place {
        id
        placeName
        address
      }
    }
  }
`;

export const CREATE_GAME = gql`
  mutation createCupGame(
    $cupId:String!
    $gameName:String
    $homeId:String
    $awayId:String
    $gameDate:String!
    $startTime:String!
    $endTime:String!
    $playerCnt:Int!
    $placeId: String
    $lat: Float
    $lng: Float
    $placeName: String
    $address: String
    $shortAddress: String
    ) {
      createCupGame(
        cupId:$cupId
        gameName:$gameName
        homeId:$homeId
        awayId:$awayId
        gameDate:$gameDate
        startTime:$startTime
        endTime:$endTime
        playerCnt:$playerCnt
        placeId:$placeId
        lat:$lat
        lng:$lng
        placeName:$placeName
        address:$address
        shortAddress:$shortAddress
      ) {
        id
      }
    }
`;


export const EDIT_USER = gql`
  mutation editMemberByAdmin(
    $id:String!
    $oppId:String!
    $oldData:String!
    $newData:String!
    ) {
      editMemberByAdmin(
        id:$id
        oppId:$oppId
        oldData:$oldData
        newData:$newData
      ) 
    }
`;


export const REMOVE_USER = gql`
  mutation removeUser(
    $id:String!
    ) {
      removeUser(
        id:$id
      ) 
    }
`;