import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import AdminHeader from "../../Components/Header/AdminHeader";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    minHeight: "100px"
  },
  container: {},
}));

const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
  padding: 0px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
  /* padding-bottom:100px; */
`;

const LoaderWapeer = styled.div`
  width: 100%;
  min-height: 170px;
  background-color: #fff;
  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 30px;
  }
`;


export default function MaterialTableDemo({
  loading,
  data,
  onUpdatePage,
  games,
  setGames,
  loadMore,
  onScroll,
  canMore,
  setCanMore,
  searchInput
}) {

    useEffect(() => {
      if(!loading && data && data.seeGames) {
        setCanMore(data.seeGames.length >= 10);
        setGames({ ...games, data: data.seeGames });
      }
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>경기관리 | WePlay</title>
        </Helmet>
        {loading && <Loader background={false}/> }
        <AdminHeader searchInput={searchInput} />
        <TableWrapper onScroll={e => onScroll(e)}>
          <MaterialTable
            title="경기관리"
            columns={games.columns}
            style={{
              margin: "0 auto",
              minHeight: "calc(100vh - 234px)",
              position: "unset",
              boxShadow: "none"
            }}
            data={games.data}
            actions={[
              {
                icon: 'edit',
                tooltip: '수정',
                onClick: (event, rowData) => {
                  console.log(rowData);
                  onUpdatePage(rowData.id, rowData.sportsCode.code);
                }
              }
            ]}
            options={{
              paging:false,
              search: false,
              draggable: false,
              actionsColumnIndex: -1,
              detailPanelType: "single"
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "총 {count}개 중 {from}-{to} "
              },
              toolbar: {
                nRowsSelected: "{0}개 선택",
                searchPlaceholder: "경기검색"
              },
              header: {
                actions: ""
              },
              body: {
                emptyDataSourceMessage: "",
                filterRow: {
                  filterTooltip: "필터"
                },
                editRow: {
                  deleteText: "정말로 삭제하시겠습니까?"
                }
              }
            }}
            //editable={superAdminEditable}
          />
          <LoaderWapeer>{canMore && loadMore && <CircularProgress />}</LoaderWapeer>
        </TableWrapper>
       

      </Wrapper>
    );
}
