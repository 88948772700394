import { gql } from "apollo-boost";

export const SEARCH_PLACE = gql`
  query seePlaces($skip:Int, $first:Int, $term:String) {
    seePlaces(skip:$skip, first:$first, term:$term) {
      id
      placeName
      address
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUserNoHost($skip:Int, $first:Int, $term:String!) {
    searchUserNoHost(skip:$skip, first:$first, term:$term) {
      id
      avatar
      email
      username
      contact
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam(
    $logo: String
    $sportId:String!
    $profiles: [String]
    $teamName: String!
    $contact: String!
    $level:Int!
    $bio: String
    $hostId: String
    $placeId: String
    ) {
      createTeam(
        logo:$logo
        profiles:$profiles
        teamName:$teamName
        sportId:$sportId
        level:$level
        contact:$contact
        bio:$bio
        placeId:$placeId
        hostId:$hostId
      ) {
        id
      }
    }
`;


export const EDIT_USER = gql`
  mutation editMemberByAdmin(
    $id:String!
    $oppId:String!
    $oldData:String!
    $newData:String!
    ) {
      editMemberByAdmin(
        id:$id
        oppId:$oppId
        oldData:$oldData
        newData:$newData
      ) 
    }
`;


export const REMOVE_USER = gql`
  mutation removeUser(
    $id:String!
    ) {
      removeUser(
        id:$id
      ) 
    }
`;