import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CREATE_TEAM, SEARCH_PLACE, SEARCH_USER } from "./CreateTeamQueries";
import CreateTeamPresenter from "./CreateTeamPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";

const CreateTeamContainer = ({ history }) => {
  const [logo, setLogo] = useState();
  const [profiles, setProfiles] = useState([]);

  const sportInput = useInput("1001");
  const teamNameInput = useInput("");
  const levelInput = useInput(1, newValue => !isNaN(newValue));
  const contactInput = useInput("", newValue => !isNaN(newValue));
  const bioInput = useInput("");
  const [place, setPlace] = useState("");
  const [host, setHost] = useState();
  const [addressS, setAddress] = useState("");

  const searchPlace = useInput("");
  const searchUser = useInput("");

  const [searchingPlace, setSearchingPlace] = useState(false);
  const [searchingUser, setSearchingUser] = useState(false);

  const [openPlaceDialog, setOpenPlaceDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);

  const [editLogo, setEditLogo] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const [uploading, setUploading] = useState(false);


  const createTeamMutation = useMutation(CREATE_TEAM);

  const { loading: loadingPlace, data: searchPlaceData } = useQuery(
    SEARCH_PLACE,
    {
      skip: searchPlace.value === "" && searchPlace.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchPlace.value
      }
    }
  );

  const { loading: loadingUser, data: searchUserData } = useQuery(SEARCH_USER, {
    skip: searchUser.value === "" && searchUser.value.length <= 1,
    variables: {
      first: 30,
      skip: 0,
      term: searchUser.value
    }
  });

  // 경기장 검색 값 변할 경우 리렌더링
  useEffect(() => {
    if (searchPlace.value.length > 1) {
      setSearchingPlace(true);
    } else {
      setSearchingPlace(false);
    }

    if (searchUser.value.length > 1) {
      setSearchingUser(true);
    } else {
      setSearchingUser(false);
    }

    if (!loadingPlace && searchPlace) {
      setSearchingPlace(false);
    }
    if (!loadingUser && searchUser) {
      setSearchingUser(false);
    }
  }, [
    searchPlace.value,
    searchUser.value,
    loadingPlace,
    searchPlaceData,
    searchUserData
  ]);

  // 경기장 변경 시 변경값 설정
  const onChangePlace = (placeId, address) => {
    setPlace(placeId);
    setAddress(address);
    handlePlaceDialogClose();
  };

  // 호스트 선택 시 변경값 설정
  const onChangeHost = (id,avatar,username,email) => {
    setHost({
      id,
      avatar,
      username,
      email
    });
    handleUserDialogClose();
  };

  // 프로필 사진 드롭 시 이벤트
  const onDropProfles = acceptedFiles => {
    const data = profiles;
    data.push(acceptedFiles);
    setProfiles(data);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveProfile = removeFile => {
    const data = profiles;
    data.splice(
      data.findIndex(i => i.upload.uuid === removeFile.upload.uuid),
      1
    );
    setProfiles(data);
  };

  const handleEditPhotoOpen = type => () => {
    if (type === "logo") setEditLogo(!editLogo);
    else if (type === "profile") setEditProfile(!editProfile);
  };

  // 로고파일 드롭 이벤트
  const onDropLogo = acceptedFile => {
    setLogo(acceptedFile);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveLogo = () => {
    setLogo(null);
  };

  // 경기장 검색창 오픈 이벤트
  const handlePlaceDialogOpen = () => {
    setOpenPlaceDialog(true);
  };

  // 경기장 검색창 닫기 이벤트
  const handlePlaceDialogClose = () => {
    setOpenPlaceDialog(false);
  };

  // 회원 검색창 오픈 이벤트
  const handleUserDialogOpen = () => {
    setOpenUserDialog(true);
  };

  // 회원 검색창 닫기 이벤트
  const handleUserDialogClose = () => {
    setOpenUserDialog(false);
  };

  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes
    console.log({
      sportId:sportInput.value,
      teamName: teamNameInput.value,
      level: parseInt(levelInput.value),
      contact: contactInput.value,
      bio: bioInput.value,
      placeId: place,
      hostId: host
    });
    if(uploading)
      return false;

    setUploading(true);
    try {
      if (!host || teamNameInput.value === "" || !place || levelInput.value==="") {
        toast.error("필수 정보는 모두 입력해주시기 바랍니다.");
      } else {
        let LogoUrl;
        let ProfileUrls;
        if (logo) {
          const formData = new FormData();
          formData.append("logo", logo);

          const {
            data: { location }
          } = await axios.post(
            "https://weplay2921.cafe24.com/api/team/upload",
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );
          LogoUrl = location;
        }

        if (profiles && profiles.length > 0) {
          const formData = new FormData();
          profiles.forEach(file => {
            formData.append("profile", file);
          });

          const { data } = await axios.post(
            "https://weplay2921.cafe24.com/api/team/profile/upload",
            formData,
            {
              headers: {
                "content-type": "multipart/form-data"
              }
            }
          );

          ProfileUrls = data;
        }

        const {
          data: { createTeam }
        } = await createTeamMutation({
          variables: {
            sportId:sportInput.value,
            teamName: teamNameInput.value,
            level: parseInt(levelInput.value),
            contact: contactInput.value,
            bio: bioInput.value,
            placeId: place,
            hostId: host.id,
            profiles: ProfileUrls,
            logo: LogoUrl
          }
        });
        console.log(createTeam);
        if (createTeam.id) {
          toast.success("업로드 완료!");

          setTimeout(() => {
            history.push(`/team/${createTeam.id}`);
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <CreateTeamPresenter
      uploading={uploading}
      editLogo={editLogo}
      editProfile={editProfile}
      handleEditPhotoOpen={handleEditPhotoOpen}
      onDropProfles={onDropProfles}
      onDropLogo={onDropLogo}
      onRemoveProfile={onRemoveProfile}
      onRemoveLogo={onRemoveLogo}
      handleSubmit={handleSubmit}

      sportInput={sportInput}
      teamNameInput={teamNameInput}
      levelInput={levelInput}
      contactInput={contactInput}
      bioInput={bioInput}
      addressS={addressS}

      host={host}
      onChangePlace={onChangePlace}
      onChangeHost={onChangeHost}
      searchPlaceData={searchPlaceData}
      searchUserData={searchUserData}
      searchPlace={searchPlace}
      searchUser={searchUser}
      searchingPlace={searchingPlace}
      searchingUser={searchingUser}
      openPlaceDialog={openPlaceDialog}
      openUserDialog={openUserDialog}
      handlePlaceDialogOpen={handlePlaceDialogOpen}
      handlePlaceDialogClose={handlePlaceDialogClose}
      handleUserDialogOpen={handleUserDialogOpen}
      handleUserDialogClose={handleUserDialogClose}
    />
  );
};

export default withRouter(CreateTeamContainer);
