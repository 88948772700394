import { gql } from "apollo-boost";


export const CREATE_CUP = gql`
  mutation createCup(
      $cupName:String!
      $target:String!
      $cupProcess:String!
      $playerCnt:Int!
      $cupStartDate:String!
      $cupEndDate:String!
      $cupDateInfo:String
      $host:[String!]
      $organizer:[String!]
      $support:[String!]
      $sponsor:[String!]

      $placeId:String
      $lat:Float
      $lng:Float
      $placeName:String
      $address:String
      $shortAddress:String

      $youtubeUrl: String
      $firstPrize: String
      $secondPrize: String
      $thirdPrize: String
      $mvpPrize: String
      $mannerPrize: String
      $joinPrice: String
      $contact: String
      $DepositAccount: String
    ) {
      createCup(
        cupName:$cupName
        target:$target
        cupProcess:$cupProcess
        playerCnt:$playerCnt
        cupStartDate:$cupStartDate
        cupEndDate:$cupEndDate
        cupDateInfo:$cupDateInfo
        host:$host
        organizer:$organizer
        support:$support
        sponsor:$sponsor
        placeId:$placeId
        lat:$lat
        lng:$lng
        placeName:$placeName
        address:$address
        shortAddress:$shortAddress
        youtubeUrl:$youtubeUrl
        firstPrize:$firstPrize
        secondPrize:$secondPrize
        thirdPrize:$thirdPrize
        mvpPrize:$mvpPrize
        mannerPrize:$mannerPrize
        joinPrice:$joinPrice
        contact:$contact
        DepositAccount:$DepositAccount
      ) {
        id
      }
    }
`;

export const SEARCH_PLACE = gql`
  query seePlaces($skip:Int, $first:Int, $term:String) {
    seePlaces(skip:$skip, first:$first, term:$term) {
      id
      placeName
      address
    }
  }
`;