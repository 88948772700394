import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";

import AdminHeader from "../../Components/Header/AdminHeader";
import {
  fade,
  createStyles,
  makeStyles,
  withStyles
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import {
  TextField,
  Button,
  NativeSelect,
  ListItemAvatar,
} from "@material-ui/core";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import BoldText from "../../Components/BoldText";

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Progress from "../../Components/Progress";
import moment from "moment";
import "moment/locale/ko";
import Avatar from "../../Components/Avatar";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Wrapper = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  userList: {
    border: "1px solid #f0f0f0"
  },
  container: {
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 64px)",
    paddingBottom:100
  },
  searchContainer: {
    backgroundColor: "#fff",
    minHeight: "500px"
  },
  headerText: {
    fontFamily: "NanumSqure",
    paddingTop: "30px",
    paddingLeft:"10px",
    color: "#000",
    "& > p": {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      color: "#ff0000"
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    boxShadow: "0px 2px 13px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    [theme.breakpoints.down('sm')]: {
      padding: "10px"
    },
  },
  control: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: "15px",
    marginBottom: "10px"
  },
  appBar: {
    width: "500px",
    maxWidth: "100%",
    position: "relative",
    backgroundColor: "#227fff"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "14px"
  },
  title: {
    color: theme.palette.primary.light
  },
  textField: {
    marginTop: "10px",
    marginBottom: "20px"
  },
  tableCell: {
    maxWidth:350
  },
  tableHead: {
    fontWeight:"bold"
  }
}));


const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

const LabelHint = styled.span`
  font-size: 13px;
  font-weight: 500;
  padding-left: 7px;
  margin-right: 10px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding: 15px 0px;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

const HalfColumn = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  loading,
  data,

  uploading,
  action,
  title,
  message,
  searchTeamInput,
  searchUserInput,
  searchUserData,
  searchTeamData,
  searchingTeam,
  searchingUser,
  handleDialogOpen,
  handleDialogClose,
  handleSubmit,
  openDialog,
  userInfo,
  teamInfo,
  setTeamInfo,
  setUserInfo
}) => {
    const classes = useStyles();
    useEffect(() => {
     
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>푸시 알림 보내기 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}
        <Container className={classes.container}>
          <Grid item>
            <Typography
              variant="h3"
              className={classes.headerText}
              gutterBottom
            >
              알림 보내기
            </Typography>
          </Grid>

          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Paper className={classes.paper}>

                  <UserInfoRow>
                      <InputInfoColumn>
                        <BoldText text={"알림 타겟"} />
                      </InputInfoColumn>
                      <HalfColumn>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={action.value}
                          onChange={action.onChange}
                          input={<BootstrapInput />}
                        >
                          <option value={"all"}>모든 사용자</option>
                          <option value={"team"}>특정 팀</option>
                          <option value={"user"}>특정 사용자</option>
                        </NativeSelect>
                        
                      </HalfColumn>
                    </UserInfoRow>
                    {action.value === 'team' && (
                      <UserInfoRow>
                        <InputInfoColumn>
                          <BoldText text={"타겟 검색"} />
                          <LabelHint>*</LabelHint>
                          <Button
                            variant="contained"
                            onClick={handleDialogOpen('team')}
                          >
                            팀 검색
                          </Button>
                        </InputInfoColumn>
                        
                        {teamInfo && (
                          <Paper className={classes.paper}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar url={teamInfo.logo} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={teamInfo.teamName}
                              />
                            </ListItem>
                          </Paper>
                        )}
                      </UserInfoRow>
                    )}

                    {action.value === 'user' && (
                      <UserInfoRow>
                        <InputInfoColumn>
                          <BoldText text={"타겟 검색"} />
                          <LabelHint>*</LabelHint>
                          <Button
                            variant="contained"
                            onClick={handleDialogOpen('user')}
                          >
                            사용자 검색
                          </Button>
                        </InputInfoColumn>
                        <InputInfoColumn>

                        {userInfo && (
                          <Paper className={classes.paper}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar url={userInfo.avatar} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={userInfo.username}
                              />
                            </ListItem>
                          </Paper>
                        )}
                        </InputInfoColumn>
                      </UserInfoRow>
                    )}

                    <UserInfoRow>
                      <InputInfoColumn>
                        <BoldText text={"알림 제목"} />
                        <LabelHint>*</LabelHint>
                      </InputInfoColumn>
                      <InputInfoColumn>
                        <TextField
                          autoFocus
                          required
                          rowsMax="4"
                          value={title.value}
                          onChange={title.onChange}
                          margin="dense"
                          id="title"
                          fullWidth
                        />
                      </InputInfoColumn>
                    </UserInfoRow>

                    <UserInfoRow>
                      <InputInfoColumn>
                        <BoldText text={"알림 내용"} />
                        <LabelHint>*</LabelHint>
                      </InputInfoColumn>
                      <InputInfoColumn>
                        <TextField
                          required
                          fullWidth
                          rowsMax="4"
                          id="message"
                          value={message.value}
                          onChange={message.onChange}
                        />
                      </InputInfoColumn>
                    </UserInfoRow>

                  </Paper>
                </Grid>

              </Grid>

              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
              </Grid>
            </Grid>
          </Grid>
          {!loading && data && data.seeNotiLogs && (
          <Paper>
             <Typography
              variant="h5"
              className={classes.headerText}
              gutterBottom
            >
              최근 전송 내역(20개)
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>알림 타겟</TableCell>
                  <TableCell className={classes.tableHead} align="right">타겟</TableCell>
                  <TableCell className={classes.tableHead} align="right">제목</TableCell>
                  <TableCell className={classes.tableHead} align="right">내용</TableCell>
                  <TableCell className={classes.tableHead} align="right">생성일</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.seeNotiLogs.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell align="right">{row.target}</TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell className={classes.tableCell}  align="right">{row.message}</TableCell>
                    <TableCell align="right">{moment(row.createdAt).format('YYYY-MM-DD A hh:mm')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          )}



        </Container>

        <Dialog
          className={classes.dialog}
          open={openDialog.team}
          onClose={handleDialogClose('team')}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose('team')}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="팀 이름을 검색해주세요."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searchTeamInput.value}
                  onChange={searchTeamInput.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>
          <Container className={classes.searchContainer}>
            <List dense>
              {searchingTeam && <CircularProgress />}
              {searchTeamData &&
                searchTeamData.searchAllTeams &&
                searchTeamData.searchAllTeams.length > 0 &&
                searchTeamData.searchAllTeams.map(team => (
                  <ListItem
                    button
                    key={team.id}
                    onClick={setTeamInfo(team)}
                  >
                     <ListItemAvatar>
                      <Avatar url={team.logo} />
                    </ListItemAvatar>
                    {team.cup? (
                      <ListItemText
                        primary={team.teamName}
                        secondary={team.cup.cupName}
                      />
                    ):(
                      <ListItemText
                        primary={team.teamName}
                      />
                    )}
                   
                  </ListItem>
                ))}
            </List>
          </Container>
        </Dialog>



        <Dialog
          className={classes.dialog}
          open={openDialog.user}
          onClose={handleDialogClose('user')}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose('user')}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="사용자 이름을 검색해주세요."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searchUserInput.value}
                  onChange={searchUserInput.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>

          <Container className={classes.searchContainer}>
            <List dense>
              {searchingUser && <CircularProgress />}
              {searchUserData &&
                searchUserData.searchUser &&
                searchUserData.searchUser.length > 0 &&
                searchUserData.searchUser.map(user => (
                  <ListItem
                    button
                    key={user.id}
                    onClick={setUserInfo(user)}
                  >
                    <ListItemAvatar>
                      <Avatar url={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.username}
                    />
                  </ListItem>
                ))}
            </List>
          </Container>
        </Dialog>
      </Wrapper>
    );
};

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    }
  })
)(InputBase);
