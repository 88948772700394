import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import CupHeader from "../../Components/CupHeader";
import AdminHeader from "../../Components/Header/AdminHeader";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ClearIcon from "@material-ui/icons/Clear";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  color: #000;
`;

// const CrownImg = styled.img`
//   width: 60px;
// `;

const HistoryWrap = styled.ul`
  max-width: 924px;
  margin: 0 auto;
  width: 95%;
  padding: 50px 30px 30px;
  background-color: #fff;
  @media (max-width: 600px) {
    padding: 50px 10px 30px;
  }
`;

const ListTitle = styled.div`
  background-color: #25374d;
  color: #fff;
  font-size: 25px;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 20px;
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #000;
  padding: 15px 15px;
  /* cursor: pointer; */
  overflow: auto;
  display: flex;
  align-self: center;
  justify-content: space-between;
  & > a {
    flex:1;
  }
  & > .MuiDivider-vertical {
    height: 25px !important;
    margin: 0px 25px !important;
    background-color: rgba(0, 0, 0, 0.92) !important;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ListHeader = styled.div`
  display: flex;
  align-items: center;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  display: flex;
  align-items: center;
`;

const TeamInfo = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  align-items: center;
`;

const AllGrade = styled(TeamInfo)`
  font-size: 15px;
  font-weight: 200;
  & > span {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    & > span {
      display:none;
    }
  }
`;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 20px 0 40px;
`;


export default ({
  loading,
  data,
  cupId,
  notices,
  setNotices,
  onCreatePage,
  openDialog,
  handleDialogOpen,
  handleDialogClose,
  handleDelete,
  handleInfiniteScroll
}) => {
  if (loading) {
    return (
      <Wrapper>
        <AdminHeader />
        <CupHeader id={cupId} active={"notice"} />

        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeNotices) {
    const {
      seeCup: { id, cupName },
      seeNotices
    } = data;

    useEffect(() => {
      setNotices(seeNotices);
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>공지사항 | WePlay</title>
        </Helmet>
        <AdminHeader />
        <CupHeader id={id} active={"notice"} cupName={cupName} />
        <ContentWrapper>
          <Section>
            <Button variant="contained" color="primary" onClick={onCreatePage}>
              공지 등록
              <AddIcon />
            </Button>
          </Section>
          <HistoryWrap>
            <ListTitle>공지</ListTitle>

            {notices &&
              notices.length > 0 &&
              notices.map((notice, index) => (
                
                  <HistoryList key={notice.id}>      
                    <Link to={`/cup/${cupId}/notice/${notice.id}`} key={notice.id}> 
                      <ListHeader>
                        <TeamName>{notice.title}</TeamName>
                      </ListHeader>
                    </Link>   
                    <AllGrade>
                      {notice.writer.username} │
                      {moment(notice.createdAt).format("YYYY년 MM월 DD일")}
                      <span>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleDialogOpen(notice.id)}
                        >
                          공지 삭제
                          <ClearIcon />
                        </Button>
                      </span>
                    </AllGrade>
                  </HistoryList>
                
              ))}
          </HistoryWrap>
        </ContentWrapper>

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"해당 공지사항을 삭제하시겠습니까?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              아니오
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              예
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
  return null;
};
