import { gql } from "apollo-boost";

export const SEE_USERS = gql`
  query seeUsers($term:String, $first: Int, $skip: Int, $code:String) {
    seeUsers(term: $term, first: $first, skip: $skip, code: $code) {
      id
      avatar
      contact
      username
      nickname
      email
      # basket {
      #   id
      #   level
      #   grade
      #   position
      #   height
      #   weight
      #   backNo
      # }
      createdAt
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($term:String!) {
    searchUser(term:$term) {
      id
      avatar
      contact
      username
      nickname
      email
      createdAt
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccountByAdmin(
    $avatar: String!
    $username: String!
    $email: String!
    $contact: String
  ) {
    createAccountByAdmin(
      avatar: $avatar
      username: $username
      email: $email
      contact: $contact
    ) {
      id
      avatar
      username
      email
      contact
      basket {
        id
      }
    }
  }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id: String!
    $userId: String!
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $level: Int
    $grade: String
  ) {
    upsertBasket(
      id: $id
      userId: $userId
      position: $position
      height: $height
      weight: $weight
      backNo: $backNo
      level: $level
      grade: $grade
    ) {
      id
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUserByAdmin(
    $id: String!
    $username: String!
    $email: String!
    $contact: String
  ) {
    editUserByAdmin(
      id: $id
      username: $username
      email: $email
      contact: $contact
    ) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: String!) {
    removeUser(id: $id)
  }
`;
