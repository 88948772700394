import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_USER,
  EDIT_USER,
  UPSERT_BASKET
} from "./EditProfileQueries";
import TeamProfilePresenter from "./EditProfilePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";

const ProfileContainer = ({ match: { params } }) => {
  const userId = params.user;
  const { data, loading, refetch } = useQuery(GET_USER, {
    variables: {
      id: userId.toString()
    }
  });

  const [avatar, setAvatar] = useState();
  const [avatarUrl, setAvatarUrl] = useState();

  const usernameInput = useInput("");
  const nicknameInput = useInput("");
  const emailInput = useInput("");
  const contactInput = useInput("", (newValue) => !isNaN(newValue) );
  const region1Input = useInput("");
  const region2Input = useInput("");
  const pointInput = useInput("", (newValue) => !isNaN(newValue));

  // 농구 관련 정보
  const [basketId, setBasketId] = useState("");
  const positionInput  = useInput("");
  const heightInput = useInput(0,(newValue) => !isNaN(newValue));
  const weightInput = useInput(0,(newValue) => !isNaN(newValue));
  const backNoInput = useInput(0,(newValue) => !isNaN(newValue));

  const authorityInput = useInput("2004");
  const levelInput = useInput(1);
  const gradeInput = useInput("");
  const basketRegion1Input = useInput("");
  const basketRegion2Input = useInput("");


  const [uploading, setUploading] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);
  
  const editUserMutation = useMutation(EDIT_USER);
  const upsertBasketMutation = useMutation(UPSERT_BASKET);

  // const { loading:loadingSearch, data: searchData } = useQuery(SEARCH_PLACE, {
  //   skip: search.value === "",
  //   variables: {
  //     first: 30,
  //     skip: 0,
  //     term: search.value
  //   }
  // });

  // 사용자 프로필 드롭 이벤트
  const onDropAvatar = acceptedFile => {
    setAvatar(acceptedFile);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveAvatar = () => {
    setAvatar(null);
  }

  const handleEditPhotoOpen = type => () => {
    setEditAvatar(!editAvatar);
  };
  

  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes
    setUploading(true);
   
    try {
      let AvatarUrl;
      if(avatar) {
        const formData = new FormData();
        formData.append("avatar", avatar);
  
        const {
          data: { location }
        } = await axios.post("https://weplay2921.cafe24.com/api/user/upload", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        });
        AvatarUrl = location;
      }
  
      const { data : { editUserByAdmin : user }} = await editUserMutation({
        variables: {
          id: userId,
          username: usernameInput.value,
          nickname: nicknameInput.value,
          point: parseInt(pointInput.value),
          regions: [region1Input.value, region2Input.value],
          contact: contactInput.value,
          email : emailInput.value,
          avatar:AvatarUrl,
          authorityCode:authorityInput.value
        }
      });

      if(user.id) {
        const { data : { upsertBasket : basket }} = await upsertBasketMutation({
          variables: {
            id:basketId,
            userId:user.id,
            position: positionInput.value,
            height:parseFloat(heightInput.value),
            weight:parseFloat(weightInput.value),
            backNo:parseInt(backNoInput.value),
            level:parseInt(levelInput.value),
            grade:gradeInput.value,
            regions: [basketRegion1Input.value, basketRegion2Input.value],
          }
        });

        if(basket.id) {
          setAvatarUrl(user.avatar);
          setEditAvatar(false);
          refetch();
          toast.success("수정 완료!");
        }
      }
    } catch(e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
    
  }
  
  return (
    <TeamProfilePresenter
      loading={loading}
      data={data}
      uploading={uploading}
      
      handleEditPhotoOpen={handleEditPhotoOpen}
      editAvatar={editAvatar}
      avatarUrl={avatarUrl}
      setAvatarUrl={setAvatarUrl}
      onDropAvatar={onDropAvatar}
      onRemoveAvatar={onRemoveAvatar}

      handleSubmit={handleSubmit}
      usernameInput={usernameInput}
      nicknameInput={nicknameInput}
      emailInput={emailInput}
      contactInput={contactInput}
      region1Input={region1Input}
      region2Input={region2Input}
      pointInput={pointInput}
      authorityInput={authorityInput}
      
      setBasketId={setBasketId}
      positionInput={positionInput}
      heightInput={heightInput}
      weightInput={weightInput}
      backNoInput={backNoInput}
      levelInput={levelInput}
      gradeInput={gradeInput}
      basketRegion1Input={basketRegion1Input}
      basketRegion2Input={basketRegion2Input}
    />
  );
};

export default withRouter(ProfileContainer);
