import { gql } from "apollo-boost";


export const GET_CUP = gql`
  query seeCup($id: String!) {
    seeCup(id: $id) {
      id
      cupName
      cupStartDate
      cupEndDate
      cupDateInfo
      target
      youtubeUrl
      host
      cupProcess
      playerCnt
      organizer
      support
      sponsor
      joinPrice
      contact
      DepositAccount
      place {
        id
        placeName
        address
      }
    }
  }
`;
