import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TopMenu = styled.div`
  background-image: url(https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg);
  height: 360px;
  background-size: cover;
  position: relative;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CupTitle = styled.h4`
  color:#fff;
  font-size:35px;
  font-weight: 700;
  margin-bottom: 60px;
  text-align:center;
`;


const TabMenu = styled.div`
    display: flex;
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: center;
`;

const Tab = styled.span`
  color:#fff;
  font-size:16px;
  padding: 18px 25px;
  cursor: pointer;
  &.active {
    font-weight:700;
    & > a {
      border-top: 4px solid #5e80ec;
      padding-top: 7px;
    }
  }
  @media (max-width: 500px) {
    padding: 18px 10px;
    font-size:13px;
  }
`;

function CupHeader({ id, active, url, cupName }) {
  return (
    <TopMenu>
      <CupTitle>{cupName}</CupTitle>
      <TabMenu>
        {/* <Tab className={active === "main"?"active":""}><Link to={`/cup/${id}`}>메인</Link></Tab>
        <Tab className={active === "notice"?"active":""}><Link to={`/cup/${id}/notices`}>공지</Link></Tab>
        <Tab className={active === "games"?"active":""}><Link to={`/cup/${id}/games`}>경기</Link></Tab>
        <Tab className={active === "rank"?"active":""}><Link to={`/cup/${id}/rank`}>랭킹</Link></Tab>
        <Tab className={active === "teams"?"active":""}><Link to={`/cup/${id}/teams`}>출전팀 리스트</Link></Tab> */}
        <Tab ><Link to={`/cups`}>대회목록</Link></Tab>
        <Tab className={active === "main"?"active":""}><Link to={`/cup/${id}`}>메인</Link></Tab>
        <Tab className={active === "notice"?"active":""}><Link to={`/cup/${id}/notices`}>공지</Link></Tab>
        <Tab className={active === "games"?"active":""}><Link to={`/cup/${id}/games`}>경기</Link></Tab>
        <Tab className={active === "rank"?"active":""}><Link to={`/cup/${id}/rank`}>랭킹</Link></Tab>
        <Tab className={active === "teams"?"active":""}><Link to={`/cup/${id}/teams`}>출전팀 리스트</Link></Tab>
      </TabMenu>
    </TopMenu>
  );
}

export default CupHeader;
