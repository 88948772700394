import React, { useState, useEffect } from "react";
import MapPresenter from "./MapPresenter";

const MapContainer = ({ isInit, lat, lng, placeName, address, showPlaceName = true, showAddress }) => {
  // const [lat, setLat] = useState(0);
  // const [lng, setLng] = useState(0);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       function(position) {
  //         setLat(position.coords.latitude);
  //         setLng(position.coords.longitude);
  //       },
  //       function(error) {
  //         console.error(error);
  //       }
  //     );
  //   } else console.log("Geolocation is not supported by this browser.");
  //   return () => {
  //     console.log("cleanup");
  //   };
  // }, []);

  return (
    <MapPresenter lat={lat} lng={lng} isInit={isInit} placeName={placeName} address={address} showPlaceName={showPlaceName} showAddress={showAddress} />
  );
};

export default MapContainer;
