import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { HashRouter as Router, BrowserRouter as BRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { gql } from "apollo-boost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Theme from "../Styles/Theme";
import GlobalStyles from "../Styles/GlobalStyles";
import Routes from "./Routes";
// import Header from "./Header";
//import AuthBackground from "../assets/img/splash_bg.jpg";
import Loader from "./Loader";



const ADMIN_QUERY = gql`
  {
    isAdminIn @client
  }
`;


const Container = styled.div`

  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-height:100vh;

  /* max-width:1280px;*/
`;

const AuthBackImg = styled.img`
  position:fixed;
  left:0;
  top:0px;
  width:100%;
  height:100%;
`;



// const getBackground = isLoggedIn => {
//   let colorCode;
//   if (isLoggedIn) {
//     colorCode = "#252C33";
//   } else {
//     colorCode = "transparent";
//   } 

//   return `
//     background-color:${colorCode}
//   `;
// };

// const ContentWrapper = styled.div`
//   ${props => getBackground(props.isLoggedIn)};
//   min-height: calc(100vh - 56px);
// `;



export default () => {

  const {
    data: { isAdminIn }
  } = useQuery(ADMIN_QUERY);

  localStorage.setItem("sport","1001");
  return (
    <ThemeProvider theme={Theme}>
      <>
        <GlobalStyles />
        <BRouter>
          <Container>
            <Routes isAdminIn={isAdminIn}/>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} />
          </Container>
        </BRouter>
      </>
    </ThemeProvider>
  );
};
