import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_PLACES,
  DELETE_PLACE
} from "./PlaceListQueries";
import PlaceListPresenter from "./PlaceListPresenter";

import { useQuery, useMutation } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";

const desktopColumn = [
  { title: "경기장명", field: "placeName", headerStyle: { fontWeight: "800" } },
  {
    title: "주소",
    field: "address",
    editable: "never",
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "담당자",
    field: "manager",
    initialEditValue: "",
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "연락처",
    field: "contact",
    initialEditValue: "",
    headerStyle: { fontWeight: "800" }
  },
  {
    title: "인증여부",
    field: "isAuthenticated",
    lookup: { true: "인증", false: "미인증" },
    headerStyle: { fontWeight: "800" }
  }
];

const mobileColumn = [
  { title: "이름", field: "placeName" },
  { title: "주소", field: "address" }
];


export default withRouter(({ history }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [canMore, setCanMore] = useState(true);
  const [first] = useState(10);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);

  const searchInput = useInput("");

  const { data, loading, refetch, fetchMore } = useQuery(SEE_PLACES, {
    variables: {
      term:searchInput.value,
      first: first,
      skip: skip
    }
  });

  const [places, setPlaces] = useState({
    columns: desktopColumn,
    data: []
  });

  const deletePlaceMutation = useMutation(DELETE_PLACE);

  useEffect(() => {
    if (loading) setPlaces({ columns: matches ? mobileColumn : desktopColumn, ...places });
    else if (!loading && data && data.seePlaces)
      setPlaces({ columns: matches ? mobileColumn : desktopColumn, data: data.seePlaces });
  }, [matches, data, loading]);
 

  const onScroll = ({currentTarget}) => {

    if (
      canMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seePlaces.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
          if(fetchMoreResult.seePlaces.length === 0) {
            toast.error("조회할 데이터가 없습니다.");
            setCanMore(false);
          }  
          else
          setPlaces({...places, data:[...places.data, ...fetchMoreResult.seePlaces]});

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seePlaces: [...prev.seePlaces, ...fetchMoreResult.seePlaces]
          });
        }
      });
    }
  };
  // const handleCreate = async () => {
  //   try {
  //     if(placeNameInput.value === "") {
  //       toast.error("장소 이름을 입력해주세요.");
  //     }
  //     else {
  //       if (!uploading) {
  //         setUploading(true);
  //         handleDialogClose();
  //         const {
  //           data: { createPlace }
  //         } = await createPlaceMutation({
  //           variables: {
  //             lat: latS,
  //             lng: lngS,
  //             placeName: placeNameInput.value,
  //             address: addressS,
  //             shortAddress: shortAddressS,
  //             manager: manager.value,
  //             contact: contact.value
  //           }
  //         });
          
  //         const data = [...places.data];
  //         data.pop();
  //         data.unshift(createPlace);
  //         setPlaces({ ...places, data });

  //         refetch();
  //         // const data = [...places.data];
  //         // data.push(createPlace);
  //         // setPlaces({ ...places, data });
  //       }
  //     }
      
  //   } catch (e) {
  //     if (e.toString().indexOf("already") !== -1) {
  //       toast.error("이미 등록된 장소입니다.");
  //     } else {
  //       toast.error("오류가 발생하였습니다.");
  //     }
  //   } finally {
  //     setUploading(false);
  //   }
  // };

  const onCreatePage = () => {

    history.push(`/place/create`);
  };

  const onUpdatePage = id => {
    console.log(id);
    history.push(`/place/${id}`);
  };


  const handleDelete = async oldData => {
    const {data : { deletePlace }} = await deletePlaceMutation({
      variables: { id: oldData.id }
    });

    if(deletePlace) {
      const data = [...places.data];
      data.splice(data.findIndex(i => i.id === oldData.id), 1);
      setPlaces({ ...places, data });

      refetch();
    } else {
      toast.error("해당 경기장을 사용 중입니다.");
    }
    
  };

  return (
    <PlaceListPresenter
      loading={loading}
      data={data}
      loadMore={loadMore}
      matches={matches}
      onScroll={onScroll}
      onCreatePage={onCreatePage}
      onUpdatePage={onUpdatePage}
      handleDelete={handleDelete}
      places={places}
      setPlaces={setPlaces}
      canMore={canMore}
      setCanMore={setCanMore}
      searchInput={searchInput}
    />
  );
});

//export default withRouter(UserListContainer);
