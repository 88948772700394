import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_TEAMS,
  CREATE_ACCOUNT,
  EDIT_TEAM,
  UPSERT_BASKET,
  DELETE_TEAM
} from "./TeamListQueries";
import TeamListPresenter from "./TeamListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";

const desktopColumn = [
  { title: "팀이름", field: "teamName", headerStyle:{ fontWeight:"800"} },
  { title: "팀레벨", field: "level", cellStyle:{ width:"120px"}, headerStyle:{ fontWeight:"800"} },
  { title: "승패", editable: "never", headerStyle:{ fontWeight:"800"}, render: rowData => {
    return (
      rowData && (
        <span>{rowData.win} / {rowData.lose}</span>
      )
    );
  } 
  },
  { title: "경기장", editable: "never", field: "place.placeName", headerStyle:{ fontWeight:"800"} },
  { title: "연락처", field: "contact", initialEditValue: "", headerStyle:{ fontWeight:"800"} }
];

const mobileColumn = [
  { title: "팀이름", field: "teamName" },
  { title: "레벨", field: "level" },
  { title: "연락처", field: "contact" },
];


export default withRouter(({ history }) => {
  const [first] = useState(15);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const searchInput = useInput("");


  const { data, loading, refetch, fetchMore } = useQuery(SEE_TEAMS, {
    variables: {
      term:searchInput.value,
      first: first,
      skip: skip
    }
  });

  const [teams, setTeams] = useState({
    columns: matches ? mobileColumn : desktopColumn,
    data: []
  });

  const deleteTeamMutation = useMutation(DELETE_TEAM);

  useEffect(() => {
    if(loading)
      setTeams({columns : matches ? mobileColumn : desktopColumn, ...teams});
    else if(!loading && data && data.seeTeams)
      setTeams({columns : matches ? mobileColumn : desktopColumn, data:data.seeTeams});
  }, [matches, data, loading])


  const onScroll = ({currentTarget}) => {

    if (
      canMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeTeams.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
          if(fetchMoreResult.seeTeams.length === 0)
            toast.error("조회할 데이터가 없습니다.");
          else
            setTeams({...teams, data:[...teams.data, ...fetchMoreResult.seeTeams]});

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeTeams: [...prev.seeTeams, ...fetchMoreResult.seeTeams]
          });
        }
      });
    }
  };


  const handleDelete = async oldData => {
    await deleteTeamMutation({
      variables: { id: oldData.id, action:"DELETE" }
    });

    refetch();
  };

  const onCreatePage = () => {
    history.push(`/team/create`);
  };

  const onUpdatePage = id => {
    history.push(`/team/${id}`);
  };

  return (
    <TeamListPresenter
      loading={loading}
      data={data}
      loadMore={loadMore}
      onCreatePage={onCreatePage}
      onScroll={onScroll}
      handleDelete={handleDelete}
      teams={teams}
      setTeams={setTeams}
      matches={matches}
      canMore={canMore}
      searchInput={searchInput}
      setCanMore={setCanMore}
      onUpdatePage={onUpdatePage}
    />
  );
});

