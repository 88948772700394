import { gql } from "apollo-boost";


export const GET_BANNERS = gql`
  query seeBanners {
    seeBanners {
      id
      url
      text
      link
      dueDate
    }
  }
`;

export const UPSERT_BANNERS = gql`
  mutation upsertBanners(
      $banners:String!
    ) {
      upsertBanners(
        banners:$banners
      ) 
    }
`;

export const DELETE_BANNER = gql`
  mutation deleteBanner( $id:String! ) {
    deleteBanner(id:$id) {
      id
    }
  }
`;

