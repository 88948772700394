import React, {useState, useEffect} from "react";
import PostCodePresenter from "./PostCodePresenter";

const PostCodeContainer = ({ setAddressInfo, defaultValue, text="주소 검색", search=true }) => {
  const themeObj = {
    bgColor: "#162525", //바탕 배경색
    searchBgColor: "#162525", //검색창 배경색
    contentBgColor: "#162525", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    pageBgColor: "#162525", //페이지 배경색
    textColor: "#FFFFFF", //기본 글자색
    queryTextColor: "#FFFFFF", //검색창 글자색
    outlineColor: "#444444" //테두리
  };

  const kakao = window.kakao;
  const daum = window.daum;
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  
  const onDaumPostCode = () => {
    var mapContainer = document.getElementById("map"), // 지도를 표시할 div
      mapOption = {
        center: new kakao.maps.LatLng(37.537187, 127.005476), // 지도의 중심좌표
        level: 5 // 지도의 확대 레벨
      };

    var map = new kakao.maps.Map(mapContainer, mapOption);
    //주소-좌표 변환 객체를 생성
    var geocoder = new kakao.maps.services.Geocoder();
    //마커를 미리 생성
    var marker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(37.537187, 127.005476),
      map: map
    });

    new daum.Postcode({
      theme: themeObj,
      oncomplete: function(data) {
        var addr = data.address; // 최종 주소 변수
        console.log(data);
        // 주소 정보를 해당 필드에 넣는다.
        document.getElementById("sample5_address").value = addr;
        // 주소로 상세 정보를 검색
        geocoder.addressSearch(data.address, function(results, status) {
          // 정상적으로 검색이 완료됐으면
          console.log(results, status);
          

          if (status === kakao.maps.services.Status.OK) {
            var result = results[0]; //첫번째 결과의 값을 활용

            // 해당 주소에 대한 좌표를 받아서
            var coords = new kakao.maps.LatLng(result.y, result.x);

            // 해당 주소로 State를 설정
            setAddressInfo(
              data.buildingName,
              data.address,
              data.sido + " " + data.sigungu,
              result.y,
              result.x
            );

            // 지도를 보여준다.
            mapContainer.style.display = "block";
            map.relayout();
            // 지도 중심을 변경한다.
            map.setCenter(coords);
            // 마커를 결과값으로 받은 위치로 옮긴다.
            marker.setPosition(coords);
          }
        });
      }
    }).open();
  };

  return (
    <PostCodePresenter
      kakao={kakao}
      daum={daum}
      text={text}
      onDaumPostCode={onDaumPostCode}
      search={search}
      value={value}
    />
  );
};

export default PostCodeContainer;
