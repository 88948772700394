import { gql } from "apollo-boost";

export const SEARCH_USER = gql`
  query searchUserNoHost($skip: Int, $first: Int, $term: String!) {
    searchUserNoHost(skip: $skip, first: $first, term: $term) {
      id
      avatar
      email
      username
      contact
    }
  }
`;


export const CREATE_CUP_USER = gql`
  mutation createCupUser(
    $teamId:String!
    $authority:String!
    $email:String!
    $contact:String
    $username:String!
    $backNo:Int
    $age:String
    $height:Int
    $weight:Int
    $position:String
  ) {
    createCupUser(
      teamId:$teamId
      authority:$authority
      email:$email
      contact:$contact
      username:$username
      backNo:$backNo
      age:$age
      height:$height
      weight:$weight
      position:$position
    ) {
      id
      email
      username
      age
      basket {
        id
        backNo
        height
        weight
        position
      } 
    }
  }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id: String!
    $userId: String!
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $level: Int
    $grade: String
  ) {
    upsertBasket(
      id: $id
      userId: $userId
      position: $position
      height: $height
      weight: $weight
      backNo: $backNo
      level: $level
      grade: $grade
    ) {
      id
    }
  }
`;

export const GET_TEAM = gql`
  query seeFullTeam($id: String!, $cupId: String!) {
    seeFullTeam(id: $id) {
      id
      teamName
      logo
      agent
      profile {
        id
        url
      }
      contact
      bio
      host {
        id
        avatar
        username
        contact
        email
        point
        userStatAvg {
          id
          score
          rebound
          assist
          blocking
          steal
          turnOver
          foul
          freeShot
          freeShotTotal
        }
        basket {
          id
          height
          weight
          backNo
          position
        }
      }
      users {
        id
        email
        avatar
        username
        contact
        age
        point
        userStatAvg {
          id
          score
          rebound
          assist
          blocking
          steal
          turnOver
          foul
          freeShot
          freeShotTotal
        }
        basket {
          id
          height
          weight
          backNo
          position
        }
      }
    }
    seeCup(id: $cupId) {
      id
      cupName
      playerCnt
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation deleteProfile($id: String!) {
    deleteProfile(id: $id)
  }
`;

export const EDIT_TEAM = gql`
  mutation editTeamByAdmin(
    $id: String!
    $agent:String
    $teamName: String
    $contact: String
    $action: String!
    $bio: String
    $logo: String
    $profiles: [String]
  ) {
    editTeamByAdmin(
      id: $id
      agent:$agent
      teamName: $teamName
      contact: $contact
      action: $action
      bio: $bio
      logo: $logo
      profiles: $profiles
    ) {
      id
      profile {
        id
        url
      }
      logo
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUserByAdmin(
    $id: String!
    $username: String!
    $email: String!
    $contact: String!
    $point:Int!) {
    editUserByAdmin(
      id: $id
      username: $username
      email: $email
      contact: $contact
      point: $point
    ) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: String!) {
    removeUser(id: $id)
  }
`;
