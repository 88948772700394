import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  CREATE_PLACE,
  EDIT_BASKET_PLACE
} from "./CreatePlaceQueries";
import TeamProfilePresenter from "./CreatePlacePresenter";
import {  useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import axios from "axios";

const TeamProfileContainer = ({ history }) => {

  // 사진 및 업로드 관련 상태 variable
  const [pictures, setPictures] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [editPicture, setEditPicture] = useState(false);

  // Mutation
  const createPlacemutation = useMutation(CREATE_PLACE);
  const createBasketPlacemutation = useMutation(EDIT_BASKET_PLACE);

  // 경기장 정보 variables
  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();

  const placeNameInput = useInput("");
  const contactInput = useInput("", (newValue) => !isNaN(newValue) );
  const managerInput = useInput("");
  const isFreeInput = useInput("false");
  const isAuthenticatedInput = useInput("false");

  // 농구 경기장 정보
  const sizeInput = useInput("");
  const floorMaterialInput = useInput("");
  const isGroundLine = useInput("false");
  const isGroundLight = useInput("false");
  const isNet = useInput("false");
  const isShowerRoom = useInput("false");
  const isParkingLot = useInput("false");

  // 경기장 사진 드롭 시 이벤트
  const onDropPictures = acceptedFiles => {
    const data = pictures;
    data.push(acceptedFiles);
    setPictures(data);
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemovePictures = removeFile => {
    const data = pictures;
    data.splice(data.findIndex(i => i.upload.uuid === removeFile.upload.uuid), 1);
    setPictures( data );
  }

  const handleEditPhotoOpen = () => {
    setEditPicture(!editPicture);
  };

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    placeNameInput.setValue(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };


  const _ConvertBoolean = value => (value === "true");

  //console.log(data);

  // 전체 업로드
  const handleSubmit = async () => {
    // POST to a test endpoint for demo purposes
    setUploading(true);
   
    let PictureUrls;
   
    if(pictures && pictures.length > 0) {
      const formData = new FormData();
      pictures.forEach(file => {
        formData.append("place", file);
      }); 

      const { data } = await axios.post("https://weplay2921.cafe24.com/api/place/picture/upload", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      });

      PictureUrls = data;     
    }

    console.log({
      lat:latS,
      lng:lngS,
      placeName:placeNameInput.value,
      address:addressS,
      shortAddress:shortAddressS,
      manager:managerInput.value,
      contact: contactInput.value,
      picture : PictureUrls,
      isFree : _ConvertBoolean(isFreeInput.value),
      isAuthenticated : _ConvertBoolean(isAuthenticatedInput.value),
      size : sizeInput.value,
      floorMaterial: floorMaterialInput.value,
      net:_ConvertBoolean(isNet.value),
      groundLine : _ConvertBoolean(isGroundLine.value),
      groundLight: _ConvertBoolean(isGroundLight.value),
      showerRoom : _ConvertBoolean(isShowerRoom.value),
      parkingLot: _ConvertBoolean(isParkingLot.value)
    });

    try {
      const { data : { createPlace : place }} = await createPlacemutation({
        variables: {
          lat:latS,
          lng:lngS,
          placeName:placeNameInput.value,
          address:addressS,
          shortAddress:shortAddressS,
          manager:managerInput.value,
          contact: contactInput.value,
          pictures : PictureUrls,
          isFree : _ConvertBoolean(isFreeInput.value),
          isAuthenticated : _ConvertBoolean(isAuthenticatedInput.value),
        }
      });

      //console.log(place);

      if(place.id) {
  
        const { data : { editBasketPlace : basketPlace }} = await createBasketPlacemutation({
          variables: {
            placeId: place.id,
            size : sizeInput.value,
            floorMaterial: floorMaterialInput.value,
            net:_ConvertBoolean(isNet.value),
            groundLine : _ConvertBoolean(isGroundLine.value),
            groundLight: _ConvertBoolean(isGroundLight.value),
            showerRoom : _ConvertBoolean(isShowerRoom.value),
            parkingLot: _ConvertBoolean(isParkingLot.value)
          }
        });
        
        if(basketPlace.id) {
          toast.success("업로드 완료!");
          setTimeout(()=> {
            history.push(`/place/${place.id}`);
          }, 1000);
        }
      }
    }
    catch(e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    }
    finally {
      setUploading(false);
    }
   
  }
  
  
  return (
    <TeamProfilePresenter
      setAddressInfo={setAddressInfo}
      uploading={uploading}

      editPicture={editPicture}
      handleEditPhotoOpen={handleEditPhotoOpen}
     
      onDropPictures={onDropPictures}
      onRemovePictures={onRemovePictures}
      handleSubmit={handleSubmit}

      placeNameInput={placeNameInput}
      contactInput={contactInput}
      managerInput={managerInput}
      addressS={addressS}
      setAddress={setAddress}
      isFreeInput={isFreeInput}
      isAuthenticatedInput={isAuthenticatedInput}

      sizeInput={sizeInput}
      floorMaterialInput={floorMaterialInput}
      isGroundLine={isGroundLine}
      isGroundLight={isGroundLight}
      isNet={isNet}
      isShowerRoom={isShowerRoom}
      isParkingLot={isParkingLot}
    />
  );
};

export default withRouter(TeamProfileContainer);
