import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import AdminHeader from "../../Components/Header/AdminHeader";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

import { EditorState, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';

import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Input from "../../Components/Input";
import Loader from "../../Components/Loader";
import Progress from "../../Components/Progress";


const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  color: #000;
`;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 20px 0 40px;
  background-color:#fff;
  &:first-child {
    padding: 50px 40px 20px;
  }
`;

const TitleWrapper = styled.div`
margin:0 auto;
 & > h4 {
  margin-bottom: 15px;
  font-weight:500;
  font-size:16px;
 }
 max-width: 775px !important;
 
`;


const EditorWrapper = styled.div`
  width: 100%;
  display: flex;
  font-family: 'NanumSqure';
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & span.rdw-image-imagewrapper > img {
    max-width:100%;
    height:auto!important;
  }
`;

const UploadButton1 = styled.button`
  background-color: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 300;
  color: #fff;
  width: 200px;
  margin: 30px auto;
`;

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const NotiLink = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} >
      {props.children}
    </a>
  );
};

function findImageEntities(contentBlock, callback, contentState) {

  

  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      console.log(entityKey !== null && contentState.getEntity(entityKey));
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'IMAGE'
      );
    },
    callback
  );
}

const Image = (props) => {
  const {
    height,
    src,
    width,
  } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <p><img src={src} height={height} width={width} alt={"공지사항 이미지"}/></p>
  );
};

export default ({
  data,
  loading,
  cupId,
  noticeId,
  title,
  body,
  handleSubmit,
  handleEdit,
  uploading,
  editorState,
  setEditorState,
  onEditorStateChange,
  uploadImageCallBack
}) => {
  if(noticeId === undefined) {


  
    return (
      <Wrapper>
        <Helmet>
          <title>공지사항 | WePlay</title>
        </Helmet>
        <AdminHeader />
        {uploading && <Progress />}

        <ContentWrapper>
          <Section>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                공지사항 작성
                <Link to={`/cup/${cupId}/notices`}>
                  <Button variant="contained" color="primary">
                    목록보기
                  </Button>
                </Link>
              </Typography>
            </Grid>
          </Section>
          <Section>
            <TitleWrapper>
              <h4>제목</h4>
              <Input value={title.value} onChange={title.onChange} />
            </TitleWrapper>
            <EditorWrapper>
              <Editor
                editorState={editorState}
                wrapperClassName="home-wrapper"
                editorClassName="home-editor"
                onEditorStateChange={onEditorStateChange}
                localization={{
                  locale: "ko"
                }}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  image: {
                    uploadCallback: uploadImageCallBack,
                    previewImage: true,
                    uploadEnabled: true,
                    urlEnabled: false,
                    popupClassName:"notice-upload",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: '300',
                      width: '300',
                    },
                  }
                }}
              />
            </EditorWrapper>
            <Grid container justify="center" spacing={2}>
              <UploadButton1 onClick={handleSubmit}>업로드</UploadButton1>
            </Grid>
          </Section>
        </ContentWrapper>
      </Wrapper>
    );
  }
  else {
    if (loading) {
      return (
        <Wrapper>
          <Helmet>
            <title>공지사항 | WePlay</title>
          </Helmet>
          <AdminHeader />
          <Loader />
        </Wrapper>
      );
    }
    else if (!loading && data && data.seeFullNotice) {

      //console.log(data);


      useEffect(() => {


        if(data.seeFullNotice.text) {
          title.setValue(data.seeFullNotice.title);
          body.setValue(data.seeFullNotice.text);
  
          const decorator = new CompositeDecorator([
            {
              strategy: findLinkEntities,
              component: NotiLink,
            },
            {
              strategy: findImageEntities,
              component: Image,
            },
          ]);
  
          const blocksFromHTML = htmlToDraft(data.seeFullNotice.text);
          const state = ContentState.createFromBlockArray(blocksFromHTML);
  
          setEditorState(EditorState.createWithContent(
            state,
            decorator,
          ));
        }

        
      }, [])
    
      return (
        <Wrapper>
          <Helmet>
            <title>공지사항 | WePlay</title>
          </Helmet>
          <AdminHeader />
          {uploading && <Progress />} 
    
          <ContentWrapper>
            <Section>
              <Grid item>
                <Typography variant="h4" gutterBottom>
                  공지사항 수정
                  <Link to={`/cup/${cupId}/notices`}>
                    <Button variant="contained" color="primary">
                      목록보기
                    </Button>
                  </Link>
                </Typography>
              </Grid>
            </Section>
            <Section>
              <TitleWrapper>
                <h4>제목</h4>
                <Input value={title.value} onChange={title.onChange} />
              </TitleWrapper>
              <EditorWrapper >
                <Editor
                  editorState={editorState}
                  wrapperClassName="home-wrapper"
                  editorClassName="home-editor"
                  onEditorStateChange={onEditorStateChange}
                  localization={{
                    locale: "ko"
                  }}
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      uploadCallback: uploadImageCallBack,
                      previewImage: true,
                      alt: { present: false, mandatory: false },
                    }
                  }}
                />
              </EditorWrapper>
              <Grid container justify="center" spacing={2}>
                <UploadButton1 onClick={handleEdit}>수정</UploadButton1>
              </Grid>
            </Section>
          </ContentWrapper>
        </Wrapper>
      );
    }
  }
  

  
};
