import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loader from "../../Components/Loader";
import BoldText from "../../Components/BoldText";
import SearchCard from "../../Components/SearchCard";
import AdminHeader from "../../Components/Header/AdminHeader";

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Helmet } from "rl-react-helmet";


const Wrapper = styled.div`
  background-color: #fafafa;
  min-height:100vh;
  .slick-next:before, .slick-prev:before {
    color: #000;
  };
`;

const EBoldText = styled(BoldText)`
  color:#000;
  font-family:"NanumSquare";
`;
const UserSection = styled.div`
  padding:30px 30px;
`;

const TeamSection = styled(UserSection)`
  
`;


const Divider = styled.hr`
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
`;

const HeaderText = styled.h4`
  color:#000;
  padding:10px 0px 20px;
  font-weight:800;
  font-size:20px;
`;

const SearchPresenter = ({ searchTerm, loading, data }) => {
  if (searchTerm === undefined) {
    return (
      <Wrapper>
        <Helmet>
          <title>검색 | WePlay</title>
        </Helmet>
        <BoldText text="Search for something" />
      </Wrapper>
    );
  } else if (loading === true) {
    return (
      <Wrapper>
        <Helmet>
          <title>검색 | WePlay</title>
        </Helmet>
        <Loader />
      </Wrapper>
    );
  } else if (data && data.searchUser && data.searchTeam) {
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 6,
      slidesToScroll: 6,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            arrows: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots: true,
          }
        }
      ]
    };

    const TeamSettings = {
      className: "center",
      infinite: false,
      centerPadding: "60px",
      slidesToShow: 2,
      speed: 500,
      rows: 2,
      slidesPerRow: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Wrapper>
        <Helmet>
          <title>검색 | WePlay</title>
        </Helmet>
        <AdminHeader />
          <UserSection>
          <HeaderText>회원 검색 결과</HeaderText>
          {data.searchUser.length === 0 ? (
            <EBoldText text={"해당 회원을 찾지 못했습니다."} />
            ) : (
            <Slider {...settings}>
              {data.searchUser.map(user => (
                <SearchCard
                  key={user.id}
                  name={user.username}
                  url={user.avatar}
                  id={user.id}
             
                  type={"user"}
                />
              ))}
            </Slider>
          )}
          </UserSection>
        <Divider />

        <TeamSection>
        <HeaderText>회원 검색 결과</HeaderText>
        {data.searchTeam.length === 0 ? (
          <EBoldText text={"해당 팀을 찾지 못했습니다."} />
          ) : (
          <Slider {...TeamSettings}>
            {data.searchTeam.map(team => (
              <div  key={team.id}>
                <SearchCard
                  id={team.id}
                  type={"team"}
                  size={"lmd"}
                  name={team.teamName}
                  userCount={team.userCount}
                  level={team.level}
                  url={
                    team.logo
                      ? team.logo
                      : team.profile && team.profile.length > 0
                      ? team.profile[0].url
                      : ""
                  }
                />
              </div>
              
            ))}
          </Slider>
          )}
        </TeamSection>
      </Wrapper>
    );
  }
};

SearchPresenter.propTypes = {
  searchTerm: PropTypes.string,
  loading: PropTypes.bool
};

export default SearchPresenter;
