import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    * {
        box-sizing:border-box;
    }

   


    /* @font-face {
      font-family: "NanumSqure";
      font-weight:400;
      src: url("/assets/fonts/NanumSquareRoundR.woff2") format("woff2"),
      url("/assets/fonts/NanumSquareRoundR.woff") format("woff"),
      url("/assets/fonts/NanumSquareRoundR.ttf") format("truetype");
    }

    @font-face {
      font-family: "NanumSqure";
      font-weight:500;
      src: url("../assets/fonts/NanumSquareRoundB.woff2") format("woff2"),
      url("../assets/fonts/NanumSquareRoundB.woff") format("woff"),
      url("../assets/fonts/NanumSquareRoundB.ttf") format("truetype");
    }

    @font-face {
      font-family: "NanumSqure";
      font-weight:600;
      src: url("../assets/fonts/NanumSquareRoundEB.woff2") format("woff2"),
      url("../assets/fonts/NanumSquareRoundEB.woff") format("woff"),
      url("../assets/fonts/NanumSquareRoundEB.ttf") format("truetype");
    } */

    @font-face {
      font-family: "NanumSqure";
      font-weight:400;
      src: url(${require("../assets/fonts/NanumSquareRoundR.woff2")}); 
    }

    @font-face {
      font-family: "NanumSqure";
      font-weight:500;
      src: url(${require("../assets/fonts/NanumSquareRoundB.woff2")}); 
    }
    
    @font-face {
      font-family: "NanumSqure";
      font-weight:600;
      src: url(${require("../assets/fonts/NanumSquareRoundEB.woff2")}); 
    }
    
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      font-family:"NanumSqure";
      vertical-align: baseline;
    }

    body {
        background-color:${props=>props.theme.bgColor};
        /* color:${props=>props.theme.whiteColor}; */
        font-size:14px;
        font-family:"NanumSqure";
        /* overflow: hidden; */
    }
    a {
        color:inherit;
        text-decoration:none;
    }

    /* svg path{
      fill:#fff;
    } */
    .MuiFormLabel-root {
      font-family:"NanumSqure";
    }

    .MuiTypography-body1 {
      font-family:"NanumSqure";
    }

    .MuiInputBase-root > svg path{
      fill:#000;
    }

    .MuiCircularProgress-colorPrimary {
      position: fixed;
      left: calc(50% - 20px);
    }

    .MuiListItemIcon-root {
      min-width:30px;
    }



    /* .MuiInputBase-input {
      padding: 6px 7px!important;
    } */
    .MuiFormControl-root {
      background: #fff;
    }



    /* .slick-slide {
      padding:10px;
      float: left;
      height: 100%;
      min-height: 1px;
      transition: all 0.3s linear;
    }
    .slick-slide.slick-current {
      transition: all 0.3s linear;
    } */

    /* .MuiDivider-root {
      background-color:rgba(255,255,255,0.2)!important;
    } */

    .dropzone.filepicker {
      border: 2px dashed #C7C7C7;
      padding: 5px;
      margin-top: 10px;
      min-height: 60px;
      text-align: center;
      border-radius: 5px;
      background-color: #E1E1E1;
    }

    .dropzone.filepicker .dz-default{
      cursor: pointer;
      margin: 2em 0;
      text-align: center;
    }
    .dropzone.filepicker > .dz-preview {
      margin: 16px;
      display: inline-block;
      position: relative;
      min-height: 100px;
      vertical-align: top;
    }
    .dropzone.filepicker > .dz-preview.dz-image-preview {
      background: none;
    }
    .dropzone.filepicker > .dz-preview .dz-image {
      width: 120px;
      height: 120px;
      display: block;
      z-index: 10;
      overflow: hidden;
      position: relative;
      border-radius: 5px;
    }

    .dropzone.filepicker > .dz-preview .dz-success-mark, .dropzone.filepicker > .dz-preview .dz-error-mark {
      top: 50%;
      left: 50%;
      display: block;
      opacity: 0;
      z-index: 500;
      position: absolute;
      margin-top: -27px;
      margin-left: -27px;
      pointer-events: none;
    }

    .dropzone.filepicker > .dz-preview .dz-success-mark svg, .dropzone.filepicker > .dz-preview .dz-error-mark svg {
      width: 54px;
      height: 54px;
      display: block;
    }

    .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
      overflow: hidden;
    text-overflow: ellipsis;
    }
    .dropzone .dz-preview .dz-details .dz-filename {
      white-space: nowrap;
    }
    .dropzone.dz-clickable {
      cursor: pointer;
    }
    .dropzone.dz-clickable * {
      cursor: default;
    }
    .dropzone .dz-preview .dz-details .dz-size {
      margin-bottom: 1em;
    font-size: 16px;
    }
    .dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
      border: 1px solid transparent;
    }
    .dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
      background-color: rgba(255,255,255,0.4);
      padding: 0 0.4em;
      border-radius: 3px;
    }
    .dropzone .dz-preview.dz-image-preview .dz-details {
      transition: opacity 0.2s linear;
    }
    .dropzone .dz-preview .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0,0,0,0.9);
      line-height: 150%;
    }
    .dropzone .dz-preview.dz-processing .dz-progress {
      opacity: 1;
    }
    .dropzone .dz-preview.dz-complete .dz-progress {
      opacity: 0;
    }
    .dropzone .dz-preview .dz-progress {
      opacity: 0;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255,255,255,0.9);
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden;
    }
    .dropzone .dz-preview .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none;
      padding-top:8px;
    }
    .dropzone .dz-preview .dz-progress .dz-upload {
      transition: width 300ms ease-in-out;
      background: linear-gradient(to bottom, #666, #444);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    .dropzone .dz-preview:hover .dz-details {
      opacity:1;
    }
    .dropzone .dz-preview:hover .dz-image img {
      filter: blur(8px);
      transform: scale(1.05, 1.05);
    }

    .home-wrapper {
      margin-top: 20px !important;
      max-width: 775px !important;
      display: block !important;
      min-height: 400px !important;
    }

    .home-editor {
      border: 1px solid #F1F1F1 !important;
      padding: 5px !important;
      border-radius: 2px !important;
      height: 80% !important;
    }

    .datepicker-modal {
      position:fixed!important;
    }
    .datepicker.default {
      top: 50%;
      left: 50%;
      height: 350px;
      border-radius:8px;
      max-width: 500px;
      transform: translate(-50%, -50%);
    }
    .datepicker-header {
      box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }

    .datepicker.default .datepicker-wheel {
      border-top: none!important;
      border-bottom: 2px solid #227fff!important;
    }
    .datepicker .datepicker-scroll>li {
      height: 80px!important;
      line-height: 90px!important;
    }
    .datepicker-scroll {
      top: -230px;
      position: relative;
    }
    .datepicker .datepicker-navbar {
      display: flex;
      justify-content: space-around;
      flex-direction: row-reverse;
      padding-top: 10px;
    }
    .datepicker-navbar-btn {
      background-color: #d5d5d5;
      border: 1px solid #d5d5d5;
      cursor: pointer;
      font-size: 20px;
      padding: 15px 0px;
      text-align: center;
      font-weight: 500;
      font-family: "NanumSqure";
      border-radius: 7px;
      &:first-child {
        background-color: ${props => props.theme.yellowColor};
        border: 1px solid ${props => props.theme.yellowColor};
      }
      color:#000!important;
      width: calc(50% - 20px);
      left:unset;
      transform:unset;
      position:relative;
      margin-top: 10px;
    }

    .datepicker .datepicker-content {
      box-shadow: inset 0px -4px 14px 0px rgba(0, 0, 0, 0.2);
    }
   
    .fileContainer .uploadPictureContainer {
      width:auto!important;
    }
    .fileContainer {
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.55)!important;
    }
`