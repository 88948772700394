import React from "react";
import styled from "styled-components";
import Loader from "../Loader";

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  /* visibility: hidden; */
`;

const Map = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 30vh;
  display: none;
`;

const AddressInput = styled.input`
  margin: 0.2em 0;
  width: 100%;
  height: 35px;
  border: 1px solid #ccc;
  border-color: #dbdbdb #d2d2d2 #d0d0d0 #d2d2d3;
  box-shadow: inset 0.1em 0.1em 0.15em rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  border-radius: 4px;
  background-color: ${props => props.theme.whiteColor};
  line-height: 1.25em;
  padding: 5px 15px;
  outline: 0;
  color: #3a4149;
`;

const AddressBtn = styled.input`
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0.4em 0em;
  border: 1px solid #ccc;
  border-color: #dbdbdb #d2d2d2 #b2b2b2 #d2d2d3;
  cursor: pointer;
  color: #464646;
  border-radius: 0.2em;
  vertical-align: middle;
  font-size: 1em;
  line-height: 1.25em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#f2f2f2)
  );
  background-image: -moz-linear-gradient(top, #fff, #f2f2f2);
  background-image: -o-gradient(top, #fff, #f2f2f2);
  background-image: linear-gradient(top, #fff, #f2f2f2);
`;

export default ({
  onDaumPostCode,
  kakao,
  daum,
  value,
  text,
  search
}) => {
  //const kakao = window.kakao;

  //console.log(kakao);
  if (kakao && daum) {
    return (
      <>
        {value ? (
          <AddressInput
            type="text"
            id="sample5_address"
            label="주소"
            defaultValue={value}
            readOnly
          />
        ) : (
          <AddressInput
            readOnly
            type="text"
            id="sample5_address"
            defaultValue={''}
            placeholder="주소"
          />
        )}
        {search && (
          <AddressBtn type="button" onClick={onDaumPostCode} value={text} />
        )}
        <br></br>
        <Map id="map" />
      </>
    );
  } else {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }
};
