import { gql } from "apollo-boost";

export const SEE_CUPS = gql`
  query seeCups($isAll:Boolean) {
    seeCups(isAll:$isAll) {
      id
      host
      active
      sponsor
      cupName
      cupStartDate
      cupEndDate
      place {
        id
        placeName
        address
      }
      youtubeUrl
      target
      firstPrize
      secondPrize
      thirdPrize
      mvpPrize
      mannerPrize
      joinPrice
    }
  }
`;

export const DELETE_CUP = gql`
  mutation deleteCup($id: String!) {
    deleteCup(id: $id) {
      id
    }
  }
`;

export const TOGGLE_CUP = gql`
  mutation toggleActive($id: String!) {
    toggleActive(id:$id)
  }
`;
