import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { CREATE_CUP_NOTICE, SEE_NOTICE, EDIT_CUP_NOTICE } from "./CreateCupBoardQueries";
import CreateCupBoardPresenter from "./CreateCupBoardPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../Hooks/useInput";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';
import { SEE_NOTICES } from "../CupNoticeList/CupNoticeListQueries";

const CreateCupBoardContainer = ({ match: { params } , history }) => {
  const cupId = params.cup;
  const noticeId = params.notice;

  const { data, loading } = useQuery(SEE_NOTICE, {
    skip : noticeId === undefined,
    variables: {
      id:noticeId
    }
  });

  const title = useInput("");
  const body = useInput("");
  const [uploading, setUploading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const createNoticeMutation = useMutation(CREATE_CUP_NOTICE, {
    refetchQueries:[{query:SEE_NOTICES, variables:{cupId}}]
  });
  const editNoticeMutation = useMutation(EDIT_CUP_NOTICE, {
    refetchQueries:[{query:SEE_NOTICES, variables:{cupId}}]
  });
  
  // 전체 업로드
  const handleSubmit = async () => {

    if(uploading)
      return false;
 
    try {
      if (title.value === "" || body.value === "") {
        toast.error("제목과 내용을 모두 입력해주세요");
      } 
      else {
        setUploading(true);

        const {
          data: { createCupNotice }
        } = await createNoticeMutation({
          variables: {
            cupId,
            title:title.value,
            text:body.value
          }
        });

        if (createCupNotice.id) {
          toast.success("업로드 완료!");

          setTimeout(() => {
            //history.push(`/cup/${cupId}/notices`);
            window.location.href = `/cup/${cupId}/notices`;
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  // 전체 업로드
  const handleEdit = async () => {
    if(uploading)
      return false;
 
    try {
      if (title.value === "" || body.value === "") {
        toast.error("제목과 내용을 모두 입력해주세요");
      } 
      else {
        setUploading(true);

        console.log({
          id:noticeId,
          title:title.value,
          text:body.value,
          action:"EDIT"
        })
        const {
          data: { editNotice }
        } = await editNoticeMutation({
          variables: {
            id:noticeId,
            title:title.value,
            text:body.value,
            action:"EDIT"
          }
        });

        console.log(editNotice);
        if (editNotice.id) {
          toast.success("수정 완료!");

          title.setValue(editNotice.title);
          body.setValue(editNotice.text);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };


  // function uploadImageCallBack(file) {
  //   return new Promise(
  //     (resolve, reject) => {
  //       const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
  //       xhr.open('POST', 'https://api.imgur.com/3/image');
  //       xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
  //       const data = new FormData(); // eslint-disable-line no-undef
  //       data.append('image', file);
  //       xhr.send(data);
  //       xhr.addEventListener('load', () => {
  //         console.log(xhr.responseText);
  //         const response = JSON.parse(xhr.responseText);
  //         console.log(response);
  //         resolve(response);
  //       });
  //       xhr.addEventListener('error', () => {
  //         const error = JSON.parse(xhr.responseText);
  //         reject(error);
  //       });
  //     },
  //   );
  // }

  // function uploadImageCallBack(file) {
  //   return new Promise(
  //     (resolve, reject) => {
  //       const reader = new FileReader(); // eslint-disable-line no-undef
  //       reader.onload = e => resolve({ data: { link: e.target.result } });
  //       reader.onerror = e => reject(e);
  //       reader.readAsDataURL(file);
  //     });
  // }


  const uploadImageCallBack = (file) => {
    return new Promise((resolve,reject) => {

      const serverURL = process.env.NODE_ENV === "development"
      ? "http://localhost:5000/api/notice/upload"
      : "https://weplay2921.cafe24.com/api/notice/upload";
      const xhr = new XMLHttpRequest();
      xhr.open('POST', serverURL);
      //xhr.setRequestHeader('content-type', 'multipart/form-data');
      const data = new FormData();
      data.append('notice', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        console.log(xhr.responseText);
        const response = JSON.parse(xhr.responseText);
        const formatResponse ={ data: { link: response.location } };
        console.log(formatResponse);
       
        resolve(formatResponse);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });

      // try {
      //   const {
      //     data: { location }
      //   } = await axios.post(
      //     "http://localhost:4000/api/notice/upload",
      //     formData,
      //     {
      //       headers: {
      //         "content-type": "multipart/form-data"
      //       }
      //     }
      //   );
      //     console.log(location);
      //   resolve(location);
      // } catch(e) {
      //   reject(e);
      // }
    
      
    });
  };

  const onEditorStateChange = (editorState) => {
    body.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  }

  return (
    <CreateCupBoardPresenter
      data={data}
      loading={loading}
      uploading={uploading}
      cupId={cupId}
      noticeId={noticeId}
      handleSubmit={handleSubmit}
      handleEdit={handleEdit}
      title={title}
      body={body}
      editorState={editorState}
      setEditorState={setEditorState}
      onEditorStateChange={onEditorStateChange}
      uploadImageCallBack={uploadImageCallBack}
    />
  );
};

export default withRouter(CreateCupBoardContainer);
